@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.search_form_base {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  min-width: 100%;
  gap: 50px 20px;
  .custom_input--area,
  .custom_input--select_box_area {
    flex-direction: column;
    gap: 0;
  }
  .custom_input--input_field,
  .custom_input--select_box_input_area {
    margin-top: 10px;
  }
  .custom_input--label,
  .custom_input--select_box_label {
    align-self: start;
    height: 16px;
    text-align: center;
  }
  &--action {
    flex: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    display: flex;
    & > .gw-btn {
      width: 300px;
      height: 44px;
      background-color: #0056d3;
      color: #ffffff;
    }
  }
}
.detail_info {
  display: flex;
  align-items: center;
  gap: 10px;
  & > div {
    display: flex;
    flex-direction: column;
    &:nth-child(1) {
      & > span:not(.ant-avatar) {
        display: inline-block;
        width: 60px;
        height: 20px;
        border: 1px solid #242424;
        box-sizing: content-box;
        border-radius: 10px;
        text-align: center;
        line-height: 20px;
        font-size: 14px;
        margin-top: 5px;
      }
    }
    &:nth-child(2) {
      text-align: left;
      & > span:nth-child(2) {
        font-weight: bold;
      }
    }
  }
}

.staff_search_modal {
  .custom_input--area .custom_input--input_area {
    align-items: flex-start;
  }
  .warning_text {
    color: red;
  }
  .table_area {
    padding: 15px 0;
  }
  .list_view--buttons_left {
    margin-bottom: 20px;
  }
  .search_form_staff {
    .custom_input--area,
    &:nth-child(1),
    &:nth-child(2) {
      max-width: 300px;
    }
    .custom_input--select_box_area {
      &:nth-child(3),
      &:nth-child(4) {
        max-width: 130px;
      }
      &:nth-child(6),
      &:nth-child(7) {
        width: 45%;
      }
      &:nth-child(n + 8) {
        max-width: 260px;
      }
    }
    .search_form_base .custom_input--label,
    .search_form_base .custom_input--select_box_label {
      height: 20px !important;
    }
    &--custom_input {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      label {
        font-size: 14px;
        color: #646464;
        display: inline-flex;
        line-height: 16px;
        height: 16px;
      }
      & > div {
        display: flex;
        margin-top: 20px;
        gap: 8px;
      }
    }
  }
  @media screen and (max-width: 800px) {
    .search_form_staff--custom_input {
      &.gender_input {
        width: 130px;
        flex-direction: column;
        & > div {
          margin-top: 20px;
          margin-left: 0;
        }
      }
      & > label {
        font-size: 12px;
      }
      flex-direction: row;
      & > div {
        margin-top: 0;
        margin-left: 20px;
        line-height: 16px;
      }
    }
    .custom_input--select_box_area {
      &:nth-child(6),
      &:nth-child(7) {
        width: 100% !important;
      }
      &:nth-child(n + 8) {
        max-width: unset !important;
      }
    }

    .table_area {
      .list_view--buttons_left {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
      }

      .list_view--count_page {
        width: unset !important;
      }
      .pager--wrapper {
        margin-top: 0;
        align-self: center;
      }
    }
  }
}

.employee_search_modal {
  .search_form_employee {
    .custom_input--area,
    &:nth-child(1),
    &:nth-child(2) {
      max-width: 48%;
      @media screen and (max-width: 800px) {
        max-width: unset;
      }
    }
  }
  @media screen and (max-width: 800px) {
    table {
      .ant-table-cell {
        &:nth-child(1) {
          min-width: 55px;
          padding: 2px;
          text-align: center;
        }
        &:nth-child(2) {
          min-width: 200px;

          padding: 2px;
          text-align: center;
        }
        &:nth-child(3) {
          width: auto;
          padding: 0;
          .gw-btn {
            width: auto;
            padding: 1px 15px;
          }
        }
      }
    }
  }
}

.business_partner_search_modal {
  .search_form_partner {
    gap: 0 20px;
  }
}
.action_area {
  display: flex;
  flex-direction: column;
  align-items: center;
  .add_btn {
    margin-bottom: 0;
  }
}

.staff_search_modal,
.employee_search_modal,
.business_partner_search_modal {
  @media screen and (max-width: 800px) {
    .ant-table-wrapper {
      margin: 0px !important;
      width: 100%;
    }
    .common_modal_title {
      text-align: left;
    }
    .search_form_base {
      gap: 20px;
    }
    .search_form_base--action {
      .gw-checkbox-wrapper {
        align-self: flex-start;
      }
      & > .gw-btn {
        width: 100%;
      }
    }
  }
}
