@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.quote_list {
  #approval_status {
    width: 128px;
  }
  table tr {
    &:has(.quote_list--approval_status--2) {
      background-color: #fdf2f5 !important;
    }
    &:has(.quote_list--approval_status--3) {
      background-color: #f4fbf3 !important;
    }
  }

  &--bottom_area.bottom_btn_area {
    height: 96px;
    flex-flow: row;
    justify-content: center;
    width: calc(100% - 56px);
  }
  &--submit_button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }
  &--setting_buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    right: 40px;
    &_btn {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
    }
  }
  &_search--header {
    display: flex;
    flex-flow: row wrap;
    gap: 24px;
  }

  tbody tr {
    td:nth-child(2) {
      text-align: right;
    }
  }

  .ant-picker-body {
    td {
      text-align: center !important;
    }
  }

  &--approval--amount {
    display: flex;
    img {
      width: 32px !important;
      margin-right: 8px !important;
    }
    span {
      white-space: pre !important;
    }
  }

  &--approval_status {
    &--0,
    &--1,
    &--2,
    &--3,
    &--4 {
      width: 74px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid;
      border-radius: 22px;
    }
    &--0 {
      color: #ffffff;
      background-color: #bcbcbc;
      border-color: #bcbcbc;
    }
    &--1 {
      color: #ffffff;
      background-color: #d9ca18;
      border-color: #d9ca18;
    }
    &--2 {
      color: #ffffff;
      background-color: #d80245;
      border-color: #d80245;
    }
    &--3 {
      color: #ffffff;
      background-color: #28d300;
      border-color: #28d300;
    }
    &--4 {
      color: #bcbcbc;
      border-color: #bcbcbc;
    }
  }
  @media (min-width: 1280px) and (max-width: 1366px) {
    &--setting_buttons {
      max-width: 480px;
      @media (max-width: 1280px) {
        max-width: 435px;
      }
    }
  }
  @media (max-width: 1180px) {
    &--setting_buttons {
      max-width: 400px;
    }
  }

  &--copy_list {
    width: 140px;
    height: 115px;
    background: white;
    position: absolute;
    bottom: 40px;
    border-radius: 6px;
    transition: 0.3s;

    p {
      width: 100%;
      height: 30px;
      background: #6a6f7c;
      color: white;
      border-radius: 6px 6px 0 0;
      text-align: center;
      font-weight: bold;
      line-height: 30px;
      margin: 0;
    }

    span {
      display: block;
      width: 100%;
      height: 40px;
      padding: 10px 12px;
      text-align: left;
      line-height: 20px;
      cursor: pointer;

      &:hover {
        background: #cccccc;
      }
    }
  }
}
@media screen and (max-width: 800px) {
  .quote_list {
    &--submit_button {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      gap: 40px;
      button {
        height: 40px !important;
      }
      .quote_list--menu_button {
        button {
          height: 42px !important;
          width: 42px !important;
          span {
            //font-size: 24px !important;
          }
        }
      }
    }
    &--bottom_area.bottom_btn_area {
      height: 135px;
      flex-flow: row;
      justify-content: center;
      width: 100%;
      @media screen and (max-width: 340px) {
        height: 183px;
      }
    }
    &--setting_buttons {
      max-width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;
      position: absolute;
      right: 16px;
      left: 16px;
      &_btn {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;
      }
    }
  }
  .custom_input {
    &--check_box_area {
      width: auto;
    }
    &--area_ant_design {
      .custom_input--input_area_ant_design .ant-picker {
        justify-content: space-between;
      }
    }
  }
}
