@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.all_bank_modal {
  &--btndiv {
    display: flex;
    justify-content: center;
    gap: 40px;
  }
  &--sel_date {
    display: flex;
    justify-content: center;
    .custom_input--area_ant_design {
      .ant-picker {
        padding: 0 11px;
        width: 141px;
      }
    }
    .custom_input--area_ant_design {
      position: relative;
    }
  }
  &--main {
    font-size: 14px;
    width: 100%;
    overflow: auto;
    display: flex;
    flex-flow: column nowrap;
    gap: 70px;
  }
}
