@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.staff_matter_register_page {
  .page_base--tabs {
    margin-top: 30px;
  }
  .no_display .custom_select--area {
    display: none;
  }
  &--title {
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    position: absolute;
    top: 8px;
    span {
      &:nth-child(2) {
        font-weight: bold;
        margin-left: 10px;
        margin-right: 30px;
      }
      &:nth-child(3) {
        font-size: 12px;
        color: #d80245;
      }
    }
  }
  &--base {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
  &--unit {
    min-width: 400px;
    width: 100%;
    display: inline-block;
    margin: 21px 0 0 10px;
    display: flex;
    flex-direction: column;
    &.display-linebreak {
      margin-top: 50px;
      gap: 5px;
    }
  }
}
