@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.matter_schedule--calendar {
  padding: 20px 40px 0 40px;
  background-color: #e9f2fc;
  margin-bottom: 100px;
  @media screen and (max-width: 800px) {
    width: 90%;
    padding: 0;
    margin: 24px auto;
  }
  .ant-picker-calendar .ant-picker-body {
    padding: 0;
  }
  //選択された日付
  .ant-picker-cell-selected .ant-picker-calendar-date-value {
    color: #7b7b7b !important;
  }
  .ant-picker-cell-selected .ant-picker-calendar-date {
    background-color: transparent !important;
  }

  .ant-picker {
    &-body {
      font: bold 14px Noto Sans CJK JP;
      z-index: 1;
    }
    //当月文字
    &-cell-in-view {
      color: #7b7b7b;
    }
    //各イベントdiv
    &-calendar-date-content {
      height: 115px !important;
    }

    //テーブル
    &-content {
      background-color: #ffffff;
      height: 550px;
      width: 100%;
      thead {
        tr {
          background: #6a6f7c;
          height: 36px;
          th {
            text-align: center;
            color: #ffffff;
            font-weight: bold;
          }
        }
      }
      tbody {
        tr {
          td {
            //文字を左詰め
            .ant-picker-calendar-date-value {
              align-items: flex-start;
              width: 20px;
              font-weight: bold;
            }
            //当日文字
            .ant-picker-calendar-date-today {
              border-color: #003b94;
              border-width: 4px;
              background-color: #e7f1f9 !important;
              .ant-picker-calendar-date-value {
                color: #003b94 !important;
              }
            }
          }
        }
      }
    }
  }
  //テーブルhead
  .ant-picker-panel .ant-picker-body th {
    padding: 0;
  }
  tr th:nth-child(-n + 6) {
    border-right: 1px solid #ffffff;
  }

  //土、日曜日列
  tr td:nth-child(5) + .ant-picker-cell-in-view {
    color: #3662a5;
  }
  tr td:nth-child(6) {
    background-color: #d7e2f1;
  }
  tr td:nth-child(6) {
    .ant-picker-cell-inner.ant-picker-calendar-date {
      border-top-color: rgba(128, 128, 128, 0.2);
    }
  }
  tr td:nth-child(6) + .ant-picker-cell-in-view {
    color: #d80245;
  }
  tr td:nth-child(7) {
    background-color: #fdf2f5;

    :hover {
      background-color: #FEF8FA;
    }
  }
  tr td:nth-child(7) {
    .ant-picker-cell-inner.ant-picker-calendar-date {
      border-top-color: rgba(128, 128, 128, 0.2);
    }
  }

  //各日付欄
  .ant-picker-cell-inner.ant-picker-calendar-date {
    display: flex;
    flex-flow: column;
  }
  .delete_icon {
    position: static;
  }
}

.matter_schedule--search {
  .commonPage--base {
    padding-bottom: 0;
  }
}
.matter_schedule {
  &--matter--open {
    display: flex;
    flex-flow: column;
    gap: 1px;
    div {
      background-color: #f5f7fb;
      border: 1px solid #003b94;
      border-radius: 6px;
      p {
        margin: -2px 0;
      }
    }
  }
  &--week--check {
    display: flex;
    flex-flow: column;
    gap: 1px;
    div {
      background-color: #f5fbf5;
      border: 1px solid #008c0f;
      border-radius: 6px;
      p {
        margin: -2px 0;
      }
    }
  }
  &--matter--report {
    display: flex;
    flex-flow: column;
    gap: 1px;
    div {
      background-color: #fdf2f5;
      border: 1px solid #d80245;
      border-radius: 6px;
      p {
        margin: -2px 0;
      }
    }
  }
  &--attendance--submit {
    display: flex;
    flex-flow: column;
    gap: 1px;
    div {
      background-color: #fdfdf3;
      border: 1px solid #f9be00;
      border-radius: 6px;
      p {
        margin: -2px 0;
      }
    }
  }
  &--head_select {
    gap: 0;
    row-gap: 8px;
    flex-direction: column;
    align-items: flex-start;
  }
}

.holiday {
  .ant-picker-calendar-date-value {
    color: #d80245;
  }
}

td.ant-picker-cell.ant-picker-cell-in-view.holiday {
  background-color: #fdf2f5;

  :hover {
    background-color: #FEF8FA;
  }
}

td.ant-picker-cell:not(.ant-picker-cell-in-view) {
  cursor: default;
  pointer-events: none;
}