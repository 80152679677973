@import '../../../../../styles/normalize';
@import '../../../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../../../styles/mixin';
@import '../../../../../styles/global';

.table_footer {
  //テーブルhead部分
  thead tr th {
    font: normal normal bold 14px/20px Noto Sans CJK JP;
    color: #ffffff;
    background-color: #a0aab8;
    border-bottom: 0;
    padding: 0 0 0 24px;
    height: 36px;
  }
  tr th:nth-child(1) {
    visibility: hidden;
    min-width: 72px;
  }
  tr th:last-child {
    visibility: hidden;
    min-width: 76px;
  }

  tbody {
    tr {
      td {
        padding: 8px;
        border-left: 1px solid #a0aab8;
        border-right: 1px solid #a0aab8;
        border-bottom: 1px solid #a0aab8;
      }
      td:nth-child(1) {
        width: 72px;
        padding: 0;
        visibility: hidden;
      }
      td:last-child {
        width: 76px;
        padding: 0;
        visibility: hidden;
      }
    }
  }
  #note {
    height: 23vh;
  }
}
