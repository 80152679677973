@import '../../styles/normalize';
@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.image_uploader {
  width: 188px;
  height: 188px;
  position: relative;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  align-items: center;
  align-self: flex-start;
  border: 1px solid $grey;
  &:hover {
    cursor: pointer;
  }
  &--upload-image {
    max-width: 188px;
    max-height: 100%;
  }
  .delete_icon {
    top: 8px;
  }
  .gw-checkbox-wrapper {
    position: absolute;
    top: 9px;
    left: 16px;
    .gw-checkbox-label::before {
      width: 16px;
      height: 16px;
      line-height: 16px;
    }
  }
  &--empty_image {
    height: 64px;
    width: 64px;
  }
}
