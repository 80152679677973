@import '../../styles/normalize';
@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.common_switch {
  .ant-click-animating-node {
    display: none;
  }
  &.switch-green {
    .ant-switch-checked {
      background-color: #28d300;
    }
  }
  &.switch-red {
    .ant-switch-checked {
      background-color: #d80245;
    }
  }
  &.switch-blue {
    .ant-switch.ant-switch-checked {
      background-color: #4a72af;
    }
  }
  &--label {
    padding-right: 10px;
    color: #646464;
  }
  @media screen and (max-width: 800px) {
    margin-right: 12px !important;
  }
}

.switch_input {
  margin: 15px 0 25px;
  max-width: 900px;
  &--label {
    text-align: right;
    display: inline-block;
    margin-right: 20px;
    width: 130px;
    font-weight: bold;
    color: #646464;
  }
  &--input {
    margin-left: 130px;
    margin-top: 20px;
  }
}
@media screen and (max-width: 500px) {
  .report_switch {
    margin-top: 30px;
    align-items: center;
    display: flex;
    width: 100%;
  }
}
