@import '../../styles/normalize';
@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.pager {
  &--wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    z-index: 1;
  }

  &--paging_btn {
    &:hover {
      background-color: #B2CCF2;
    }
  }
}