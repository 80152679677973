@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.recruit_matter_list {
  &--search_area {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
  }

  &--calender_unit {
    width: 338px;
    height: 76px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

    .recruit_matter_list--calender_unit_span {
      width: 16px;
      height: 44px;
      font-size: 14px;
      color: #646464;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      margin-top: 32px;
    }
  }

  &--input_date_area,
  &--input_date_area_no_label {
    width: 153px;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    .custom_input {
      &--label_ant_design {
        width: 100%;
        height: 24px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-shrink: 0;

        label {
          font-size: 14px;
          color: #646464;
        }
      }
    }
  }

  &--input_date_area_no_label {
    margin-top: 32px;
  }
}
.recruit-matter-staff {
  .status-box {
    background-color: black;
    color: #ffffff;
    border-radius: 22px;
    width: 112px;
    min-width: 112px;
    height: 32px;
    padding: 3px 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  @media screen and (max-width: 800px) {
    .custom_input--select_box_area_column {
      width: 100% !important;
    }
  }
  .custom_sp_view {
    width: 91%;
    display: flex;
    margin: 0 auto 20px;
    gap: 16px;
    background-color: #ffffff;
    flex-direction: column;
    z-index: 1 !important;

    &--title {
      width: 100%;
      background-color: #6a6f7c;
      color: #ffffff;
      font-weight: bold;
      padding-left: 16px;
      height: 30px;
      line-height: 30px;
      font-size: 12px;
    }
    &--matter_title {
      font-weight: bold;
      color: #242424;
      font-size: 12px;
      line-height: 18px;
    }
    & > span {
      padding-left: 15px;
    }
    &--content {
      padding-left: 16px;
      color: #242424;
      font-size: 12px;
      line-height: 18px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 24px;
      & > span:nth-child(1) {
        width: 75px;
        display: inline-block;
        color: #646464;
        font-size: 12px;
        line-height: 18px;
      }
    }
    .status-box {
      display: flex;
      width: 74px;
      height: 24px;
      max-width: 74px !important;
      min-width: 74px !important;
      margin-left: 16px;
      line-height: 24px;
      font-size: 12px;
      padding: 0;
    }
    & > .gw-btn {
      height: 40px;
      flex: 1;
      margin: 16px 16px 24px;
      padding: 0;
      align-self: center;
      width: calc(100% - 30px);
      background-color: #ffffff;
      color: #2b3245;
      border: 1px solid #2b3245;
    }
  }
}
