@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.schedule_modal {
  &--startest {
    font-size: 14px;
    width: 100%;
    overflow: auto;
    display: flex;
    flex-flow: column nowrap;
    gap: 3vh;
  }
  &--main {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    gap: 22px;
    &_content {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 24px;
      &_title {
        width: 12%;
        text-align: right;
        margin: 0;
        sup {
          color: #d80245;
        }
      }
      .ant-picker {
        box-sizing: border-box;
        width: 165px !important;
      }
    }
    &_date--pick {
      display: flex;
      gap: 8px;
      @media screen and (max-width: 800px) {
        width: 100%;
      }
    }
  }
}

//ボタン
.schedule_modal {
  &--btndiv {
    display: flex;
    justify-content: center;
    gap: 24px;
    margin-top: 3vh;
  }
}

.schedule_modal--main_content {
  @media screen and (max-width: 800px) {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
  }
  .ant-select-arrow {
    font-style: normal;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: calc(50% + 1.5px);
    right: -9px;
    display: flex;
    align-items: center;
    height: 12px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px;
    line-height: 1;
    text-align: center;
    pointer-events: none;

    @media screen and (max-width: 800px) {
      right: 11px;
    }
  }

  .custom_input--select_box_unit {
    div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    p,
    b {
      display: inline-block;
      white-space: nowrap;
      margin: 0;
      height: 100%;
      text-align: left;
    }
  }
}

.schedule_modal_sp--text {
  width: 100%;
  display: flex;
  justify-content: flex-start;align-items: center;
  font-size: 12px;

  p {
    margin-bottom: 0;
    line-height: 16px;
  }
  b {
    margin-bottom: 0;
    line-height: 16px;
  }
}

