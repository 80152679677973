@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  display: flex;

  td:nth-child(1).pay_list--main_table--column {
    width: 10%;
  }
  td:nth-child(2).pay_list--main_table--column {
    width: 26%;
  }
  td:nth-child(3).pay_list--main_table--column {
    width: 24%;
  }
  td:nth-child(4).pay_list--main_table--column {
    width: 15%;
  }
  td:nth-child(5).pay_list--main_table--column {
    width: 17%;
  }
  td:nth-child(6).pay_list--main_table--edit {
    width: 4%;
  }
  td:nth-child(7).pay_list--main_table--del {
    width: 4%;
  }
}
.row-dragging td {
  padding: 16px;
}

.pay_list {
  background-color: #e9f2fc;
  height: 100%;

  &--add--btn {
    float: right;
    margin: 18px 42px;
  }
  &--main_table {
    margin: -61px 40px 0 40px;
    font: normal normal bold 14px/16px Noto Sans CJK JP;
    min-width: 680px;

    &--column {
      border-right: 1px dashed #e0e0e0;
    }
    &--edit {
      padding: auto;
    }
    thead {
      tr th:nth-child(-n + 5) {
        border-right: 1px solid #e0e0e0;
      }
      tr th:nth-child(7) {
        padding: 0 24px 0 12px;
      }
      tr {
        th {
          height: 36px;
          color: #ffffff;
          background-color: #6a6f7c;
          padding: 0 0 0 24px;
        }
      }
    }
    tbody tr:nth-child(odd) {
      background-color: #f3f3f3;
    }
    tbody tr:nth-child(1) {
      background-color: #f4fbf3;
      .pay_list--main_table--column--order {
        p {
          visibility: visible !important;
        }
      }
    }
    tbody {
      tr {
        td {
          font-weight: normal;
          padding: 28px 24px;
        }
      }
      tr td:nth-child(6) {
        padding: 24px 12px 24px 24px;
      }
      tr td:nth-child(7) {
        padding: 24px 24px 24px 12px;
      }
    }
  }
  .ant-table-thead tr th {
    font-weight: bold;
  }

  //addボタン吹き出し
  .add_button--text {
    z-index: 8000;
  }
}

@media screen and (max-width: 800px) {
  .pay_list {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;

    &--add--btn {
      width: 100%;
      height: 40px;
      margin: 32px 0 20px;
    }

    &--main_table {
      width: 92%;
      min-width: initial;
      height: fit-content !important;
      margin: 0;
      overflow-y: scroll;
      background-color: #FFFFFF;
      z-index: 1;

      .ant-table-wrapper {
        min-width: 1200px;
      }
    }
  }

  .pay_list--main_table tbody tr td {
    box-sizing: border-box;
    white-space: nowrap !important;
    max-height: 90px !important;
  }
}