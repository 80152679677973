@import '../../../../../styles/normalize';
@import '../../../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../../../styles/mixin';
@import '../../../../../styles/global';

.pay_stamp {
  display: flex;
  flex-flow: column nowrap;
  gap: 40px;
  width: calc(100% - 272px);
  margin-top: 64px;
  &--staff_employee {
    position: relative;
  }
  &--each_area {
    display: flex;
    flex-flow: column nowrap;
    gap: 24px;
  }
}
