@import '../../styles/normalize';
@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.select_add_btn {

  &--area {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .add_button--area {
      .add_button {
        width: 44px;
        height: 44px;
        border-radius: 0 6px 6px 0;
        border-top: 1px solid #2b3245;
        border-right: 1px solid #2b3245;
        border-bottom: 1px solid #2b3245;
        border-left: none;
        padding: 10px;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #7B7B7B;

        &:hover {
          background-color: #0056d3;
        }
        &:active {
          opacity: 0.5;
        }
      }
    }

    .ant-select {
      width: 100%;
      height: 44px;
      padding-right: 16px;

      .ant-select-selector {
        width: calc(100% + 16px);
        height: 44px;
        border-radius: 6px 0 0 6px;
        border-right: 0.5px solid #7B7B7B;
      }
    }
  }
}