@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.global-modal {
  .ant-modal-body {
    @include flex-column-center();
  }
  display: flex;
  margin: 20px 0;
  @media screen and (max-width: 800px) { 
    margin: 24px 0;
  }
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .global-modal-title {
    font-weight: bold;
    font-size: $font-size-lm;
    margin-bottom: 15px;
    text-align: center;
  }
  .modal-message {
    display: flex;
    font-weight: bold;
    font-size: $font-size-ssm;
    flex-direction: column;
    text-align: center;
    margin-bottom: 20px;
    &.delete-message {
      font-weight: normal;
    }
  }
  .tip {
    color: $dark-red;
    margin-top: 10px;
    font-size: $font-size-ssm;
  }
  .gw-btn {
    height: 30px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .button-container {
    display: flex;
    justify-content: center;
    position: relative;
    gap: 0 20px;
    width: 100%;
  }
}
