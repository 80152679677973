@import '../../styles/normalize';
@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.search_select {
  &--area {
    div,
    span,
    ul,
    li {
      box-sizing: content-box !important;
    }

    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
    position: relative;

    img {
      position: absolute;
      right: 16px;
      bottom: 14px;
    }

    &_top {
      height: 76px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      flex-flow: column;
      gap: 8px;
      position: relative;

      div,
      span,
      ul,
      li {
        box-sizing: content-box !important;
      }

      img {
        position: absolute;
        right: 16px;
        bottom: 14px;
      }
    }
  }

  &--label {
    display: inline-block;
    text-align: right;
    height: 14px;
    font-size: 14px;
    line-height: 14px;
    color: #646464;

    &_top {
      display: inline-block;
      text-align: left;
      height: 24px;
      font-size: 14px;
      line-height: 24px;
      color: #646464;
    }
  }

  &--input_ul_wrap {
    position: relative;
  }

  &--input {
    height: 44px;
    padding: 10px 32px 10px 15px;
    border: 0.5px solid #2b3245;
    border-radius: 6px;

    &::placeholder {
      color: #BCBCBC;
    }

    &:focus {
      border: 1px solid #003B94;
      box-shadow: 0 0 8px #003B9429;
    }
  }

  &--ul {
    position: absolute;
    top: 50px;
    margin: 0 0;
    padding: 0 0;
    width: 100%;
    list-style: none;
    background-color: #FFFFFF;
    border-radius: 6px;
    box-shadow: 0 0 8px #2424244D;
    z-index: 9000;
    max-height: 288px;
    overflow-y: auto;
    opacity: 0;
    transition: all .3s;

    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: #FFFFFF;
      border-radius: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #BCBCBC;
      box-shadow: #2424244D 0 0 8px;
      border-radius: 5px;
    }
  }

  &--li {
    width: calc(100% - 40px);
    line-height: 20px;
    font-size: 12px;
    height: 0;
    padding: 0 0;
    overflow: hidden;
    cursor: pointer;
    transition: all .3s;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_check_mark {
      content: '';
      width: 10px;
      height: 5px;
      border-left: 2px solid #242424;
      border-bottom: 2px solid #242424;
      transform: rotate(-45deg);
      margin-bottom: 4px;
      opacity: 0;
    }

    &:hover {
      opacity: .6;
    }
  }

  &--multi {
    &_input_area {
      height: 24px;
      padding: 10px 32px 10px 15px;
      border: 0.5px solid #2b3245;
      border-radius: 6px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      overflow-y: hidden;
      overflow-x: auto;
      gap: 8px;

      &::-webkit-scrollbar {
        display: none;
      }

      &::placeholder {
        color: #BCBCBC;
      }

      &:focus {
        border: 1px solid #003B94;
        box-shadow: 0 0 8px #003B9429;
      }
    }

    &_selected_value_area {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
    }

    &_input {
      display: inline-block;
      border: none;
      outline: none;
      width: 100%;
      flex-shrink: 100;
    }

    &_selected_icon {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      background-color: #E9F2FC;
      border-radius: 22px;
      height: 32px;
      padding: 0 12px;
      line-height: 32px;
      flex-shrink: 0;
    }
  }
}
