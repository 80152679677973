@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.spConfirmModal {
  &--overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #24242466;
    z-index: 9500 !important;
  }
  &--base {
    width: 92%;
    height: 280px;
    padding: 48px 16px;
    background-color: #FFFFFF;
    border-radius: 12px;
    position: absolute;
    top: calc(50vh - 188px);
    left: 4%;
    z-index: 9999;

    p {
      width: 100%;
      font-size: 20px;
      color: #242424;
      text-align: center;
      margin: 0;
      font-weight: bold;
    }

    .subText {
      display: block;
      width: 60%;
      font-size: 14px;
      color: #242424;
      text-align: center;
      margin: 32px auto 0;
      white-space: break-spaces;
    }
  }

  &--buttons_wrap {
    display: flex;
    width: calc(100% - 32px);
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 48px;
    left: 16px;

    button {
      height: 40px !important;
      width: 148px !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}