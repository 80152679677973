@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.staff_plan--edit_page {
  width: 100%;
  height: auto;
  padding: 8px 40px 132px;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;

  .staff_plan {
    &--sp_check_all_area {
      width: 100%;
      height: 21px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    &--date_display {
      width: 183px;
    }

    &--upper_part {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .staff_plan--table {
        width: 44%;
        height: 97px;
        margin-top: 32px;

        td {
          height: 36px;
          background-color: #6a6f7c;

          .staff_plan--td_contents {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #ffffff;
            font: normal normal bold 14px Noto Sans CJK JP;
          }
        }

        th {
          height: 61px;
          background-color: #ffffff;

          .staff_plan--th_contents {
            font: normal normal normal 14px Noto Sans CJK JP;
            letter-spacing: 0px;
            color: #242424;

            .data_info {
              font: normal normal bold 18px Noto Sans CJK JP;
              letter-spacing: 0px;
              color: #242424;
              opacity: 1;
            }
          }
        }
      }

      .staff_plan--access_user_info_area {
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        gap: 24px;
        color: #7b7b7b;
      }

      .staff_plan--access_user_data {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
      }
    }

    &--lower_part {
      width: 100%;
      height: 500px;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;

      .staff_plan--bar_area {
        width: 100%;
        height: 44px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .staff_plan--bar_content_status {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 40px;

          span {
            font: normal normal bold 24px/30px Noto Sans CJK JP;
            letter-spacing: 0px;
            color: #242424;
          }
        }
      }

      .staff_plan--contents_area {
        width: 100%;
        height: auto;
      }
    }

    &--calender_prev_button,
    &--calender_next_button {
      width: 92px;
      height: 44px;
      border: 1px solid #2b3245;
      border-radius: 6px;
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
    }

    &--calender_status_area {
      width: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 24px;
      margin-left: 24px;

      .staff_plan--calender_status {
        width: 74px;
        height: 32px;
        border-radius: 22px;
        color: #ffffff;
        background-color: #d80245;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
      }

      .staff_plan--calender_status_ture {
        width: 74px;
        height: 32px;
        border-radius: 22px;
        color: #ffffff;
        background-color: #28d300;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
      }

      .staff_plan--calender_update_info {
        color: #7b7b7b;
        font-size: 14px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
      }
    }

    &--shift_table {
      width: 100%;
      height: auto;
      margin-bottom: 132px;

      td {
        background-color: #6a6f7c;
        color: #ffffff;
        font-size: 14px;
        height: 36px;

        .staff_plan--td_contents {
          .custom_input--check_box_area {
            width: 25px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;

            .check_box--label_text {
              display: none;
            }
          }
        }
      }

      tr {
        height: 36px;
      }

      .staff_plan--shift_table_tr {
        height: 80px;
        background-color: #ffffff;
        border-top: 1px solid #f3f3f3;
        border-bottom: 1px solid #f3f3f3;
        padding-top: 2px;
      }

      .staff_plan--shift_table_tr_sun {
        height: 80px;
        background-color: #fdf2f5;
        border-top: 1px solid #bcbcbc;

        .staff_plan--calender_font {
          color: #d80245;
          font-size: 14px;
        }
      }
      .staff_plan--shift_table_tr_sat {
        height: 80px;
        background-color: #d7e2f1;

        .staff_plan--calender_font {
          color: #3662a5;
          font-size: 14px;
        }
      }

      .staff_plan_confirm--table_tr_link:hover {
        opacity: 0.75;
      }
      .staff_plan_confirm--table_tr_link:active {
        opacity: 0.5;
      }
    }

    &--th_shift_contents {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .custom_input--radio_input_area {
        row-gap: 16px;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;

        label {
          color: #242424;
          font: normal normal normal 14px Noto Sans CJK JP;
        }
      }
    }

    &--th_contents_time {
      width: 262px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      margin-right: unquote('max(4%, 35px)');

      .staff_plan--th_contents_time_border {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 16px;
        font-size: 14px;
        color: #242424;
      }

      .custom_input--area_ant_design {
        .ant-picker {
          width: 115px;

          &.ant-picker-disabled {
            background-color: #ffffff;
          }

          .anticon anticon-clock-circle {
            color: #3ba1ff;
          }
        }
      }
    }

    &--td_0 {
      .staff_plan--td_contents {
        padding-left: 24px;
      }
    }
    &--td_1 {
      .staff_plan--td_contents {
        padding-left: 8px;
      }
    }
    //&--td_2 {
    //  .staff_plan--td_contents {
    //    white-space: nowrap;
    //  }
    //}
    //&--td_3 {
    //  .staff_plan--td_contents {
    //    white-space: nowrap;
    //  }
    //}
    //&--td_4 {
    //  .staff_plan--td_contents {
    //
    //    white-space: nowrap;
    //  }
    //}
    //&--td_5 {
    //  background-color: #3ba1ff;
    //  .staff_plan--td_contents {
    //    white-space: nowrap;
    //  }
    //}
  }
}

@media screen and (max-width: 800px) {
  .data_info {
    font: normal normal bold 18px Noto Sans CJK JP;
    letter-spacing: 0px;
    color: #242424;
    opacity: 1;
  }

  .staff_plan--edit_page {
    width: 100%;
    height: auto;
    padding: 8px 20px 0px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;

    .staff_plan {
      &--date_display {
        width: 183px;
      }

      &--upper_part {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;

        .staff_plan--access_user_info_area {
          height: auto;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          flex-flow: column;
          font-size: 12px;
          gap: 0;
          color: #7b7b7b;
        }

        .staff_plan--access_user_data {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 8px;
        }
      }

      &_number_font {
        font-weight: bold;
      }

      &--table {
        width: 100%;
        border: solid 1px #6a6f7c;

        &_header {
          background-color: #6a6f7c;
          color: #ffffff;
          font-size: 14px;
          height: 36px;
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-content: center;
        }

        &_body {
          background-color: #ffffff;
          font-size: 14px;
          height: 60px;
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-content: center;
        }
      }

      &--lower_part {
        width: 100%;
        height: auto;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        gap: 15px;
      }

      &--bar_area {
        width: 100%;
        height: 44px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &--bar_content {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;
      }

      &--date_title {
        font: normal normal bold 18px Noto Sans CJK JP;
        letter-spacing: 0px;
        color: #242424;
        white-space: nowrap;
        width: 122px;
      }

      &--calender_status_area {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 24px;
        margin-left: 0;

        .staff_plan--calender_status {
          width: 74px;
          height: 32px;
          border-radius: 22px;
          color: #ffffff;
          background-color: #d80245;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
        }

        .staff_plan--calender_status_ture {
          width: 74px;
          height: 32px;
          border-radius: 22px;
          color: #ffffff;
          background-color: #28d300;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
        }

        .staff_plan--calender_update_info {
          color: #7b7b7b;
          font-size: 14px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 8px;
        }
      }

      &--shift_list {
        width: 100%;
        font-weight: 700;

        &_header {
          width: 100%;
          height: 36px;
          background-color: #e2e4e8;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-left: 20px;

          &_sun {
            width: 100%;
            height: 36px;
            color: #d80245;
            background-color: #fdf2f5;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-left: 20px;
          }

          &_sat {
            width: 100%;
            height: 36px;
            color: #3662a5;
            background-color: #d7e2f1;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-left: 20px;
          }
        }

        &_body {
          width: 100%;
          height: 40px;
          background-color: #ffffff;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 20px;

          &_title {
            width: 30%;
          }

          &_time {
            display: flex;
            align-items: center;
            gap: 7px;

            .custom_input--area_ant_design {
              .custom_input--input_area_ant_design {
                .ant-picker {
                  width: 100%;
                }
              }
            }
          }
        }

        &_body_area {
          width: 100%;
          height: 100%;
          display: flex;
          flex-flow: column;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 32px;
          padding: 30px 6%;
          background-color: #FFFFFF;
        }
      }
    }
  }
}

