@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.quote_register {
  &--submit_buttons {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }
}

.quote_register--term {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 26px;
}
@media screen and (max-width: 800px) { 
  .custom_input--area {
    gap: 8px;
  }
  .ant-modal-content {
    border-radius: 12px;
    margin: 8px;
  }
}