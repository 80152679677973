@import '../../styles/normalize';
@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.dashboard_staff {
  width: 77.5%;
  height: auto;
  margin: 80px auto 87px;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;

  &--user_notice {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  }
  .action_alert:hover {
    cursor: pointer;
  }
  &--contents_notice_alert,
  &--contents_notice {
    width: 100%;
    height: auto;
    border: 1px solid #d80245;
    background-color: #fdf2f5;
    font-size: 14px;
    color: #d80245;
    padding: 14px 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    white-space: pre-line;
  }

  &--contents_notice {
    border: none;
    background-color: #ffffff;
    color: #242424;
    box-shadow: 0 0 8px #383f524d;
  }

  &--matter_info {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #003b94;
  }

  &--matter_title {
    width: 100%;
    height: 36px;
    padding-left: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #6a6f7c;
    font: normal normal bold 14px Noto Sans CJK JP;
    color: #ffffff;
  }

  &--matter_contents, &--matter_items_area {
    width: 100%;
    height: auto;
    padding: 8px 56px 24px 40px;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: start;
  }

  &--matter_items_area {
    padding: 0;
  }

  &--matter_right_area {
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-end;
    gap: 24px;
  }

  &--alert {
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #d80245;
    border-bottom: 1px solid #d80245;
  }

  &--matter_link {
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #7b7b7b;
    border-bottom: 1px solid #7b7b7b;
  }

  &--matter_items {
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
  }

  &--matter_name {
    height: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font: normal normal bold 14px Noto Sans CJK JP;
    color: #242424;
    margin-bottom: 4px;
    margin-top: 16px;
  }

  &--matter_details {
    height: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
  }

  &--matter_label {
    width: 96px;
    height: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    color: #646464;
  }

  &--matter_value {
    height: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    color: #242424;
  }

  &--matter_alert {
    height: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    color: #d80245;
    margin-top: 4px;

    label {
      height: 24px;
      background-color: #d80245;
      color: #ffffff;
      padding: 2px 16px 0;
      font: normal normal bold 14px Noto Sans CJK JP;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &--no_matter {
    height: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font: normal normal bold 14px Noto Sans CJK JP;
    color: #646464;
    margin: 16px 0 5px;
  }

  &--copy_right {
    width: 100%;
    height: 18px;
    line-height: 18px;
    margin-bottom: 6px;
    font: normal normal normal 12px Noto Sans CJK JP;
    color: #383F52;
    text-align: center;
  }

  &--icon_area {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    position: relative;
    padding: 0 40px 40px 0;

    @media screen and (max-width: 800px) {
      padding: 0 20px 0 0;
    }

    img {
      width: 168px;
      height: 168px;

      @media screen and (max-width: 800px) {
        width: 89px;
        height: 89px;
      }
    }
  }

  &--red_circle_on_character {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #D80245;
    position: absolute;
    top: 0;
    right: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 800px) {
      width: 24px;
      height: 24px;
      right: 25px;
    }

    span {
      font-size: 18px;
      font-weight: bold;
      color: #FFFFFF;

      @media screen and (max-width: 800px) {
        font-size: 12px;
        font-weight: normal;
      }
    }
  }

}

@media screen and (max-width: 800px) {
  .dashboard_staff {
    width: 100%;
    margin: 40px 0 68px;

    &--contents_notice_alert {
      font-size: 12px;
      padding: 12px 16px;
    }

    &--matter_info, &--contents_notice {
      width: 91%;
    }

    &--contents_notice {
      font-size: 12px;
      padding: 8px 16px;
    }

    &--matter_title {
      height: 30px;
      line-height: 30px;
      padding-left: 16px;
      font: normal normal bold 12px Noto Sans CJK JP;
    }

    &--matter_contents {
      padding: 16px;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-end;
      gap: 16px;
    }

    &--matter_items {
      width: 100%;
      height: auto;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 8px;
    }

    &--matter_name {
      width: 100%;
      height: 18px;
      line-height: 18px;
      font: normal normal bold 12px Noto Sans CJK JP;
      color: #242424;
      text-align: left;
      margin: 0;
    }

    &--matter_details {
      width: 100%;
      height: fit-content;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 16px;
    }

    &--matter_label {
      width: 73px;
      height: 18px;
      line-height: 18px;
      text-align: left;
      font-size: 12px;
      color: #646464;
      flex-shrink: 0;
    }

    &--matter_value {
      width: 100%;
      height: fit-content;
      text-align: left;
      font-size: 12px;
      color: #242424;
    }

    &--alert {
      width: auto;
      height: 18px;
      line-height: 18px;
      font-size: 12px;
      color: #D80245;
      border-bottom: 1px solid #D80245;
      margin: 0 auto;
    }

    &--matter_link {
      width: auto;
      height: 17px;
      line-height: 17px;
      font-size: 11px;
      margin-top: 8px;
    }

    &--no_matter {
      width: auto;
      height: 18px;
      line-height: 18px;
      color: #242424;
      margin:0 auto 0 0;
      font: normal normal normal 12px Noto Sans CJK JP;
    }

    &--copy_right {
      width: 100%;
      height: 16px;
      line-height: 16px;
      margin-bottom: 6px;
      font: normal normal normal 10px Noto Sans CJK JP;
      color: #383F52;
      text-align: center;
    }
  }
}