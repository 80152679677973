@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.invoice_create {
  &--area {
    width: 76%;
    height: auto;
    margin: 40px auto;
    @media screen and (max-width: 800px) { 
      width: 100%;
    }
  }

  &--btn_area {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: rgba(106, 111, 124, 0.6);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 30;
    @media screen and (max-width: 800px) { 
      padding: 24px 16px;
    }
  }

  &--btn {
    width: 100%;
    height: 44px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    @media screen and (max-width: 800px) { 
      position: fixed;
      bottom: 24px;
      height: 40px;
      gap: 23px;
      width: calc(100% - 32px);
    }
  }

  &--sub_btn_area {
    position: absolute;
    right: 40px;
    top: 4px;
    width: 224px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    @media screen and (max-width: 800px) { 
      bottom: 56px;
      top: auto;
      right: 0;
      width: 100%;
      gap: 23px;
    }
  }
}
