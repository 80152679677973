@import '../../../../../styles/normalize';
@import '../../../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../../../styles/mixin';
@import '../../../../../styles/global';

.insert_table {
  //テーブルhead部分
  tr th:first-child,
  tr th:last-child {
    visibility: hidden;
  }

  tr th:not(:last-child):not(:first-child) {
    background-color: #a0aab8;
  }
  tr th:not(:last-child):not(:first-child):not(:nth-last-child(2)) {
    border-right: 1px solid #e9f2fc;
  }

  thead tr th {
    font: normal normal bold 14px/20px Noto Sans CJK JP;
    color: #ffffff;
    text-align: center;
    border-bottom: 0;
    padding: 0;
    height: 36px;
  }

  tbody {
    tr td:not(:last-child):not(:first-child) {
      border-right: 1px solid #a0aab8;
      border-bottom: 1px solid #a0aab8;
    }
    tr td:nth-child(2) {
      border-left: 1px solid #a0aab8;
    }
    tr {
      td:nth-child(1) {
        border-bottom: none;
        padding: 0;
      }
      td:last-child {
        border-bottom: none;
        padding: 0;
      }
      td:not(:last-child):not(:first-child) {
        padding: 8px 7px 7px 7px;
      }
    }
  }

  &--pull_down--sub_menu {
    height: 26px;
    text-align: center;
  }
  &--pull_down--sub_menu.ant-dropdown-menu-item-selected {
    color: #ffffff;
    background-color: #2b3245;
    opacity: 1;
    border-radius: 3px;
  }

  &--tool_tip {
    padding-bottom: 4px;
    .ant-tooltip-inner {
      padding: 2px 0;
      min-height: 26px;
      width: 58px;
      text-align: center;
      border-radius: 3px;
    }
  }
}

.row-dragging {
  td {
    padding: 0 8px 0 0;
  }
  .insert_table--column_order {
    padding-left: 6px;
  }
  .insert_table--column_action {
    padding-right: 4px;
  }
  td:nth-child(2).insert_table--column {
    width: 100%;
  }
  td:nth-child(4).insert_table--column {
    width: 15%;
  }
}
