@import '../../styles/normalize';
@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.delete_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 7px;
  height: 24px;
  width: 24px;
  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: 800px) {
    right: 2px;
    top: 2px;
  }
}
