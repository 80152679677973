@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.staff_pay_edit {
  &--title {
    @include shadow-box();
    min-height: 64px;
    background: #ffffff;
    display: flex;
    padding: 8px 0 8px 24px;
    &--img {
      width: 48px;
      border-radius: 50%;
    }
    &--id {
      font: normal normal normal 14px/20px Noto Sans CJK JP;
      color: #a0aab8;
      margin-bottom: 1px;
    }
    &--name {
      font: normal normal bold 24px/30px Noto Sans CJK JP;
      color: #242424;
    }
    &--info1 {
      display: flex;
      gap: 8px;
    }
    &--info2 {
      display: flex;
      gap: 40px;
    }
  }
  &--title_left {
    width: 33%;
    display: flex;
    align-items: center;
    gap: 8px;
    border-right: 1px solid #f3f3f3;
  }
  &--title_right {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-left: 8px;
  }

  &--tabs {
    margin-top: -16px;
  }
}

@media screen and (max-width: 800px) {
  .staff_pay_edit {
    &--title {
      @include shadow-box();
      min-height: 64px;
      background: #ffffff;
      display: flex;
      padding: 20px 24px;
      flex-flow: column;
      gap: 10px;

      &--img_area {
        display: flex;
        padding-bottom: 10px;
        border-bottom: 1px solid #f3f3f3;
      }
      &--img {
        width: 48px;
        height: 48px;
        border-radius: 50%;
      }
      &--id_name {
        display: flex;
        flex-flow: column;
      }
      &--id {
        font: normal normal normal 14px Noto Sans CJK JP;
        color: #a0aab8;
        margin-bottom: 1px;

        @media screen and (max-width: 800px) {
          font-size: 12px;
        }
      }
      &--name {
        font: normal normal bold 18px Noto Sans CJK JP;
        color: #242424;
      }
      &--info1 {
        display: flex;
        gap: 8px;
        font-size: 12px;
      }
      &--info2 {
        display: flex;
        gap: 40px;
      }
    }
    &--title_left {
      width: 33%;
      display: flex;
      align-items: center;
      gap: 8px;
      border-right: 1px solid #f3f3f3;
    }
    &--title_right {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      padding-left: 8px;
    }

    &--tabs {
      margin-top: -16px;
    }
  }
}
