@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.approval_apply--main {
  font-size: 14px;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-flow: column nowrap;
  gap: 40px;
    @media screen and (max-width: 800px) { 
      overflow: hidden;
      .import_modal--title_area {
      height: 32px;
      align-items: flex-start;
      justify-content: center;
      p {
        font-size: 16px;
      }
    }
    .all_bank_modal--btndiv {
      gap: 23px;
      //height: 40px;
    }
    }
  .custom_input--area_ant_design {
    .ant-picker {
      padding: 0 11px;
      width: 141px;
      height: 44px;
    }
  }
  &--startest {
    font-size: 14px;
    width: 100%;
    overflow: auto;
    display: flex;
    flex-flow: column nowrap;
    padding: 0 64px;
  }
  &--header {
    width: 100%;
    height: 11%;
  }
  //ボタン
  &--btndiv {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding-top: 6vh;
  }
  &--message {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    gap: 24px;
    @media screen and (max-width: 800px) { 
      flex-direction: column;
      gap: 8px;
      margin-top: -8px;
    }
    &--span {
      height: 24px;
      white-space: nowrap;
    }
    &--box {
      width: 93%;
      textarea {
        height: 23vh;
      }
    }
  }
  .approval_apply--steps {
    margin: 26px 0 24px 0;
    &--status {
      color: #ffffff;
      height: 44px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &--area {
      padding: 24px 0 0 0;
      display: flex;
      justify-content: space-around;
      @media screen and (max-width: 800px) { 
        margin-top: -16px;
        padding: 0;
        overflow-x: scroll;
        justify-content: flex-start;
      }
      .info--step {
        height: 44px;
        width: 44px;
        border: 3px solid #a0aab8;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font: normal normal bold 18px Noto Sans CJK JP;
        color: #bcbcbc;
        z-index: 2;
        &--title {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &--area {
          display: flex;
          flex-flow: column;
          align-items: center;
          width: 110px;
        }
        &--divs {
          width: 100%;
          display: flex;
          justify-content: center;
          position: relative;
        }
        &--divs:not(:last-child):after {
          width: calc((100% - 44px) / 2);
          height: 4px;
          background: #a0aab8;
          border-radius: 1px;
          content: '';
          position: absolute;
          top: 22px;
          left: calc(50% + 22px);
        }
        &--divs:not(:first-child)::before {
          width: calc((100% - 44px) / 2);
          height: 4px;
          background: #a0aab8;
          border-radius: 1px;
          content: '';
          position: absolute;
          top: 22px;
          left: 0px;
        }
        &--divs.step_active::after,
        &--divs.step_active::before {
          background: #bcbcbc;
        }
        &--divs.step_wait::after,
        &--divs.step_wait::before {
          background: #f3f3f3;
        }
        &--divs.step_error::after,
        &--divs.step_error::before {
          background: #d80245;
        }
        &--divs.error_finish::after,
        &--divs.error_finish::before {
          background: #003b94;
        }
      }
    }
    &--stamp_buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 40px;
      margin: 40px 0;
    }
    &--message {
      padding-top: 6px;
    }
  }
}
