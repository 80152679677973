@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.matter_date_list {
  &--status {
    border-radius: 22px;
    width: 150px;
    min-width: 150px;
    height: 32px;
    padding: 3px 10px;
    text-align: center;
    color: #ffffff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  &--submit_buttons {
    .gw-btn {
      width: 240px;
      height: 44px;
      &:nth-child(1) {
        background-color: #ffffff !important;
        color: #2b3245;
      }
      &:nth-child(2) {
        background-color: #0056d3;
        color: #ffffff;
      }
    }
    @media screen and (max-width: 800px) {
      width: calc(100% - 40px);
      max-width: 240px;
      & > .gw-btn {
        margin: 10px 0;
      }
    }
  }
}

.multiCalendar {
  &_base {
    width: 350px;
    height: 490px;
    position: absolute;
    z-index: 9999;
    background-color: white;
    box-shadow: 0 0 12px #383f5260;
    border-radius: 6px;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      width: 4px;
      background: #A0AAB8;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      background: white;
      box-shadow: none;
    }
  }

  &_calendars_wrap {
    width: 350px;
    padding: 0 0 50px;
  }

  &_calendar {
    &_box {
      width: 100%;
      //height: 398px;
      margin: 15px 0 0;
    }

    &_header {
      width: 100%;
      height: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 15px;

      &_arrows {
        width: 48px;
        height: 100%;

        img {
          cursor: pointer;
          opacity: .6;

          &:hover {
            opacity: 1;
          }
        }
      }

      span {
        font-size: 16px;
        font-weight: bold;
        display: inline-block;
      }
    }

    &_body {
      width: 100%;

      span {
        display: inline-block;
        width: 50px;
        height: 50px;
        flex-shrink: 0;
        text-align: center;
        line-height: 50px;
        font-size: 16px;
        border-radius: 8px;
        box-sizing: border-box;
        user-select: none;

        &:hover {
          opacity: .6;
        }
      }

      &_days {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }
}
