@import '../../../../../styles/normalize';
@import '../../../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../../../styles/mixin';
@import '../../../../../styles/global';

.quote_register--mail--file_upload {
  width: 240px;
  height: 44px;
  border-radius: 6px;
  border: 1px solid #2b3245;
  background: #ffffff 0% 0% no-repeat padding-box;
  color: #2b3245;
  margin: 0;
  padding: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    opacity: 0.75;
  }
  &:active {
    opacity: 0.5;
  }
  &:disabled {
    &:hover {
      cursor: not-allowed;
      opacity: 1;
    }
  }

  &--info {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
