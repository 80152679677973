@import '../../styles/normalize';
@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

%base {
  position: relative;
  background-color: #FFFFFF;
  width: 700px;
  box-shadow: 0 0 12px #383F524D;
  border-radius: 12px;
  margin: 0 calc((100vw - 700px) / 2);
}

%font-info {
  font-weight: bold;
  font-size: 20px;
  color: #242424;
}

.login {
  &--foundation {
    height: 646px;
    top: calc((100vh - 646px) / 2);
    @extend %base;
  }

  &--logo_area {
    position: relative;
    width: 580px;
    height: 108px;
    margin: 0 auto;
    border-bottom: 2px solid #F3F3F3;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 30%;
      margin: 3% auto 0;
    }
  }

  &--form_area {
    position: relative;
    font-size: 14px;
    width: 480px;
    height: auto;
    margin: 0 auto;
  }

  &--url_area {
    width: 100%;
    height: 24px;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      width: 294px;
      height: 24px;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #7b7b7b;

      a {
        color: #7b7b7b;
      }
    }
  }
}

.changePassword {
  &--foundation {
    height: 350px;
    top: calc((100vh - 350px) / 2);
    @extend %base;
  }

  &--logo_area {
    position: relative;
    width: 425px;
    height: 80px;
    margin: 0 auto;
    border-bottom: 2px solid #F3F3F3;
    @extend %font-info;
  }

  &--form_area {
    position: relative;
    width: 480px;
    height: 220px;
    margin: 0 auto;
  }
}

.is_corporate_id {
  height: 546px;
  top: calc((100vh - 546px) / 2);
}
//ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
.forget_pass, .change_pass {
  &--foundation {
    height: 439px;
    top: calc((100vh - 439px) / 2);
    @extend %base;
  }

  &--foundation_no_input {
    height: 339px;
    top: calc((100vh - 439px) / 2);
    @extend %base;
  }

  &--title_area {
    position: relative;
    width: 580px;
    height: 91px;
    margin: 0 auto;
    border-bottom: 2px solid #F3F3F3;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0 16px;

    span {
      font: normal normal bold 24px/30px Noto Sans CJK JP;
    }
  }

  &--form_area {
    position: relative;
    font-size: 14px;
    width: 480px;
    height: auto;
    margin: 24px auto 0;
  }
}


.is_corporate_id {
  height: 546px;
  top: calc((100vh - 546px) / 2);
}

@media screen and (max-width: 800px) {

  .forget_pass, .change_pass {
    &--foundation {
      width: 90%;
      height: 360px;
      top: calc((100vh - 360px) / 2);
      margin: 0 calc((100vw - 90%) / 2);
    }

    &--title_area {
      position: relative;
      width: unquote('min(90%, 455px)');
      height: 72px;
      margin: 0 auto;
      border-bottom: 2px solid #F3F3F3;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 32px;
      padding-bottom: 16px;

      span {
        font: normal normal bold 16px/16px Noto Sans CJK JP;
      }
    }

    &--form_area {
      position: relative;
      font-size: 12px;
      width: 90%;
      height: auto;
      margin: 24px auto 0;
    }
  }

  .login {
    &--foundation {
      width: 90%;
      height: 546px;
      margin: auto;
      top: calc((100vh - 546px) / 2);
    }

    &--form_area {
      width: 100%;
      height: auto;
      margin: 0 auto;
    }

    &--url_area {
      font-size: 12px;
      margin-top: 24px;

      div {
        width: 252px;
        height: 22px;
        font-size: 12px;
      }
    }

    &--logo_area {
      width: unquote('min(90%, 455px)');
      height: 94px;

      img {
        width: unquote('min(50%, 200px)');
        margin: unquote('min(3%, 5%)') auto 0;
      }
    }
  }

  .forget_pass--foundation_no_input {
    width: 90%;
    height: fit-content;
    top: calc(50vh - 169px);
    margin: 0 auto;
  }
  .is_corporate_id {
    height: 467px;
    top: calc((100vh - 546px) / 2);
  }
}