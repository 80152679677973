@import '../../styles/normalize';
@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.profile-info {
  display: flex;
  align-items: center;
  .profile-image {
    width: 60px;
    height: 60px;
    border-radius: 60px;
    margin-right: 7px;
  }
  .profile-info-column {
    display: flex;
    flex-direction: column;
    .position-text {
      width: 166px;
      height: 20px;
      font-weight: bold;
      background: #a0aab8;
      color: #ffffff;
      text-align: center;
      margin-bottom: 10px;
      border-radius: 10px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
}
