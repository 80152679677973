@import '../../styles/normalize';
@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.search_title {
  display: flex;
  & > span {
    margin-left: 10px;
    font-weight: bold;
  }
}
