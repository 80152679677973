@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.delete_modal {
  &--wrap {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    gap: 40px;
  }

  &--text {
    &_area {
      width: 100%;
      //height: 190px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-flow: column;
      gap: 16px;

      span {
        display: inline-block;
        text-align: center;
      }
    }

    &_title {
      font-size: 24px;
      font-weight: bold;
      color: $font-color;
    }

    &_message {
      font-size: 14px;
      color: $font-color;
    }

    &_notice {
      font-size: 12px;
      color: #D80245;
      opacity: .9;
    }
  }

  &--button {
    &_area {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 40px;
      @media screen and (max-width: 800px) { 
        gap: 23px;
      }
    }
  }
}