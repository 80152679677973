@import '../../styles/normalize';
@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.custom_select {
  &--option_check_mark {
    content: '';
    width: 10px;
    height: 5px;
    border-left: 2px solid #242424;
    border-bottom: 2px solid #242424;
    transform: rotate(-45deg);
    margin-top: 6px;
  }

  &--area {
    width: 100%;
    height: 44px;
    .ant-select {
      width: 100%;
      height: 44px;
      padding-right: 16px;
      .ant-select-selector {
        width: calc(100% + 16px);
        height: 44px;
        border-radius: 6px;
        border: 0.5px solid #2b3245;
      }
    }
  }

  &--area_multiple {
    .ant-select-selection-item {
      background: #e9f2fc 0% 0% no-repeat padding-box;
      border-radius: 12px;
      color: #242424;
      height: 30px;
      line-height: 28px;
    }
    .ant-select-selection-item[title='...'] {
      border-radius: 12px;
    }
  }

  &--area:not(.custom_select--area_multiple) {
    .ant-select {
      .ant-select-selector {
        .ant-select-selection-search {
          input {
            height: 44px;
          }
        }
        .ant-select-selection-item {
          width: 20px;
          padding-left: 5px;
          padding-top: 6px;
          padding-right: 30px;
          color: #242424;
          font-size: 14px;
        }
      }

      .ant-select-clear {
        right: 13px;
        img {
          position: absolute;
          top: -8.5px;
          right: -5px;
        }
      }
    }
    .ant-select-disabled {
      .ant-select-selector {
        .ant-select-selection-item {
          width: 20px;
          padding-left: 5px;
          padding-top: 6px;
          color: #bcbcbc;
          font-size: 14px;
        }
      }
    }
    .ant-select-selection-placeholder {
      padding-left: 5px;
      padding-top: 6px;
      color: #bcbcbc;
      width: 20px;
    }
  }
}

.custom_input--error {
  .ant-select-selector {
    box-shadow: 0px 0px 8px #d8024529;
    border: 1px solid #d80245 !important;
  }
}
