@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.attend_forStaffSp {
  display: none;
}

@include sp() {
  .attend_forPc {
    display: none;
  }

  .attend_forStaffSp {
    display: unset;
  }
}

.staff_plan_confirm {
  &--bar_button_area {
    width: 240px;
    height: 44px;
    background-color: #28d300;
  }

  &--table {
    width: 100%;
  }

  &--td_0 {
    padding-left: 40px;
  }

  &--th_item {
    font-size: 14px;
    color: #242424;
    font-weight: normal;
  }

  &--calender_status_ture {
    width: 74px;
    height: 32px;
    border-radius: 22px;
    color: #FFFFFF;
    background-color: #bcbcbc;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }

  &--assign_info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    font-size: 14px;
    color: #7b7b7b;
  }
}

.assign_circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #D80245;
}

.assign_border {
  width: 10px;
  height: 1.5px;
  background-color: #BCBCBC;
}

.matter_name_area {
  text-align: left;
}

.staff_plan {
  &--custom_matter_name-area {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    padding: 15px 0;
  }

  &--custom_click_elm {
    cursor : pointer;
    color: #7B7B7B;
    border-bottom: 1px solid #7B7B7B;

    &:hover {
      opacity: .6;
    }
  }
}