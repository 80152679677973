@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.matter_register {
  &--matter_number_field {
    width: 100%;
    height: 18px;
    display: flex;
    flex-direction: row !important;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 8px;

    .custom_input--input_area {
      width: 100%;
      height: 18px;

      #matterNumber {
        width: 100%;
        height: 18px;
        padding: 0;
      }
    }
  }
  &--base {
    .bottom_btn_area {
      height: 92px;
      justify-content: center;
    }
    .register_base--detail_info_base {
      z-index: unset;
    }

    .register_base--detail_info_tab_page {
      @media screen and (max-width: 800px) {
        margin: 8px 0 0 0;
      }
    }
  }
}
