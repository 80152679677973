@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

@import '../csvImportModal/style';

.attendance_process_modal {

  &--select_area {
    .custom_input--select_box_area {
      .custom_input--select_box_input_area {
        .custom_select--area_multiple {
          .ant-select {
            .ant-select-selector {
              height: 44px;
              box-sizing: border-box;
            }
          }
        }
      }
    }
  }

  &--title_area {
    @extend .import_modal--title_area;
    height: 45px;
  }
  &--main {
    font-size: 14px;
    width: 100%;
    overflow: auto;
    display: flex;
    flex-flow: column nowrap;
    gap: 40px;
    .custom_input--radio_title_area {
      width: 148px;
    }
  }
  &--sel_date {
    display: flex;
    flex-flow: column nowrap;
    gap: 24px;
    .custom_input--label_ant_design {
      width: 148px;
    }
    .custom_input--area_ant_design {
      .ant-picker {
        padding: 0 11px;
        width: 129px;
        height: 42px;
      }
    }
  }
  &--view_date {
    display: flex;
    align-items: center;
    gap: 8px;
    @media screen and (max-width: 800px) { 
      align-items: flex-end;
    }
  }
  &--btndiv {
    display: flex;
    justify-content: center;
    gap: 24px;
    @media screen and (max-width: 800px) { 
      width: 100%;
    }
  }
  &--tooltip {
    white-space: pre-line;
  }
  &--onlyLabel {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-shrink: 0;
    width: 138px;
    font-weight: bold;
    @media screen and (max-width: 800px) { 
      justify-content: flex-start;
    }
  }
}
@media screen and (max-width: 800px) {
  .attendance_process_modal--main::-webkit-scrollbar{
    display:none;
  }
  .ant-select-selector {
    box-sizing: border-box;
  }
  .ant-tooltip {
    max-width: 80%;
  }
  .ant-select-dropdown {
    z-index: 1080;
  }
  .modal_base--dialog {
    max-height: calc(100% - 112px);
    //position: absolute;
    top: 16px;
  }
  .attendance_process_modal {
    &--title_area {
      height: 26px;
      margin-top: 32px;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      p {
        font-size: 16px;
      }
    }
    &--sel_date {
      .custom_input--label_ant_design {
        width: 100%;
      }
    }
    &--btndiv {
      margin-bottom: 32px;
    }
  }
}
