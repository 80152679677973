@import '../../../../styles/normalize';
@import '../../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../../styles/mixin';
@import '../../../../styles/global';

.report_tab {
  .caculate_table .ant-table-summary {
    z-index: 0 !important;
  }
  & > div {
    gap: 30px;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .custom-label {
    width: 170px;
    display: inline-block;
    color: #646464;
    text-align: right;
    margin-right: 30px;
  }
  &--column {
    @include requiredItem();
  }
  .guest_attribute_input {
    .custom_select--area {
      width: 100px;
    }
  }
  &--table_title {
    color: #646464;
    margin-top: 15px;
  }
  &--table_container {
    background-color: #e9f2fc;
    padding: 20px;
    margin: 5px 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    overflow-x: scroll;
    gap: 35px;
    .caculate_table {
      width: unset;
    }
    .performance_table--layout_container {
      max-height: none;
      margin-top: 0;
      overflow-x: hidden;
      .caculate_table {
        border: 3px solid #a0aab8;
      }
    }
  }
  &--images {
    background-color: #e9f2fc;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    .image_uploader {
      max-width: 100%;
      max-height: 100%;
    }
  }
  &--text {
    color: #646464;
    margin-bottom: 10px;
    .folder-tip {
      font-size: 13px;
      color: #7b7b7b;
    }
    .folder-name {
      margin-right: 30px;
    }
  }
  &--custom_input {
    display: flex;
    gap: 25px;
    align-items: center;
    & > label {
      color: #646464;
      width: 170px;
      text-align: right;
      min-width: 170px;
    }
  }
  &--wrap_label {
    align-self: start;
    margin-top: 10px;
  }
  &--custom_input_wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    max-width: 500px;
  }
  .free_form_cell {
    textarea {
      width: 100%;
      padding: 15px;
    }
  }
}

.report-btn {
  background-color: #0056d3;
  margin: 0;
}
.end-report-btn {
  background-color: #d80245;
}

@media screen and (max-width: 800px) {
  .report_tab {

    & > div {
      gap: 16px;
    }
    &--for_sp_label {
      font-size: 12px;
      margin-bottom: 8px;
    }
    &--text {
      display: flex;
      flex-direction: column;
    }
    &--images {
      padding: 10px 1px;
      & > span,
      & .image_uploader {
        width: calc((85vw - 55px) / 2);
        height: calc((85vw - 55px) / 2);
        .image_uploader--upload-image {
          max-width: calc((85vw - 55px) / 2);
        }
      }
    }
    &--custom_input {
      flex-wrap: wrap;
      gap: 16px;
      & > label {
        text-align: left;
        width: 100%;
      }
      & > .gw-checkbox-wrapper {
        align-self: flex-start;
      }
    }

    .guest_attribute_input {
      .custom_input--area {
        max-width: 80%;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-flow: row;
        .custom_input--label {
          width: 50px !important;
          margin-right: 0 !important;
          & > label {
            font-size: 12px;
          }
        }
      }
      .custom_input--select_box_area {
        margin-bottom: 5px;
        flex-flow: row;
        align-items: center;
        .custom_input--select_box_label {
          align-items: center;
          justify-content: flex-end;
          & > label {
            color: #242424;
          }
        }
      }
      .report_tab--custom_input_wrap {
        .custom_input--select_box_label {
          width: 50px;
          & > label {
            color: #242424;
          }
        }
        .custom_input--select_box_area {
          width: unset !important;
          margin-right: 0 !important;
        }
      }
    }
  }
}

.recruit_matter_detail {
  &--staff_report_input_area {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    padding: 16px 16px 0;

    @media screen and (max-width: 800px) {
      padding: 0 16px;
      gap: 14px;
    }

    .custom_input--area_ant_design {
      .custom_input--label_ant_design {
        label {
          @media screen and (max-width: 800px) {
            color: #646464;
            font: normal normal bold 12px Noto Sans CJK JP;
          }
        }
      }
      .custom_input--input_area_ant_design {
        .ant-picker {
          width: 115px;
          @media screen and (max-width: 800px) {
            width: 140px;
          }
        }
      }
    }
  }
  &--alert_message {
    font-size: 14px;
    color: #D80245;
    margin-left: 10px;

    @media screen and (max-width: 800px) {
      font-size: 12px;
      margin-left: 0;
    }
  }
}

.new_area {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;

  label {
    width: 170px;
    text-align: right;
    flex-shrink: 0;
    margin-top: 10px;
    font-size: 14px;
    color: #646464;
    @media screen and (max-width: 800px) {
      font-size: 12px;
    }
  }

  &--input {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;

    .custom_input--select_box_area {
      width: auto;
      .custom_input--select_box_label {
        width: auto;
        align-items: center;

        label {
          width: auto;
          margin-top: 0;
        }
      }
      .custom_input--select_box_input_area {
        width: auto;
        .custom_select--area {
          width: 100px;
        }

      }

    }
  }
}
