@import '../../../../styles/normalize';
@import '../../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../../styles/mixin';
@import '../../../../styles/global';

.quote_flow_list {
  #approval_flow_name {
    width: 17%;
  }
  .add_button--text {
    z-index: 8000;
  }
}
