@import '../../../../styles/normalize';
@import '../../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../../styles/mixin';
@import '../../../../styles/global';

.staff_pay_approval_sp {
  &--body {
    font-family: Noto Sans CJK JP;
    width: 100%;
  }

  &--area {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 48px;
  }

  &--search {
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 88px;

    &_input,
    &_input_short {
      width: 424px;
      height: 44px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 24px;
    }

    &_input_short {
      width: 200px;
    }
  }

  &--title {
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 30px;
    text-align: center;
    width: 100%;
  }

  &--sub_title {
    font: normal normal bold 16px/20px Noto Sans CJK JP;
    padding-bottom: 5px;
    color: #646464;
  }

  &--pay_detail {
    width: 100%;
    height: 50px;
    display: inline-block;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
  }

  &--item_box_title {
    display: inline-flex;
    width: 100%;
    height: 50px;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font: normal normal 16px/20px Noto Sans CJK JP;
    color: #ffffff;
    background-color: #a0aab8;
    border: #a0aab8 0.25px solid;

    &_left {
      padding-left: 15px;
    }

    &_right {
      padding-right: 15px;
    }
  }
  &--item_box_contents {
    display: inline-flex;
    width: 100%;
    height: 50px;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font: normal normal 16px/20px Noto Sans CJK JP;
    color: #6a6f7c;
    background-color: #ffffff;
    border-left: #a0aab8 0.25px solid;
    border-right: #a0aab8 0.25px solid;
    border-bottom: #a0aab8 0.25px solid;

    &_left {
      padding-left: 15px;
    }

    &_right {
      padding-right: 15px;
    }

    &_border {
      border-bottom: #a0aab8 1.5px double;
    }
  }

  &--item_box_left {
    width: 25%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;

    .staff_pay_approval--item_box_title {
      border-radius: 3px 0 0 0;
      border-right: 1px solid #a0aab8;
    }
    .staff_pay_approval--item_box_contents {
      border-radius: 0 0 0 3px;
      border-right: 1px solid #a0aab8;
      border-left: 1px solid #a0aab8;
      border-bottom: 1px solid #a0aab8;
    }
  }

  &--item_box_center {
    width: 25%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;

    .staff_pay_approval--item_box_title {
      border-right: 1px solid #a0aab8;
      border-radius: 0;
    }

    .staff_pay_approval--item_box_contents {
      border-right: 1px solid #ffffff;
      border-bottom: 1px solid #a0aab8;

      .staff_pay_approval--item_box_center_item {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &--item_box_right {
    width: 25%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;

    .staff_pay_approval--item_box_title {
      border-radius: 0 3px 0 0;
    }
    .staff_pay_approval--item_box_contents {
      border-radius: 0 0 3px 0;
      border-right: 1px solid #a0aab8;
      border-bottom: 1px solid #a0aab8;
    }
  }

  &--solo_contents {
    border-radius: 0 0 3px 3px;
    border-right: 1px solid #a0aab8;
    border-left: 1px solid #a0aab8;
    border-bottom: 1px solid #a0aab8;
  }

  &--no-data {
    width: 100%;
    font-size: 14px;
    color: #2b3245;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 31%;
  }

  &--diligence_top {
    display: inline-flex;
    width: 100%;
    height: 36px;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font: normal normal 16px/20px Noto Sans CJK JP;
    color: #ffffff;
    background-color: #6a6f7c;
    border: #a0aab8 0.25px solid;

    &_left {
      padding-left: 15px;
    }

    &_right {
      padding-right: 15px;
    }
  }

  &--diligence_title {
    display: inline-flex;
    width: 100%;
    height: 36px;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 14px;
    font: normal normal 16px/20px Noto Sans CJK JP;
    color: #ffffff;
    background-color: #a0aab8;
    border-left: #a0aab8 0.25px solid;
    border-right: #a0aab8 0.25px solid;
    border-bottom: #ffffff 0.25px solid;

    &_left {
      padding-left: 15px;
    }

    &_right {
      padding-right: 15px;
    }
  }

  &--diligence_contents {
    display: inline-flex;
    width: 100%;
    height: 36px;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    font-size: 14px;
    font: normal normal 16px/20px Noto Sans CJK JP;
    color: #242424;
    background-color: #ffffff;
    border-left: #a0aab8 0.25px solid;
    border-right: #a0aab8 0.25px solid;
    border-bottom: #ffffff 0.25px solid;

    &_left {
      padding-left: 15px;
    }

    &_right {
      padding-right: 15px;
    }
  }

  &--diligence_contents_list {
    display: inline-flex;
    width: 100%;
    height: 36px;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    font-size: 14px;
    font: normal normal 16px/20px Noto Sans CJK JP;
    color: #242424;
    background-color: #ffffff;
    border-left: #a0aab8 0.25px solid;
    border-right: #a0aab8 0.25px solid;
    border-bottom: #ffffff 0.25px solid;

    &_left {
      padding-left: 55px;
    }

    &_right {
      padding-right: 55px;
    }

    &_border_last {
      border-top: #a0aab8 0.25px solid;
      height: 40px;
    }
  }

  &--copy_right {
    width: 100%;
    line-height: 30px;
    font-size: 14px;
    color: #383f52;
    letter-spacing: 3px;
    text-align: center;
  }

  &--submit_buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    padding-top: 24px;

    @include sp() {
      width: 100vw;
      justify-content: center;
      gap: 23px;
    }
  }

  &--submit_btn_area {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: rgba(106, 111, 124, 0.6);
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    z-index: 30;
    transition: width .2s;
  }
}
