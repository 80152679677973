@import '../../styles/normalize';
@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

@media screen and (max-width: 800px) {

  .multi_select {
    &--select_label_area {
      width: 100%;
      height: auto;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 8px;
    }

    &--label {
      width: 100%;
      height: 18px;
      margin-top: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 12px;
      color: #646464;
    }

    &--select {
      width: 100%;
      height: auto;

      .ant-select {
        width: 100%;
        height: 40px;
        padding-right: 16px;

        .ant-select-selector {
          width: calc(100% + 16px);
          height: 40px;
          border-radius: 6px;
          border: 0.5px solid #2b3245;

          .ant-select-selection-placeholder {
            font-size: 12px;
            padding-top: 1px;
          }

          .ant-select-selection-item {
            padding-top: 0;
            font-size: 12px;
          }

          .ant-select-selection-search {
            .ant-select-selection-search-input {
              height: 38px;
              font-size: 12px;
              padding-left: 5px;
            }
          }

          .ant-select-selection-overflow {
            .ant-select-selection-overflow-item {
              .ant-select-selection-item-content {
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    &--display_value_area {
      .multi_select--item {
        width: auto;
        height: 40px;
        padding: 10px 12px 10px 16px;
        gap: 6px;

        .multi_select--value {
          font-size: 12px;
          color: #242424;
        }
      }
    }
  }
  //.custom_input {
  //
  //  &--select_box_area, &--select_box_area_column {
  //    width: 100%;
  //    height: auto;
  //    display: flex;
  //    flex-flow: column;
  //    justify-content: flex-start;
  //    align-items: flex-start;
  //    gap: 8px;
  //
  //    .custom_input {
  //
  //      &--select_box_label {
  //        width: 100%;
  //        height: 18px;
  //        display: flex;
  //        justify-content: flex-start;
  //        align-items: center;
  //
  //        label {
  //          line-height: 18px;
  //          font-size: 12px;
  //          color: #646464;
  //        }
  //      }
  //
  //      &--select_box_input_area {
  //        width: 100%;
  //        height: 40px;
  //
  //        .custom_select--area, .custom_select--area_multiple {
  //          width: 100%;
  //          height: 40px;
  //
  //          .ant-select {
  //            width: 100%;
  //            height: 40px;
  //            padding-right: 16px;
  //
  //            .ant-select-selector {
  //              width: calc(100% + 16px);
  //              height: 40px;
  //              border-radius: 6px;
  //              border: 0.5px solid #2b3245;
  //
  //              .ant-select-selection-placeholder {
  //                font-size: 12px;
  //                padding-top: 4px;
  //              }
  //
  //              .ant-select-selection-item {
  //                padding-top: 4px;
  //                font-size: 12px;
  //              }
  //
  //              .ant-select-selection-search {
  //                .ant-select-selection-search-input {
  //                  height: 38px;
  //                  font-size: 12px;
  //                  padding-left: 5px;
  //                }
  //              }
  //
  //              .ant-select-selection-overflow {
  //                .ant-select-selection-overflow-item {
  //                  .ant-select-selection-item-content {
  //                    font-size: 12px;
  //                  }
  //                }
  //              }
  //            }
  //          }
  //
  //          .ant-select-multiple {
  //            .ant-select-selector {
  //              width: calc(100% + 16px);
  //              height: 40px;
  //              border-radius: 6px;
  //              border: 0.5px solid #2b3245;
  //
  //              .ant-select-selection-placeholder {
  //                font-size: 12px;
  //                padding-top: 2px;
  //              }
  //
  //              .ant-select-selection-item {
  //                padding-top: 0;
  //                font-size: 12px;
  //              }
  //            }
  //
  //            .ant-select-clear {
  //              right: 14px;
  //
  //              //img {
  //              //  margin-bottom: 20px;
  //              //}
  //            }
  //          }
  //
  //          .ant-select-arrow {
  //            top: 52%;
  //          }
  //        }
  //      }
  //
  //    }
  //  }
  //
  //}
}