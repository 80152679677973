@import '../../styles/normalize';
@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

@media screen and (max-width: 800px) {
  .ant-select-auto-complete .ant-select-selector {
    height: 40px !important;
    align-items: center;
    line-height: 40px;
    & input {
      font-size: 12px;
    }
    & .ant-select-selection-placeholder {
      padding-left: 5px;
      font-size: 12px;
      color: #bcbcbc;
    }
  }
  .custom_input {
    &--area_ant_design {
      width: 100%;
      height: auto;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 8px;

      .custom_input--label_ant_design {
        width: 100%;
        height: 18px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        label {
          line-height: 18px;
          font-size: 12px;
          color: #646464;
        }
      }
    }

    &--input_area_ant_design {
      .ant-picker {
        .ant-picker-input {
          input {
            font-size: 12px;
            line-height: 18px;

            &::placeholder {
              font-size: 12px;
              color: #bcbcbc;
            }
          }
        }
      }
    }
  }

  .no_display_input_field {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;

    .custom_input {
      &--label {
        width: 100%;
        height: 18px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        label {
          font-size: 12px;
          color: #646464;
        }
      }

      &--input_area {
        width: 100%;
        height: 40px;

        input {
          font-size: 12px;
        }
      }
    }
  }

  .custom_input {
    &--text_area {
      width: 100%;
      height: auto;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 8px;

      .custom_input {
        &--label {
          width: 100%;
          height: 18px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          label {
            font-size: 12px;
            color: #646464;
          }
        }

        &--input_area {
          textarea {
            font-size: 12px;
            color: #242424;
          }

          .custom_input--unit {
            width: auto;
            height: 18px;
            font-size: 12px;
            color: #242424;
            white-space: nowrap;
            margin-left: 8px;
          }
        }
      }
    }
  }

  //ラジオボタン
  .custom_input {
    &--radio_button {
      width: 100%;
      height: auto;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 16px;
    }

    &--radio_title_area {
      width: 100%;
      height: 18px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      p {
        line-height: 18px;
        font-size: 12px;
        color: #646464;
      }

      span {
        line-height: 18px;
        font-size: 12px;
        color: #d80245;
      }
    }

    &--radio_input_area {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      row-gap: 16px;
      column-gap: 48px;
    }

    &--radio_display_area {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .custom_input--radio_button_text {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: #242424;
      }
    }
  }

  //チェックボックス
  .custom_input {
    &--check_box_area {
      .check_box--label_text {
        font-size: 12px;
        line-height: 18px;
        color: #242424;
      }
    }
  }

  //column指定のインプット
  .custom_input {
    &--area_column_not_error {
      width: 100%;
      height: auto;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 8px;

      .custom_input {
        &--label {
          width: 100%;
          height: 18px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-shrink: 0;

          label {
            line-height: 18px;
            font-size: 12px;
            color: #646464;

            span {
              font-size: 12px;
              color: #d80245;
            }
          }
        }

        &--input_area {
          width: 100%;
          height: 41px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 8px;

          .custom_input--input_field {
            font-size: 12px;
            color: #242424;
            border: 0.5pt solid #2B3245;
          }

          .custom_input--unit {
            width: auto;
            height: 18px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 12px;
            color: #242424;
            white-space: nowrap;
          }
        }
      }
    }
  }

  //インプット
  .custom_input {
    &--area {
      width: 100%;
      height: auto;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 8px;

      .custom_input {
        &--label {
          width: 100%;
          height: 18px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-shrink: 0;

          label {
            line-height: 18px;
            font-size: 12px;
            color: #646464;
          }

          .custom_input--label_disabled {
            font-size: 12px;
            color: #bcbcbc;
          }
        }

        &--input_area {
          width: 100%;
          height: 41px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 8px;

          .custom_input--input_field {
            font-size: 12px;
            color: #242424;
          }

          .custom_input--unit {
            width: auto;
            line-height: 18px;
            font-size: 12px;
            color: #242424;
            white-space: nowrap;
            flex-shrink: 0;
          }

          .custom_input--unit_disable {
            width: auto;
            line-height: 18px;
            font-size: 12px;
            color: #bcbcbc;
            white-space: nowrap;
            flex-shrink: 0;
          }
        }
      }
    }
  }

  //パスワード
  .custom_input--password_not_error {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-end;

    .custom_input--icon_field {
      position: relative;

      .icon {
        position: absolute;
        right: 16px;
        bottom: 8px;
      }
    }

    .custom_input--password_area {
      width: 100%;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 8px;

      .custom_input {
        &--password_label {
          width: 100%;
          height: 18px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-shrink: 0;

          label {
            line-height: 18px;
            font-size: 12px;
            color: #646464;

            span {
              color: #d80245;
            }
          }
        }

        &--password_input_area {
          width: 100%;
          height: 41.5px;
          display: flex;
          flex-flow: column;
          justify-content: flex-start;
          align-items: center;

          .custom_input--input_field {
            font-size: 12px;
            color: #242424;
          }
        }
      }
    }
  }
}

.ant-select-auto-complete {
  width: 100%;
  .ant-select-selector {
    padding-left: 15px !important;
    border-radius: 6px !important;
    border: 0.5px solid #2b3245 !important;
    height: 44px;
    align-items: center;
  }
}

