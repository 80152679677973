@import '../../styles/normalize';
@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.header {
  &--base {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 0 12px #2424244d;
    z-index: 9000;
  }

  &--logo_area {
    height: 32px;
    width: 158px;
    margin: 0 56px;

    img {
      width: 100%;
    }
  }

  @media screen and (max-width: 800px) {
    &--base {
      width: 100vw;
      top: 0;
      overflow: hidden;
      position: fixed;
    }
    &--logo_area {
      height: 100%;
      width: fit-content;
      margin: 0 16px;
      line-height: 60px;

      img {
        width: unset !important;
        height: 30px;
      }
    }
  }

  &--account_area {
    position: relative;
    height: 52px;
    margin: 0 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;

    img {
      height: 100%;
      border-radius: 50%;
      cursor: pointer;
      aspect-ratio: 1;
      object-fit: cover;
      object-position: center center;
    }

    p {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-flow: column;
      margin: 0 0;
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 800px) {
    &--account_area {
      display: none;
    }
  }

  &--sp_hamburger {
    display: none;
  }

  @media screen and (max-width: 800px) {
    &--sp_hamburger {
      display: block;
      position: relative;
      height: 22px;
      width: 24px;
      margin-right: 24px;
      cursor: pointer;

      span {
        display: inline-block;
        position: absolute;
        width: 24px;
        height: 4px;
        background-color: #0f2341;
      }

      &_line {
        &_first {
          top: 0;
          transition: all .3s;
          perspective: 100;

          &_open {
            transform-origin: center;
            transform: rotate(45deg) translateY(12.5px);
            width: 30px !important;
            right: -12px;
          }
        }
        &_second {
          top: 9px;
          transition: all .3s;
          perspective: 100;

          &_open {
            opacity: 0;
          }
        }
        &_third {
          bottom: 0;
          transition: all .3s;
          perspective: 100;

          &_open {
            transform-origin: center;
            transform: rotate(-45deg) translateY(-12.5px);
            width: 30px !important;
            right: -12px;
          }
        }
      }
    }
  }

  &--account_action_box {
    width: 160px;
    height: 88px;
    background-color: #242424cc;
    border-radius: 6px;
    position: absolute;
    top: 70px;
    left: -54px;
    z-index: 9000;

    a {
      text-decoration: none;
    }
  }

  &--account_action {
    display: block;
    width: 100%;
    height: 44px;
    margin: 0 0;
    padding-left: 30px;
    text-align: left;
    font-size: 14px;
    line-height: 44px;
    color: #ffffff;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
      background-color: #242424;
    }
  }

  &--sp_side_menu {
    display: none;
  }

  @media screen and (max-width: 800px) {
    &--sp_side_menu {
      display: unset;

      &_overlay {
        position: fixed;
        width: 100vw;
        height: calc(100vh - 60px);
        left: 0;
        top: 60px;
        background-color: #242424;
        opacity: 0;
        z-index: 9998;
        transition: all .3s;
        display: none;

        &_open {
          display: block;
          opacity: .6;
        }
      }

      &_base {
        position: fixed;
        width: 200px;
        height: calc(100% - 60px);
        right: -280px;
        top: 60px;
        z-index: 9999;
        opacity: 0;
        transition: all .3s;
        overflow-y: auto;

        &_open {
          opacity: 1;
          right: 0;
        }
      }

      &_account_area {
        position: sticky;
        width: 100%;
        height: fit-content;
        padding: 12px 0 16px;
        right: 0;
        top: 0;
        background-color: #484e60;
        z-index: 1;
      }

      &_account_image_area {
        height: 65px;
        width: 100%;
        text-align: center;
        margin: 0 0 12px;

        img {
          display: inline-block;
          height: 65px;
          width: 65px;
          text-align: center;
          object-fit: cover;
          object-position: center center;
          border-radius: 50%;
        }
      }

      &_account_info_area {
        span {
          display: block;
          width: 100%;
          box-sizing: border-box;
          padding: 0 12px;
          color: white;
          font-size: 12px;
          text-align: center;
          line-height: 16px;
        }

        p {
          width: 100%;
          box-sizing: border-box;
          padding: 0 12px;
          color: white;
          font-size: 14px;
          text-align: center;
          line-height: 20px;
          margin: 4px 0 0 0;
        }
      }

      &_menu_area {
        position: relative;
        width: 280px;
        height: fit-content;
        background-color: #484e60;
        padding-bottom: 50px;

        //overflow-y: auto;
      }

      &_link {
        width: 100%;
        height: 56px;
        border-bottom: 1px solid #7B7B7B;
        background-color: #484e60;
        color: #ffffff;
        font-size: 14px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 0 0 25.2px;
        margin: 0;

        &_img_area {
          width: 52px;
          text-align: center;

          img {
            opacity: 0.6;
          }
        }

        &_active {
          @extend .header--sp_side_menu_link;
          background-color: #0056d3;

          img {
            opacity: 1;
          }
        }

        &_ul {
          list-style: none;
          padding: 0;
          margin: 0;
          overflow: hidden;
          max-height: 56px;
          z-index: 9997;
          transition: all .3s;

          &.show {
            max-height: 1000px;
          }
        }

        &_sub {
          width: 100%;
          opacity: 0;
          height: 56px;
          border-bottom: 1px solid #7B7B7B;
          background-color: #484e60;
          color: #ffffff;
          font-size: 14px;
          padding-left: 26px;
          line-height: 56px;
          transition: all .3s;

          &.show {
            opacity: 1;
          }

          &_active {
            @extend .header--sp_side_menu_link_sub;
            background-color: #0056d3;
          }
        }
      }
    }
  }
}

.hidden {
  display: none;
}
