@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.client_register {
  &--area {
    .register_base--container {
      .register_base--basic_info {
        .register_base--basic_info_image_area {
          .register_base--basic_info_image_area_wrap {
            background-color: #F3F3F3;
          }
        }
      }
    }
  }

  &--client_id_field {
    align-items: flex-end;

    .custom_input--input_area {
      #clientId {
        padding: 20px 15px 0;
      }
    }
  }

  &--submit_buttons {
    width: 520px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }
}

.img_register {
  .register_base--basic_info_image_area {
    img {
      background-color: #f3f3f3;
    }
  }
}

@media screen and (max-width: 800px) {
  .client_register {
    &--submit_buttons {
      width: 100%;
      height: 44px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 23px;
      padding-right: 16px;
      padding-left: 16px;
    }

    &--client_id_field {
      width: 100%;
      height: 18px;
      display: flex;
      flex-direction: row !important;
      justify-content: flex-start;
      align-items: flex-end;
      gap: 8px;

      .custom_input--input_area {
        width: 100%;
        height: 18px;

        #clientId {
          width: 100%;
          height: 18px;
          padding: 0;
        }
      }
    }
  }
}
