@import '../../../../styles/normalize';
@import '../../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../../styles/mixin';
@import '../../../../styles/global';

.corporate_register--def_set {
  display: flex;
  flex-flow: column;
  gap: 24px;
  margin-top: 26px;
  &--attend_closing_day {
    display: flex;
    align-items: center;
    gap: 24px;
    @media screen and (max-width: 800px) {
      flex-direction: column;
      gap: 8px;
      font-size: 12px;
      justify-content: flex-start;
      align-items: flex-start;
    }
    &--sel {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
  &--matter_open {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-top: -16px;
    &--sel {
      display: flex;
      align-items: center;
      gap: 18px;
    }
  }
}

.corporate_register {

  &--schedule_settings {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    margin-top: 16px;
  }

  &--schedule_settings_label {
    width: 175px;
    text-align: right;
    height: 24px;
    color: #646464;
    font: normal normal bold 14px Noto Sans CJK JP;
    @media screen and (max-width: 800px) {
      text-align: left;
    }
  }

  &--schedule_settings_items {
    width: 100%;
    height: auto;
    background-color: #E9F2FC;
    border-radius: 3px;
    padding: 24px 0;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    @media screen and (max-width: 800px) {
      padding: 24px 16px;
    }
  }

  &--schedule_settings_unity {
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    color: #242424;
    font-size: 14px;
    @media screen and (max-width: 800px) {
      height: auto;
      font-size: 12px;
    }
  }
}
