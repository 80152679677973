@import '../../styles/normalize';
@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.input_item {
  border: 1px solid $border-grey;
  padding: 15px 80px;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  position: relative;
  &--main {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .custom_input--area {
    max-width: 500px;
    margin-right: 30px;
  }
  .custom_input--label {
    width: 56px;
  }
  & > textarea {
    margin: 0 87px;
    width: auto !important;
    display: block;
    width: auto;
  }
  @media screen and (max-width: 800px) {
    padding: 15px 6px;
    &--main {
      flex-direction: column;
    }
    .custom_input--area {
      margin-right: 0;
    }
    .report_switch {
      margin: 10px 0;
    }
    .custom_input--text_area .custom_input--label {
      display: none;
    }
  }
}
