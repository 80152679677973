@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.cost_setting_modal {
  overflow-x: scroll;
  max-height: 700px;
  padding-bottom: 40px;

  @include sp() {
    max-height: calc(100vh - 192px);
    overflow-x: hidden;
    padding: 0 16px 40px;
  }

  &_table_wrapper {
    width: 100%;
    overflow-x: scroll;
  }

  .head_table {
    thead {
      text-align: center;
    }
    width: 40% !important;
  }
  table:not(.head_table) {
    thead > tr > th {
      padding-left: 20px;
    }
    width: calc(100% - 60px) !important;
  }
  table {
    border-collapse: collapse;
    margin: 0 30px;

    @include sp() {
      margin: 0 0 16px;
      min-width: 800px;
    }

    td,
    th {
      border: 1px solid #a0aab8;
    }
    &:nth-child(2) {
      height: 104px;
      width: 60%;
    }
    &:nth-child(n + 3) {
      & > tbody > tr > td {
        padding: 0 20px;
        text-align: start;
        &:nth-child(1) {
          width: 32%;
        }
        &:nth-child(2):not(.cost_setting_modal--label-td) {
          width: 28%;
        }
      }
      width: 100%;
    }
    &:nth-child(3) {
      height: 104px;
    }
    &:nth-child(4) {
      & > tbody > tr > td {
        padding: 12px 20px;
      }
    }
    margin-bottom: 15px;
    & > thead {
      background-color: #6a6f7c;
      color: white;
      height: 24px;
    }
    & > tbody {
      text-align: center;
    }
  }
  &--actions {
    display: flex;
    justify-content: center;
    gap: 40px;
    margin-top: 50px;
  }
  .no-border {
    border: 0px none;
  }
  &--label-td {
    background-color: #6a6f7c;
    color: #ffffff;
    font-weight: bold;
    & + td {
      text-align: end !important;
    }
  }
  .amount_text {
    font-weight: bold;
  }
}
