@import '../../styles/normalize';
@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.caculate_table {
  overflow: scroll;
  display: flex;
  flex-direction: column;
  width: 0;
  flex: 1;
  .ant-table-summary {
    z-index: 1 !important;
  }
  &--summary {
    .ant-table-cell {
      padding: 0;
      text-align: right;
      &:nth-child(n + 2) {
        font-weight: bold;
      }
    }
    .gw-btn {
      background-color: #a0aab8;
      width: 100%;
      height: 60px;
      margin: 0;
      border-radius: 0;
      padding: 0;
      font-weight: bold;
    }
  }

  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > thead
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > thead
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-summary
    > table
    > thead
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > tbody
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > tbody
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-summary
    > table
    > tbody
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tfoot
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > tfoot
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > tfoot
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-summary
    > table
    > tfoot
    > tr
    > th,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tfoot
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > tfoot
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > tfoot
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-summary
    > table
    > tfoot
    > tr
    > td {
    border-right-color: #a0aab8;
    border-bottom-color: #a0aab8;
    &:last-child {
      border-right: none;
    }
  }
  &--table {
    margin-top: 10px;
    border-top: 1px solid #a0aab8;
    border-bottom: 1px solid #a0aab8;
    .ant-table-thead > tr > th {
      background-color: $sky-blue;
      padding: 5px 5px;
      min-height: 55px;
      height: 55px;
    }
    .ant-table-tbody > tr > td:nth-child(1) {
      background-color: $sky-blue;
    }
    .ant-table-tbody > tr > td:nth-child(n + 2) {
      input {
        text-align: right;
        padding-right: 10px;
      }
    }
    .ant-table-tbody > tr > td {
      padding: 5px 5px;
    }
    td {
      border-right-color: #a0aab8;
      border-bottom-color: #a0aab8;
    }
  }
  border-radius: 3px;
  &--empty_title {
    width: 150px;
    display: inline-block;
    background-color: red;
  }
  & input {
    height: 44px;
    flex: 1;
    &::placeholder {
      padding-left: 10px;
      font-size: 16px !important;
    }
    &:not(.custom_input--input_field_error) {
      border-color: #a0aab8;
    }
  }
  &--summary {
    input {
      height: 60px;
      border-radius: 0;
    }
  }

  &--input_value {
    padding-left: 20px;
  }
  &--title_text {
    color: #646464;
    font-weight: bold;
    padding: 10px 15px;
  }
  &--title {
    position: relative;
    padding: 4px 4px;
    .delete_icon {
      top: 17px;
    }
  }
  &--total {
    display: flex;
    height: 52px;
    margin-top: 6px;
    padding: 4px 4px;
    align-items: center;
    .gw-btn {
      background-color: #a0aab8;
      width: 160px;
      height: 52px;
      margin: 0;
      border-radius: 0;
      font-weight: bold;
    }
    & > span {
      display: inline-block;
      border: 1px solid #a0aab8;
      line-height: 60px;
      flex: 1;
      text-align: right;
      padding: 0 10px;
      background-color: #ffffff;
      border-left: none;
      font-weight: bold;
    }
  }
}
