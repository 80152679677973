@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.staff_plan_list {
  &--search_area_user_count {
    width: 212px;
    height: 44px;
    background-color: #d80245;
    border-radius: 22px;
    display: flex;
    flex-shrink: 0;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    padding: 0 16px;
    color: #ffffff;
    font-size: 14px;
    margin-top: 32px;
  }

  &--input_area {
    width: calc(100% - 212px);
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;

    .search_select--area {
      width: 25%;
      height: auto;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 8px;
      position: relative;

      .search_select--label {
        height: 24px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 14px;
        color: #646464;
      }

      .search_select--input_ul_wrap {
        width: 100%;

        .search_select--input {
          width: 100%;

          &::placeholder {
            color: #bcbcbc;
          }
        }
      }

      img {
        position: absolute;
        top: 48px;
      }
    }
  }

  &--search_area {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    //gap: 24px;
    //padding-right: calc(212px - 24px - 100%);
  }

  &--status_div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &--status_false {
    width: 74px;
    height: 32px;
    background-color: #d80245;
    color: #ffffff;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 22px;
  }

  &--status_true {
    width: 74px;
    height: 32px;
    background-color: #e9f2fc;
    color: #6a6f7c;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 22px;
  }
}

.staff_plan--input_month_area {
  width: 153px;
  height: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  .custom_input {
    &--label_ant_design {
      width: 100%;
      height: 24px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-shrink: 0;

      label {
        font-size: 14px;
        color: #646464;
      }
    }
  }
}

.staff_plan_list {
  &--button_area {
    //width: calc(100% - 56px);
    width: 100%;
    height: 92px;
    //position: fixed;
    position: sticky;
    bottom: 0;
    background-color: rgba(106, 111, 124, 0.6);
    z-index: 30;
    padding: 24px 0;
    display: flex;
    margin: 0 auto;
    justify-content: center;
  }
}

@media screen and (max-width: 800px) {
  .staff_plan_list {
    &--search_area_user_count {
      width: 212px;
      height: 44px;
      background-color: #d80245;
      border-radius: 22px;
      display: flex;
      flex-shrink: 0;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      padding: 0 16px;
      color: #ffffff;
      font-size: 14px;
      margin-top: 32px;
    }

    &--input_area {
      width: 100%;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      gap: 24px;

      .search_select--area {
        width: 25%;
        height: auto;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 8px;
        position: relative;

        .search_select--label {
          height: 24px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 14px;
          color: #646464;
        }

        .search_select--input_ul_wrap {
          width: 100%;

          .search_select--input {
            width: 100%;

            &::placeholder {
              color: #bcbcbc;
            }
          }
        }

        img {
          position: absolute;
          top: 48px;
        }
      }
    }

    &--search_area {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      //gap: 24px;
      //padding-right: calc(212px - 24px - 100%);
    }

    &--status_div {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &--status_false {
      width: 74px;
      height: 32px;
      background-color: #d80245;
      color: #ffffff;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 22px;
    }

    &--status_true {
      width: 74px;
      height: 32px;
      background-color: #e9f2fc;
      color: #6a6f7c;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 22px;
    }

    &--button_area {
      width: 100%;
      height: 92px;
      position: fixed;
      bottom: 0;
      background-color: rgba(106, 111, 124, 0.6);
      z-index: 30;
      padding: 24px 0;
      display: flex;
      margin: 0 auto;
      justify-content: center;
    }
  }

  .staff_plan--input_month_area {
    .custom_input {
      &--label_ant_design {
        height: 20px;

        label {
          font-size: 12px;
        }
      }
    }
  }
}
