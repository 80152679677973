@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.survey_register_tab {
  &--image {
    width: 188px;
    height: 188px;
    background-color: #FFFFFF;
    border: 1px solid #f3f3f3;
    align-items: center;
    align-self: flex-start;
    display: flex;
    justify-content: center;
    position: relative;

    img {
      max-width: 188px;
      max-height: 100%;
    }
  }
  &--area {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
  }

  &--survey_title_area {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
  }

  &--survey_title_contents {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
  }

  &--survey_title_image {
    width: 237px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 19px;
  }

  &--contents_area {
    align-items: center;
    display: flex;
    flex-flow: column;
    gap: 24px;
    justify-content: flex-start;
    width: 100%;
  }

  &--contents {
    align-items: flex-end;
    border: 1px solid #a0aab8;
    display: flex;
    flex-flow: column;
    gap: 4px;
    justify-content: flex-start;
    padding: 24px 0;
    position: relative;
    width: 100%;
  }

  &--contents_info {
    align-items: flex-start;
    display: flex;
    flex-flow: column;
    gap: 24px;
    height: 100%;
    justify-content: flex-start;
    width: 100%;

    &_date_mass {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 4px;
    }

    .custom_input--radio_button {
      .answerResultFlag_disabled {
        p {
          color: #BCBCBC;
        }
      }
    }
  }

  &--question_description_label_area {
    width: 232px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }

  &--label {
    font-size: 14px;
    font-weight: bold;
    color: #646464;

    &_required {
      font-size: 14px;
      color: #D80245;
    }
  }

  &--contents_info_question_description {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
  }



  &--question_description_area {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
  }

  &--label_area {
    width: 232px;
    line-height: 32px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &--input_area {
    width: 70%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
  }

  &--required_area {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    .custom_input--toggle_button {
      margin: 0;
    }
  }

  &--choices {
    width: 100%;
    padding-left: 252px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
  }

  &--checkboxes {
    width: 100%;
    padding-left: 256px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
  }

  &--aggregation_graph_area {
    width: 700px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
  }

  &--aggregation_graph_label {
    width: 232px;
    font-size: 14px;
    font-weight: bold;
    color: #646464;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-end;
    flex-shrink: 0;

    .survey_register_tab--label {
      span:nth-of-type(2) {
        color: #D80245;
      }
    }
  }

  &--image_area {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
  }

  &--image_label {
    width: 232px;
    font-size: 14px;
    font-weight: bold;
    color: #646464;
    text-align: right;
    flex-shrink: 0;
  }

  &--image_title_area {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &--image_title {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0 15px;

    span {
      font-size: 14px;
      width: 188px;
      text-align: center;
      color: #242424;
    }
  }

  &--survey_type_area {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
  }

  &--contents_info_date_mass_add_btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 250px;
  }

  &--text_area {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;

    &_label {
      width: 232px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 14px;
      color: #646464;
      font-weight: bold;
      flex-shrink: 0;

      span {
        font-size: 14px;
        color: #D80245;
      }
    }

    &_date {
      font-size: 14px;
      color: #242424;
      white-space: nowrap;
    }
  }

  &--required_text {
    font-size: 14px;
    color: #bcbcbc;
    line-height: 32px;
  }

  &--question_description_text {
    width: 100%;
    font-size: 14px;
    color: #242424;
    word-wrap: break-word;
  }

  &--answer_method_text_area {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
  }

  &--answer_method_text {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;

    &_unity {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 24px;
      font-size: 14px;
      color: #242424;
    }
  }
}