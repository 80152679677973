@import '../../../../styles/normalize';
@import '../../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../../styles/mixin';
@import '../../../../styles/global';

.assign_info {
  padding: 20px 0 0 10px;

  &--label {
    color: #646464;
    display: inline-block;
    margin-right: 12px;
  }
  &--label,
  .custom_input--label,
  .custom_input--select_box_label {
    text-align: right;
    min-width: 150px;
  }
  @media screen and (max-width: 800px) {
    &--label {
      text-align: left;
      min-width: none;
    }
  }
  .custom_input--text_area_field {
    width: 80%;
  }
  &--checkbox_container {
    display: flex;
    margin: 20px 0;
    padding: 0;
  }
  .ant-radio-group:nth-child(5) {
    .ant-radio-wrapper {
      min-width: 150px;
    }
  }
  .ant-radio-group:last-child {
    flex: 100%;
    .assign_info--checkbox_area_other {
      display: flex;
      align-items: center;
    }
  }
  &--checkbox_area {
    margin-left: 12px;
    display: flex;
    gap: 15px 0;
    max-width: 550px;
    flex-wrap: wrap;
    .ant-radio-group {
      flex: 1;
      font-size: 14px;
    }

    @include sp() {
      span {
        font-size: 12px;
      }

      .ant-radio-wrapper {
        align-items: center;

        .ant-radio {
          top: unset;
        }
      }
    }
  }
}
.assign_setting {
  padding-bottom: 15px;
  .assign_btn {
    border-radius: 6px;
  }
  .add_line_button {
    margin-top: 20px;
  }
  .pay-base-cell {
    min-width: 310px;
    .gw-checkbox-wrapper {
      flex-direction: column;
      & > div {
        align-self: flex-end;
      }
    }
  }
  &--table_container {
    position: relative;
    border: 1px solid #a0aab8;
    overflow-x: scroll;
    margin-top: 15px;
  }
  .quantity-wrapper {
    .ant-select-selector {
      min-width: 70px;
    }
  }
  .separate {
    font-size: 20px;
    padding: 0 2px;
  }
  .gw-btn {
    background-color: #0056d3;
    height: 44px;
  }
  .gw-btn.unlock-btn {
    background-color: #d80245;
  }
  overflow-x: scroll;
  table {
    @include baseTable();
  }
  &--base_table {
    .delete_icon {
      top: 10px;
      right: 5px;
    }
    margin: 10px 0 0 0;
    td {
      &:nth-child(2) {
        min-width: 140px;
      }
      &:nth-child(3),
      &:nth-child(4) {
        min-width: 85px;
      }
      &:nth-child(5) {
        min-width: 240px;
      }
      &:nth-child(6) {
        min-width: 100px;
      }
      &:nth-child(7) {
        min-width: 300px;
      }
    }
    td {
      & > div {
        display: flex;
        align-items: center;
        .table_label {
          color: #646464;
          padding-right: 30px;
        }
        .custom_input--select_box_area {
          width: auto;
          margin-right: 30px;
        }
      }
      padding: 15px 7px;
      &.setting_btn {
        min-width: 226px;
        text-align: center;
        .gw-btn {
          width: 128px;
        }
      }
    }
  }
  .assign_setting--staff_table_empty th:last-child:not(.empty-assign) {
    min-width: 267px !important;
  }
  &--staff_table {
    width: 100%;
    .empty-label {
      color: #646464;
    }
    .punch-memo-area {
      position: relative;
      .punch-memo-image {
        width: 24px;
        height: 24px;
        position: absolute;
        right: 1px;
        top: 30px;
      }
      .custom_input--input_area {
        margin-right: 40px;
        width: 75px;
      }
    }
    td {
      padding: 15px 7px;
      & > div {
        display: flex;
        flex-direction: column;
      }
    }
    th {
      border-top: none !important;
    }
    .empty-assign {
      min-width: 261px;
      & > span {
        height: 20px;
        width: 166px;
        background: #a0aab8 0% 0% no-repeat padding-box;
        border-radius: 10px;
        color: #ffffff;
        font-weight: bold;
        font-size: 12px;
        display: inline-block;
        text-align: center;
        line-height: 20px;
        margin: 0 20px 0 40px;
      }
    }
    th,
    td {
      &:nth-child(1) {
        min-width: 35px;
        text-align: center;
      }
      &:nth-child(2) {
        min-width: 55px;
        text-align: center;
      }
      &:nth-child(3):not(.empty-assign) {
        min-width: 340px;
        & > div {
          display: flex;
          flex-direction: row !important;
          & > div {
            display: flex;
            flex-direction: column;
            &:nth-child(1) {
              align-items: center;
              margin-right: 10px;
              & > span:not(.ant-avatar) {
                display: inline-block;
                width: 60px;
                height: 20px;
                border: 1px solid #242424;
                border-radius: 10px;
                text-align: center;
                line-height: 20px;
                font-size: 14px;
                box-sizing: content-box;
              }
              .ant-avatar {
                margin-bottom: 5px;
              }
            }
            &:nth-child(2) {
              & > span {
                &:nth-child(1) {
                  background-color: #a0aab8;
                  border-radius: 10px;
                  width: 180px;
                  height: 20px;
                  text-align: center;
                  line-height: 20px;
                  color: #ffffff;
                  font-weight: bold;
                }
                &:nth-child(2) {
                  font-weight: bold;
                  & > span:nth-child(1) {
                    margin-right: 20px;
                  }
                }
                &:nth-child(4) {
                  & > span:nth-child(1) {
                    margin-right: 20px;
                  }
                }
              }
            }
          }
        }
      }
      &:nth-child(4) {
        min-width: 190px;
        & > div {
          label {
            color: #a0aab8;
            padding-right: 2px;
          }
        }
      }
      &:nth-child(5) {
        min-width: 160px;
        .warning,
        .warning > label {
          color: #d80245;
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
      &:nth-child(6) {
        min-width: 160px;
        .overtime-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        align-items: center;
        .gw-btn {
          width: 164px;
        }
      }
      &:nth-child(7) {
        min-width: 180px;
        .custom_input--label label {
          white-space: pre-line !important;
        }
      }
      &:nth-child(8) {
        min-width: 110px;
        & > div {
          text-align: center;
          & > span:nth-child(1) {
            font-weight: bold;
            font-size: 20px;
          }
          & > span:nth-child(n + 2) {
            font-size: 14px;
            color: #7b7b7b;
          }
        }
      }

      &:last-child:not(.empty-assign) {
        min-width: 210px;
        & > div {
          .assign-delete-btn {
            background-color: #d80245;
          }
          align-items: center;
          & > .gw-btn {
            width: 184px;
            margin: 10px 0;
            background-color: #ffffff;
            color: #2b3245;
            border: 1px solid #2b3245;
          }
          .unlock-btn-full {
            width: 184px !important;
          }
          & > div {
            gap: 5px;
            display: flex;
            .gw-btn {
              width: 89px;
              margin: 0;
              &:nth-child(2) {
                background-color: #ffffff;
                border: 1px solid #2b3245;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 800px) {
    overflow-x: hidden;
    table {
      display: table;
      border-collapse: collapse;
    }
    .delete_icon {
      position: relative;
    }
    .ant-descriptions-row > th,
    .ant-descriptions-row > td {
      padding-bottom: 0;
    }
    width: 100%;
    .ant-descriptions-item {
      position: relative;
    }
    .ant-descriptions {
      width: 100%;
      min-width: 100%;
      padding-bottom: 20px;
    }
    .ant-descriptions-item-content {
      align-items: center;
      justify-content: center;
      padding: 15px 15px;
    }
    .ant-descriptions-item,
    .ant-descriptions-item-container,
    .payment_item--cost_container {
      width: 100%;
    }
    .ant-descriptions-view table {
      border-collapse: collapse !important;
    }
    .base-table-sp {
      .ant-descriptions-item {
        position: relative;
      }
      .ant-descriptions-view table {
        border-collapse: collapse;
        margin-bottom: 10px;
      }
      .ant-select-selection-placeholder,
      .ant-select-selection-item {
        text-align: right;
      }
      .ant-select-selection-placeholder {
        &::after {
          padding-left: 10px;
        }
      }

      .ant-descriptions-row {
        .ant-descriptions-item-label {
          width: 50%;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #a0aab8;
          color: white;
          font-weight: bold;
          min-height: 80px;
          &::after {
            display: none;
          }
        }
        &:nth-child(1) {
          .ant-descriptions-item-label {
            width: 100%;
            background-color: white;
            .custom_input--area {
              padding-right: 10px !important;
              margin-top: 0;
            }
          }
        }
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          .custom_input--input_area input {
            text-align: right;
          }
        }
        &:nth-child(6),
        &:nth-child(9) {
          .ant-descriptions-item-label {
            width: 100%;
            min-height: 30px;
          }
        }
        &:nth-child(7) {
          .ant-descriptions-item-content {
            & > div {
              width: 100%;
              flex-direction: row;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
        &:nth-child(11) {
          .ant-descriptions-item-content {
            flex-direction: column;
            gap: 15px;
            width: 100%;
          }
          .quantity-wrapper {
            gap: 15px;
            display: flex;
            flex-direction: column;
          }
          .custom_input--select_box_area {
            flex-flow: row;
            align-items: center;
            .custom_input--select_box_label {
              width: 150px;
            }
          }
          .gw-btn {
            width: 100%;
            min-width: 120px;
            max-width: 250px;
          }
        }
      }
    }
    .detail-table-sp {
      .ant-descriptions-row,
      // .ant-descriptions-item,
      .ant-descriptions-item-container,
      .ant-descriptions-item-label,
      .ant-descriptions-item-label {
        border: none;
        outline: none;
      }
      table {
        border: 1px solid #a0aab8;
        border-collapse: collapse;
      }
      .ant-descriptions-row {
        .ant-descriptions-item-label {
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #a0aab8;
          color: white;
          font-weight: bold;
          min-height: 30px;
          border: none;
          box-shadow: none;
          outline: none;
          c &::after {
            display: none;
          }
        }
        &:nth-child(1) {
          .ant-descriptions-item-content {
            padding: 0px 15px;
          }
          .ant-descriptions-item {
            &:nth-child(1) {
              width: 80%;
              .ant-descriptions-item-label {
                width: 50%;
              }
            }
            &:nth-child(2) {
              .ant-descriptions-item-label {
                padding: 0 15px;
              }
            }
          }
        }
        &:nth-child(2) {
          .ant-descriptions-item-container,
          .ant-descriptions-item-content {
            flex-direction: column;
          }
          .ant-descriptions-item-content {
            & > div {
              width: 100%;
              flex-direction: row;
              display: flex;
              align-items: flex-start !important;
              & > div {
                &:nth-child(1) {
                  margin-right: 5px;
                }
                &:nth-child(2) {
                  flex-direction: column;
                  display: flex;
                  & > span {
                    &:nth-child(1) {
                      height: 20px;
                      width: 166px;
                      background: #a0aab8 0% 0% no-repeat padding-box;
                      border-radius: 10px;
                      color: #ffffff;
                      font-weight: bold;
                      font-size: 12px;
                      text-align: center;
                    }
                    &:nth-child(2),
                    &:nth-child(4) {
                      display: flex;
                      flex-direction: column;
                    }
                    &:nth-child(2) {
                      font-weight: bold;
                      font-size: 16px;
                    }
                  }
                }
              }
            }
            & > span {
              height: 20px;
              width: 166px;
              background: #a0aab8 0% 0% no-repeat padding-box;
              border-radius: 10px;
              color: #ffffff;
              font-weight: bold;
              font-size: 12px;
              display: inline-block;
              text-align: center;
              line-height: 20px;
              margin-bottom: 10px;
            }
            .gw-btn {
              margin: 8px 0;
            }
          }
        }
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          .ant-descriptions-item-container {
            flex-direction: column;
            display: flex;
            width: 100%;
          }
        }
        &:nth-child(3) {
          .ant-descriptions-item-content > div {
            flex-direction: column;
            display: flex;
            width: 100%;
            & > span {
              padding-left: 15%;
            }
          }
        }
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          .ant-descriptions-item-content {
            .custom_input--area {
              gap: 5px 0 !important;
            }
          }
        }
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          .ant-descriptions-item-content {
            .punch-memo-image {
              position: absolute;
              right: 10px;
              bottom: 30%;
            }
          }
        }
        &:nth-child(4) {
          .ant-descriptions-item-content {
            & > div {
              & > div {
                & > div {
                  .warning,
                  .warning > label {
                    color: #d80245;
                    img {
                      width: 20px;
                      height: 20px;
                    }
                  }
                }
              }
            }
          }
        }

        &:nth-child(6) {
          .ant-descriptions-item {
            &:nth-child(2) {
              .ant-descriptions-item-content {
                min-height: 166px;
                & > div {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                }
              }
            }
          }
          .ant-descriptions-item-container {
            flex-direction: column;
          }
        }
        &:nth-child(7) {
          .ant-descriptions-item-content {
            & > div {
              width: 100%;
              .assign-delete-btn {
                background-color: #d80245;
              }
              align-items: center;
              & > .gw-btn {
                background-color: #ffffff;
                color: #2b3245;
                border: 1px solid #2b3245;
                margin: 0;
                margin-top: 10px;
                width: 100%;
              }
              .unlock-btn-full {
                width: 100%;
              }
              & > div {
                display: flex;
                justify-content: space-between;
                .gw-btn {
                  margin: 0;
                  &:nth-child(2) {
                    background-color: #ffffff;
                    border: 1px solid #2b3245;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.matter_date_info_venue_address_area {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px 0;
  gap: 12px;
}

.matter_date_info_venue_address_data {
  margin: 0;

  span {
    display: block;
  }
}