@import '../../styles/normalize';
@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.hogehogehoge {
  width: 700px;
  margin: auto;
  background-color: #d89f9f;
}

