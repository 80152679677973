@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.survey_search_area {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;

  &--from_to {
    width: fit-content;
    height: 76px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 6px;
  }

  &--from_to_hyphen {
    line-height: 44px;
    color: #646464;
    font-size: 14px;
  }

  &--date {
    width: 100%;
    height: 76px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;

    .custom_input--label_ant_design {
      label {
        color: #646464;
        font-size: 14px;
        line-height: 24px;
      }
    }

    .custom_input--input_area_ant_design {
      .ant-picker {
        width: 165px;
      }
    }
  }
}