@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.layout_modal {
  padding: 0 30px;
  width: 'calc(85% - 80px)';
  &--title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    padding-bottom: 5px;
    border-bottom: 1px solid $grey;
    @media screen and (max-width: 800px) {
      text-align: center;
    }
  }
  &--layout {
    display: flex;
    justify-content: center;
    margin: 30px 0;
    gap: 25px;
    background-color: $sky-blue;
    padding: 30px 0;
    flex-wrap: wrap;
  }
  &--action {
    justify-content: center;
    display: flex;
    .gw-btn {
      background-color: $sky-blue;
      width: 240px;
      height: 44px;
      color: #486083;
      margin-top: 40px;
    }
  }
  .layout_pattern:nth-child(2) {
    .layout_pattern--layout-container-free {
      flex-direction: column;
      .layout_pattern--inner {
        height: 82px;
        width: 148px;
      }
    }
  }
  .layout_pattern:nth-child(3) {
    .layout_pattern--layout-container-free {
      flex-wrap: wrap;
      max-width: 160px;
      justify-content: center;
      .layout_pattern--inner {
        height: 79px;
        width: 69px;
        max-width: 69px;
      }
    }
  }
}
.layout_pattern {
  &:hover {
    cursor: pointer;
  }
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #2b3245;
  min-width: 240px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  &--layout-container {
    display: flex;
    gap: 5px;
  }
  &--inner {
    background-color: #e9f2fc;
    height: 170px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  &--layout-container-free {
    border: 2px solid $sky-blue;
    padding: 2px;
    background-color: $sky-blue;
    .layout_pattern--inner {
      border: 3px solid #ffffff;
    }
  }
  &--text {
    font-weight: bold;
    margin-top: 5px;
  }
}

@media screen and (max-width: 800px) {
  .layout_pattern {
    &--layout-container {
      flex: 1;
      width: 100%;
    }
    &--inner {
      width: 100%;
      flex: 1;
    }
    &--layout-container-free {
      align-items: center;
      min-width: 100%;
    }
    &--layout-container-free-pattern {
      &-2,
      &-3 {
        width: 100%;
        min-height: 170px;
      }
      &-2 {
        .layout_pattern--inner {
          min-width: 100%;
        }
      }
      &-3 {
        .layout_pattern--inner {
          min-width: 48%;
        }
      }
    }
  }
}
