@import '../../styles/normalize';
@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.main {
  &--page_area {
    position: fixed;
    width: 100%;
    height: calc(100vh - 60px);
    top: 60px;
    left: 0;
    display: flex;

    @media screen and (max-width: 800px) {
      width: 100vw;
      display: block;
    }
  }

  &--content_area {
    width: 100%;
    overflow-y: auto;
    background-color: #E9F2FC;

    @media screen and (max-width: 800px) {
      width: 100vw;
      height: calc(100vh - 60px);
      background-color: #E9F2FC;
    }
  }
}