@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.survey_list_view {
  &--td_survey_title {
    min-width: 250px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;

    span {
      font-size: 14px;
      color: #242424;
    }
  }

  &--td_survey_from_to {
    width: 120px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0;

    span {
      line-height: 18px;
      font-size: 14px;
      color: #242424;
    }
  }

  &--td_survey_type {
    width: 120px;

    span {
      line-height: 18px;
      font-size: 14px;
      color: #242424;
    }
  }

  &--td_total {
    min-width: 140px;
    white-space: nowrap;

    span {
      line-height: 18px;
      font-size: 14px;
      color: #242424;
    }
  }
}