@import '../../styles/normalize';
@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.sub {
  &--page_area {
    position: fixed;
    width: 100%;
    height: calc(100vh - 60px);
    top: 60px;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #e9f2fc;
  }

  &--content_area {
    position: absolute;
    width: 100%;
    top: 40px;
    padding-bottom: 170px;
    //background-color: #e9f2fc;
    &::-webkit-scrollbar {
      display: none;
    }

    @include sp() {
      padding-bottom: 256px;
    }
  }
}
