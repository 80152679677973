@import '../../../../../styles/normalize';
@import '../../../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../../../styles/mixin';
@import '../../../../../styles/global';

.pay_total--table {
  margin-top: 25px;
  thead {
    tr {
      height: 36px;
      th:not(:last-child):not(:first-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        background-color: #e0e0e0;
        height: 100%;
      }
      th {
        padding: 0;
        text-align: center;
        background-color: #a0aab8;
        font: normal normal bold 14px/20px Noto Sans CJK JP;
        color: #ffffff;
      }
      th:nth-child(1) {
        background-color: #6a6f7c;
        border-bottom: 0;
      }
    }
  }
  tbody {
    tr:nth-child(1) {
      //支給列
      td:nth-child(1) {
        background-color: #6a6f7c;
        padding: 0 10px;
        border-bottom: 1px solid #6a6f7c;
      }
    }

    tr {
      td:not([rowspan]):not([colspan]) {
        border-right: 1px solid #a0aab8;
        border-bottom: 1px solid #a0aab8;
        text-align: center;
      }
    }
  }

  &--column {
    &--payment {
      font: normal normal bold 14px/20px Noto Sans CJK JP;
      color: #ffffff;
      height: 72px;
    }
  }
}

.pay_total--total {
  width: 31%;
  border: 1px solid #a0aab8;
  &--top {
    font: normal normal bold 14px/20px Noto Sans CJK JP;
    color: #ffffff;
    background-color: #6a6f7c;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &--bottom {
    height: 73px;
    display: flex;
    align-items: center;
    justify-content: center;
    font: normal normal bold 24px/30px Noto Sans CJK JP;
  }
}
