@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.matter_list {
  &--date_column {
    flex-direction: column;
    gap: 0;
    align-items: flex-start;
    height: 76px;
    row-gap: 8px;
    .custom_input--label_ant_design {
      label {
        min-height: 24px;
      }
    }
  }
  &--bottom_area.bottom_btn_area {
    height: 96px;
    flex-flow: row;
    justify-content: center;
    min-height: 92px;
    display: flex;
    gap: 30px;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
    padding: 0 100px;
    & > div:nth-child(1) {
      flex: 1;
      display: flex;
      justify-content: center;
      .gw-btn {
        &:nth-child(1) {
          background-color: #0056d3;
          height: 44px;
          width: 240px;
          max-width: 240px;
        }
      }
    }
    .matter_list--submit_buttons {
      display: flex;
      text-align: center;
      position: absolute;
      right: 20px;
      .gw-btn {
        display: flex;
        background-color: #ffffff;
        width: 140px;
        height: 36px;
        color: #242424;
        line-height: 36px;
        justify-content: center;
        align-items: center;
        text-align: center;
        & > span {
          line-height: 36px !important;
          height: 36px !important;
        }
      }
    }
  }

  &--matter_no {
    height: 24px;
    line-height: 24px;
    text-align: right;
    display: inline-block;
    & > img {
      margin-right: 3px;
    }
  }
  &--status {
    border-radius: 22px;
    width: 112px;
    min-width: 112px;
    height: 32px;
    padding: 3px 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
}
.matter_base {
  .list_view--list_table td {
    line-height: unset;
  }
}

.matter_list_sp {
  &--bottom_area, &--multiple_bottom_area {
    position: fixed;
    width: 100%;
    height: 88px;
    padding: 24px 16px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    background-color: rgba(106, 111, 124, 0.6);
    bottom: 0;
    right: 0;
    z-index: 30;
  }
  &--multiple_bottom_area {
    height: 136px;
    gap: 8px;
  }
  &--sub_btn_area {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
  }
}

//@media screen and (-webkit-min-device-pixel-ratio:0) {
//  ::i-block-chrome, .matter_list_sp--bottom_area {
//    height: 130px;
//  }
//  ::i-block-chrome, .matter_list_sp--multiple_bottom_area {
//    height: 230px;
//  }
//}
//
//_::-webkit-full-page-media, _:future, :root .matter_list_sp--bottom_area {
//  height: 130px;
//}
//
//_::-webkit-full-page-media, _:future, :root .matter_list_sp--multiple_bottom_area {
//  height: 230px;
//}