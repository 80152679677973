@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.partner_register {
  &--mail_send_check {
    width: 220px;
    height: 36px;
    padding: 0 24px;
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 6px;
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    span {
      display: inline-block;
      font-size: 16px;
      line-height: 16px;
      height: 16px;
      color: #242424;
    }
  }

  &--submit_buttons {
    width: 520px;
    height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
  }
}

@media screen and (max-width: 800px) {
  .partner_register {
    &--partner_company_id_field {
      width: 100%;
      height: 18px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;
      gap: 8px;

      .custom_input--input_area {
        width: 100%;
        height: 18px;

        #partnerCompanyId {
          width: 100%;
          height: 18px;
          padding: 0;
        }
      }
    }
    &--submit_buttons {
      width: 100%;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 23px;
      padding-right: 16px;
      padding-left: 16px;
    }
  }
}
