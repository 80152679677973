@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.staff_edit {

  &--contents_area {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 24px;
    margin-top: 26px;
  }

  &--body_infos_area {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    margin-top: 26px;

    .custom_input--unit, .custom_input--select_box_unit {
      width: 23px;
      flex-shrink: 0;
    }
  }

  &--staff_id_area {
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
    @media screen and (max-width: 800px) {
      height: 18px;
      gap: 8px;
    }

    label {
      width: 119px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-shrink: 0;
      font-size: 14px;
      color: #646464;
      @media screen and (max-width: 800px) {
        width: auto;
        line-height: 18px;
        justify-content: flex-start;
      }

      span {
        color: #d80245;
      }
    }

    .staff_edit--staff_id {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 14px;
      color: #242424;
    }
  }

  &--unity_area {
    width: unquote('min(95%, 1052px)');
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;

    .custom_input--area_ant_design {
      .ant-picker {
        width: 165px;
      }
    }

    .staff_edit--input_unity {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;

      .ant-picker {
        width: 165px;
      }
    }
  }

  &--unity_area_daily_pay {
    width: 67%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;
    gap: 40px;
    padding-left: 176px;
  }

  &--access_unity_area {
    width: 91%;
    height: auto;
    border: 1px solid #A0AAB8;
    border-radius: 3px;
    opacity: 1;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    padding: 12px 0 16px;
    @media screen and (max-width: 800px) { 
      gap: 16px;
      width: 100%;
      padding: 10px;
    }

    .staff_edit--access_input_button_area {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      @media screen and (max-width: 800px) { 
        flex-direction: column;
      }
    }

    .staff_edit--access_input_area {
      width: 87%;
      height: auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      @media screen and (max-width: 800px) { 
        gap: 16px;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        .custom_input--input_area {
          flex-direction: column;
          height: auto;
        }
      }
    }
  }

  &--input_span_area {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

    span {
      font-size: 12px;
      color: #d80245;
    }
  }

  &--basic_area {
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 24px;

    .staff_edit--calendar_area {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      row-gap: 8px;

      .staff_edit--input_month {

        .ant-picker {
         width: 145px;
        }
      }
    }

    .staff_edit--input_notification_area {
      width: 100%;
      height: auto;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
    }

    .staff_edit--notification_area {
      width: 100%;
      height: 32px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 24px;

      label {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 119px;
        font-size: 14px;
        color: #646464;
      }

      button {
        width: 160px;
        height: 32px;
        background: #7B7B7B 0% 0% no-repeat padding-box;
        border-radius: 6px;
        opacity: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 16px;
          height: 16px;
          margin-top: 2px;
          margin-right: 4px;
        }

        span {
          font-size: 14px;
          color: #ffffff;
        }

        &:focus {
          background-color: #3ba1ff;
        }

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
}

.add_button--area {
  position: relative;
  text-align: center;
  width: auto;

  .staff_edit--add_button {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px solid #2b3245;
    padding: 10px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 36px;
      height: 36px;
    }
    @media screen and (min-width: 800px) {
      &:hover {
        background-color: #0056d3;
      }
    }
    @media screen and (max-width: 800px) {
      &:active {
        background-color: #0056d3;
        opacity: 0.5;
      }
    }
  }
}

.staff_edit--delete_button_area {
  position   : relative;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  @media screen and (max-width: 800px) { 
    align-self: flex-end;
    margin-right: 0px;
    width: 12px;
    height: 12px;
  }
}
.staff_edit--delete_button_area:hover .staff_edit--delete_button_text {
  display: inline;
}
.staff_edit--delete_button_text {
  position: absolute;
  display: none;
  padding: 4px 3px;
  border-radius: 3px;
  background-color: #242424CC;
  color: #ffffff;
  width: 58px;
  height: 30px;
  left : -18px;
  top: -38px;
  font-size: 14px;
  text-align: center;
}
.staff_edit--delete_button_text:after{
  position: absolute;
  bottom: -10px;
  right: 23px;
  border: solid transparent;
  border-top-color: #242424CC;
  border-width: 5px;
  pointer-events: none;
  content: " ";
}

.my_page {
  &--user_infos_area {
    width: 85%;
    height: auto;
    margin: 0 auto;
    padding-top: 40px;
  }

  &--container_notice {
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    color: #D80245;
    position: absolute;
    top: 8px;
  }

  &--input_area {
    width: 70%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
  }

  &--staff_id_area {
    width: 100%;
    height: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
  }

  &--staff_id_label {
    font-size: 14px;
    color: #646464;
    width: 272px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    span {
      color: #d80245;
    }

  }

  &--staff_id {
    font-size: 14px;
    color: #242424;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &--bottom_btn_area {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: rgba(106, 111, 124, 0.6);
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    z-index: 30;
  }
}