@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

@media screen and (max-width: 800px) {
  .shift_forPc {
    display: none;
    visibility: hidden;
  }
  .shift_forSp {
    display: unset;
    visibility: visible;
  }
}

@media screen and (min-width: 801px) {
  .shift_forPc {
    display: unset;
    visibility: visible;
  }
  .shift_forSp {
    display: none;
    visibility: hidden;
  }
}

.staff_plan_register {

  &--search_area {
    width: 100%;
    height: 148px;
    background-color: #FFFFFF;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
    padding: 40px 40px 64px;
    margin-top: 40px;
    margin-bottom: 16px;

    .staff_plan_register--input_area {
      width: auto;
      height: 44px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 24px;
    }
  }
}