@import '../../styles/normalize';
@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.forget_pass {
  &--background {
    position: relative;
    height: 100vh;
    width: 100%;
    background-color: #F3F6F9;
    text-align: center;

    .copyright--contents {
      width: 100%;
      left: 0;
    }
  }
}
@media screen and (max-width: 800px) {
.forget_pass--background {
.copyright--contents {
  position: absolute;
}
}
.login--background {
  .copyright--contents {
    position: absolute;
  }
  }
}

  