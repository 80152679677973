@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.naming_list {
  &--search_area {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
  }

  &--input_area {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
  }
}

.naming_list_page {
  #th_checkAll {
    //width: 200px;
  }
}

@media screen and (max-width: 800px) { 
  .naming_list {
    &--search_area {
      //aaa
    }
    &--input_area {
      flex-flow: column;

    }
  }
}