@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.quote_approval--stamp_buttons {
  display: flex;
  height: 100%;
  align-items: center;
  gap: 40px;
}


@media screen and (max-width: 800px) {
  .quote_approval--stamp_buttons {
    width: 100%;
    height: 88px;
    display: flex;
    align-items: center;
    gap: 23px;
  }
}