$red: #ff0000;
$dark-red: #990000;
$shallow-red: #d89f9f;
$cardinal: #990000;
$yellow: #eaab01;
$grey: #f3f3f3;
$dark-grey: #808080;
$shallow-grey: #f0f0f0;
$light-grey: #bdbdbd;
$silver: #7b7b7b;
$black: #000000;
$light-black: #242424;
$transparent-black: #2424241a;
$white: #ffffff;
$smoke-white: #f5f5f5;
$sky-blue: #e9f2fc;
$deep-blue: #0056d3;
$border-grey: #a0aab8;

$font-size-base: 1rem;
$font-size-xxxl: $font-size-base * 2;
$font-size-xxl: $font-size-base * 1.625;
$font-size-xl: $font-size-base * 1.5;
$font-size-lg: $font-size-base * 1.25;
$font-size-lm: $font-size-base * 1.175;
$font-size-sm: $font-size-base * 0.875;
$font-size-ssm: $font-size-base * 0.75;
$font-size-sssm: $font-size-base * 0.625;
$font-size-ssssm: $font-size-base * 0.5;

$font-family: 'Lato', 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', 'メイリオ',
  Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;

$font-color: #242424;

$text-align-map: (
  text-align-left: left,
  text-align-center: center,
  text-align-right: right,
);

$input-width: 280px;
$input-width-short: 80px;
$input-width-wide-select: 120px;
$input-width-wide: 160px;
$input-width-wide-area: 200px;
$input-width-wider: 240px;
$input-width-50: 50px;
$input-width-100: 100px;
$input-width-125: 125px;
$input-width-150: 150px;
$input-width-200: 200px;
$input-width-250: 250px;
$input-width-300: 300px;
$input-width-350: 350px;
$input-height: 32px;
$input-padding-left: 11px;
$input-placeholder-padding-left: 0px;
$input-label-width-short: 20px;
$input-label-width-wide-short: 40px;
$input-label-width: 80px;
$input-label-width-wider: 120px;
$input-label-width-half: 400px;
$input-label-width-wide: 500px;
$inout-label-font-size: $font-size-sm;
$input-wrapper-margin: 5px 0;
$input-block-margin: 5px 0;

$btn-width: 118px;
$btn-wider-width: 180px;
$btn-table-width: 45px;
$btn-height: 26px;
$btn-table-height: 30px;
$btn-margin-vertical: 20px;
$btn-margin-horizontal: 10px;
$btn-border-radius: 6px;
$btn-active-opacity: 0.5;
$btn-wider-border: 2px solid;
$btn-wider-margin: 0;

$modal-border-radius: 7px;

$upload-picture-card-width: 150px;
$upload-picture-card-height: 200px;
