@import '../../../../styles/normalize';
@import '../../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../../styles/mixin';
@import '../../../../styles/global';

.corporate_register {
  &--address_list {
    &_area, &_contents {
      width: 100%;
      height: fit-content;
    }

    &_area {
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: center;
      gap: 24px;
    }

    &_contents {
      border: 1px solid #A0AAB8;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-end;
      gap: 4px;
      padding: 24px 0;
      position: relative;
      @media screen and (max-width: 800px) {
        padding: 24px 9px;
      }
    }

    &_info {
      width: 100%;
      height: 100%;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 24px;
    }

    &_number {
      width: 100%;
      height: 44px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 7px;
      @media screen and (max-width: 800px) {
        height: fit-content;
        align-items: flex-end;
      }

      span {
        width: 7px;
        font-size: 14px;
        color: #242424;
        text-align: center;
        @media screen and (max-width: 800px) {
          line-height: 41px;
        }
      }
    }

    &_add_btn_area {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 24px;
    }
  }
}