@import '../../styles/normalize';
@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.copyright {
  &--contents {
    position: absolute;
    width: 100%;
    margin: 0;
    bottom: 6px;
    //left: 56px;
    line-height: 30px;
    font-size: 14px;
    color: #383F52;
    letter-spacing: 0;
    text-align: center;
  }
}

@media screen and (max-width: 800px) {
  .copyright {
    &--contents {
      position: sticky;
      width: 100vw;
      left: 0;
      font-size: 12px;
      z-index: 0;
      margin-top: 70px;
    }
  }
}