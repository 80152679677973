@import '../../styles/normalize';
@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.gw-checkbox {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.gw-checkbox-active::before {
  content: '\2713' !important;
  color: white;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}
.gw-checkbox-disabled {
  .gw-checkbox-label::before {
    border-color: #bcbcbc;
    border-radius: 3px;
  }
  color: #bcbcbc;
}
.gw-checkbox-label {
  cursor: pointer;
  user-select: none;
  &::before {
    content: '\a0';
    display: inline-block;
    margin-right: 6px;
    text-align: center;
    line-height: 10px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-weight: bold;
    border: 0.5px solid #2b3245;
    border-radius: 3px;

    @include sp() {
      width: 18px;
      height: 18px;
      line-height: 18px;
    }
  }
  &.gw-checkbox-active::before {
    background-color: #28d300;
    border-color: #28d300;
  }
}
.gw-checkbox-disabled {
  .gw-checkbox-label {
    cursor: not-allowed;
  }
}
.gw-cuscheck-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  opacity: 1;
}
