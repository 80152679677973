@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.recruit_matter_detail_modal {

  .ant-modal-content {
    border-radius: 6px;
  }

  &--title {
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font: normal normal bold 24px Noto Sans CJK JP;
    color: #242424;
    margin-bottom: 16px;
    margin-top: 40px;
  }
  &--content {
    width: 100%;
    height: 478px;
    overflow-x: scroll;
  }
  &--info {
    padding: 16px 0;
    margin: 0 40px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1px solid #f3f3f3;
    &:nth-child(9) {
      border-bottom: none;
    }
    &_label {
      width: 120px;
      height: 24px;
      font-size: 14px;
      color: #646464;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &_detail {
      width: calc(100% - 120px);
      font-size: 14px;
      color: #242424;
      white-space: pre-wrap;
      display: flex;
      flex-direction: column;
    }
  }

  &--button {
    margin: 0 40px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 60px;
    flex-shrink: 0;
    margin-top: 24px;
    .gw-btn {
      min-width: 208px;
    }
  }
  @media screen and(max-width:800px) {
    &--content {
      width: 100%;
      height: 350px;
      overflow-x: scroll;
    }

    &--info {
      flex-direction: column;
      margin: 0 24px;
      padding: 12px 0;
      gap: 4px;
    }
    &--info_label {
      font-size: 12px;
      height: 18px;
    }
    &--info_detail {
      font-size: 12px;
      width: 100%;
    }
    &--button {
      gap: 15px;
      margin: 25px 15px 0;
      .gw-btn {
        min-width: unset;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .recruit_matter_detail_modal {

    .ant-modal-content {
      height: 75%;
    }

    &--title {
      width: 100%;
      height: 29px;
      display: flex;
      justify-content: center;
      align-items: center;
      font: normal normal bold 18px Noto Sans CJK JP;
      color: #242424;
      margin: 16px auto 12px;
    }
  }
}
