@import '../../../../styles/normalize';
@import '../../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../../styles/mixin';
@import '../../../../styles/global';

.able_menu--check_boxes {
  display: flex;
  justify-content: center;
  gap: 120px;
  margin: 30px 0px;
  &--area {
    display: flex;
    flex-flow: column nowrap;
    gap: 20px;
    margin: 0 20px;
    @media screen and (max-width: 800px) {
      margin: 0 20px 20px;
    }
  }
  @media screen and (max-width: 800px) {
    display: block;
  }
}
.corporate_register--able_menu--staff_view {
  background-color: #e9f2fc;
  height: 44px;
  width: 204px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  @media screen and (max-width: 800px) {
    width: 100%;
/*     justify-content: flex-start;
    padding: 0 16px;
 */  }
}

.corporate_register--able_menu--survey_view {
  background-color: #e9f2fc;
  height: 44px;
  width: fit-content;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 32px;
  @media screen and (max-width: 800px) {
    width: 100%;
    /*     justify-content: flex-start;
        padding: 0 16px;
     */  }
}