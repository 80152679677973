@import '../../../../styles/normalize';
@import '../../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../../styles/mixin';
@import '../../../../styles/global';

.implementation_tab_content {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .tab_label,
  .custom_input--label,
  .custom_input--select_box_label,
  .custom_input--label_ant_design {
    text-align: right;
    min-width: 170px;
  }
  @media screen and (max-width: 800px) {
    margin-top: 0;
  }
}

.traffic_info {
  .custom_unit {
    margin-left: 195px;
    & > a {
      color: #d80245;
      text-decoration: underline;
      font-size: 14px;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
  .custom_input--select_box_input_with_icon {
    width: 75%;
  }
}

.payment_tab_content {
  overflow-x: scroll;
  &--item {
    display: flex;
    margin: 25px 0;
    gap: 7px;
    margin-right: 100px;
  }
  &--item_list {
    margin-top: 70px;
  }

  &--actions {
    .gw-btn {
      height: 44px;
      &:nth-child(1) {
        background: #d80245;
        width: 160px;
      }

      &:nth-child(2) {
        background: #0056d3;
        width: 240px;
      }
    }
  }
}
