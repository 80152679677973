@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.survey_result_modal {

  &--survey_info {
    font-size: 14px;
    color: #242424;
  }

  &--survey_info_select {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 24px;

    .custom_input--select_box_area {
      width: 262px;
      flex-shrink: 0;
      .custom_input--select_box_input_area {
        .custom_select--area {
          .ant-select {
            box-sizing: border-box;
            width: 100%;
            padding-right: 0;

            .ant-select-selector {
              width: 100%;
              height: 42px;
            }
          }
        }
      }
    }
  }
  &--wrap {
    width: 100%;
    padding: 40px;
  }

  &--title {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 22px;
    color: #242424;
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: solid 2px #F3F3F3;
    font-weight: bold;
  }

  &--btn {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }

  &--graph {
    width: 100%;
    height: 378px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 8px;

    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: #ffffff;
      border-radius: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #bcbcbc;
      border-radius: 5px;
    }
  }
}