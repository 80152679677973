@import '../../../../../styles/normalize';
@import '../../../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../../../styles/mixin';
@import '../../../../../styles/global';

@media screen and (max-width: 800px) {
  .pay_stamp {
    display: flex;
    flex-flow: column nowrap;
    gap: 40px;
    width: 100%;
    margin-top: 8px;
    &--staff_employee {
      position: relative;
    }
    &--each_area {
      display: flex;
      flex-flow: column nowrap;
      gap: 24px;
    }
  }
}
