@import '../../styles/normalize';
@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.graph_component {

  &--display_area {
    width: 100%;
    max-height: 378px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: #ffffff;
      border-radius: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #bcbcbc;
      border-radius: 5px;
    }
  }

  &--graph_text {
    width: 100%;
    font-size: 14px;
    color: #242424;
    word-break: break-word;
  }

  &--graph_unity {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
  }

  &--graph_area {
    width: 50%;
    height: fit-content;
  }

  &--label_area {
    width: 50%;
    height: fit-content;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
  }

  &--mark_with_label {
    width: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;

    span {
      line-height: 14px;
      color: #242424;
      font-size: 12px;
    }
  }

  &--mark {
    width: 14px;
    height: 14px;
    border-radius: 3px;
  }

  &--attribute_label {
    width: 100%;
    height: auto;
    text-align: left;
    padding-left: 20px;

    span {
      line-height: 14px;
      color: #242424;
      font-size: 12px;
    }
  }
}

.hogehoge {
  width: 500px;
  height: 500px;
  margin: 100px auto 100px;
  background-color: #d89f9f;
}