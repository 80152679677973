@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.pay_register {
  &--term {
    padding-top: 26px;
  }
  &--submit_buttons {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }
}

.pay_register--term {
  &--input {
    margin-bottom: 24px;
  }
}

.pay_term_register {

  &--slash {
    font-size: 14px;
    color: #242424;
  }
   &--select_unity {
     width: 100%;
     height: 44px;
     display: flex;
     justify-content: flex-start;
     align-items: center;
     gap: 7px;
   }
}

@media screen and (max-width: 800px) {
  .pay_register {

    &--term {

      .pay_term_register--select_unity {
        width: 100%;
        height: 66px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        gap: 8px;

        .pay_term_register--slash {
          width: 6px;
          line-height: 40px;
          font-size: 12px;
          color: #242424;
        }
      }
    }

    &--submit_buttons {
      width: 100%;
      height: 88px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 23px;
      padding: 0 16px;
    }
  }
}