@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.import_modal {
  &--title_area {
    width: 100%;
    height: 35px;
    padding-bottom: 8px;
    border-bottom: 2px solid #f3f3f3;
    display: flex;
    justify-content: space-between;
    align-items: end;

    p {
      margin: 0 0;
      height: 100%;
      width: unset;
      line-height: 35px;
      font-size: 24px;
      font-weight: bold;
      color: #242424;
      letter-spacing: 1px;
    }

    a {
      color: #7b7b7b;
      letter-spacing: 0;
      text-decoration: none;
      padding-bottom: 1px;
      border-bottom: 1px solid #7b7b7b;
    }
  }

  &--file_area {
    width: 100%;
    height: 76px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      margin: 0 0;
      line-height: 20px;
      color: #242424;
      text-align: right;
    }

    &_counter {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-flow: column;
      gap: 6px;

      span {
        font-size: 12px;
        color: #242424;
      }
    }
  }

  &--preview {
    &_area {
      width: calc(100% - 42px);
      height: 41vh;
      max-height: 400px;
      padding: 21px;
      background-color: #e9f2fc;
      display: flex;
      justify-content: center;
      position: relative;
      overflow: auto;
    }

    &_no_file {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      font-weight: bold;
      color: #BCBCBC;
      margin: 0 0;
      height: 100%;
    }

    &_table {
      width: 100%;
      max-height: 100%;
      height: fit-content;
      overflow: auto;
      position: relative;

      &_wrap {
        width: 100%;
        height: 100%;
        position: relative;
        overflow: auto;
      }

      &_head {
        th {
          height: 36px;
          line-height: 36px;
          min-width: 60px;
          text-align: center;
          padding: 0 12px;
          color: #FFFFFF;
          white-space: nowrap;
          user-select: none;
          background-color: #6A6F7C;
          position: sticky;
          top: 0;
        }
      }

      &_body {
        tr {
          border-bottom: solid 2px #F3F6F9;

          td {
            height: 40px;
            line-height: 40px;
            text-align: left;
            padding: 0 12px;
            color: #242424;
            white-space: nowrap;
            user-select: none;
            background-color: #FFFFFF;
          }
        }
      }
    }
  }


  &--file_selector {
    width: 240px;
    height: 44px;
    border-radius: 6px;
    background-color: #ffffff;
    margin: 0 0;
    user-select: none;
    border: 1px solid #2b3245;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    cursor: pointer;

    span {
      height: 14px;
      line-height: 14px;
      font-size: 14px;
      color: #2b3245;
    }

    //&:hover {
    //  border-color: #003B94;
    //
    //  span {
    //    color: #003B94;
    //  }
    //}
  }

  &--button_area {
    width: 100%;
    margin-top: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
  }
}
