@import '../../../../styles/normalize';
@import '../../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../../styles/mixin';
@import '../../../../styles/global';

.custom_list_view--matter_assign_state_td {
  width: 100%;
  min-width: 200px;
  line-height: 18px;
  padding: 16px 0;
}

.custom_list_view--matter_assign_state_status {
  height: 32px;
  border-radius: 22px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-size: 14px;
    color: #FFFFFF;
  }
}

.custom_list_view--assign_show_detail {
  width: 100%;
  height: 50px;
}

.custom_list_view td {
  line-height: 30px;
}

.assign_show_detail_elm {
  min-width: 200px;
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 14px;
}

.custom_list_view--position_elm {
  width: 100%;
  height: 70px;
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
}

.custom_list_view--list_wrapper::-webkit-scrollbar{
  display:none;
}