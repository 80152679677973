@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

@import '../csvImportModal/style';

.export_modal {
  &--title_area {
    @extend .import_modal--title_area;
  }

  &--check_box {
    &_area {
      width: calc(100% - 32px);
      padding: 16px 16px;
      background-color: #e9f2fc;
      max-height: 200px;
      margin-top: 20px;
      overflow-y: auto;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 20px 0;
      position: relative;
      &_category {
        flex: 100%;
        &_disabled {
          color: grey;
        }
      }
    }

    &_content {
      width: 33%;
      &_placeholder {
        .custom_input--label {
          display: none;
        }
      }
    }
    &--category_area {
      background-color: red !important;
    }
  }

  &--button_area {
    @extend .import_modal--button_area;
  }
}
