@import '../../../../styles/normalize';
@import '../../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../../styles/mixin';
@import '../../../../styles/global';

.cost_setting {
  &--detail {
    table {
      border-collapse: collapse;
      td,
      th {
        border: 1px solid #a0aab8;
      }
      width: 60%;
      margin: 10px 194px 35px;
      height: 104px;
      font-weight: bold;
      & > thead {
        background-color: #6a6f7c;
        color: white;
        height: 24px;
      }
      & > tbody {
        text-align: center;
      }
    }
  }
  .adjust-btn {
    background-color: #ffffff;
    color: #2b3245;
    width: auto;
    max-width: 320px;
    border: 1px solid #2b3245;
    display: flex;
    padding: 12px 15px;
    height: 44px;
    margin: 5px 0 0 194px;
    border: 1px solid #2b3245;
    &.gw-btn-disabled {
      background-color: #ffffff !important;
    }
  }
}

.matter_date {
  &--container {
    border: 1px solid #a0aab8;
    position: relative;
    width: 95%;
    max-width: 894px;
    display: flex;
    padding: 15px 80px;
    flex-wrap: wrap;
    margin-bottom: 15px;
    .custom_input--area_ant_design {
      &:nth-child(4) {
        gap: 0;
        label {
          width: auto;
          text-align: center;
          width: 20px;
          font-size: 25px;
          padding-right: 5px;
          margin: 0 5px;
        }
      }
      margin-right: 5px;
    }
  }
  @media screen and (max-width: 800px) {
    margin-top: 8px;
  }
}
.tab_custom_component {
  @media screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
    margin: 0 70px;
  }
  .tab_custom_label {
    width: 100px;
    display: inline-block;
    margin: 0 25px 0 70px;
    text-align: right;
    color: #646464;
    @media screen and (max-width: 800px) {
      margin: 0;
      text-align: left;
    }
  }
}
