@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.staff_pay_detail {

  &--area {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 48px;
  }

  &--search {
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 88px;

    &_input, &_input_short {
      width: 424px;
      height: 44px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 24px;
    }

    &_input_short {
      width: 200px;
    }
  }

  &--pay_detail {
    width: 90%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
    padding-bottom: 21%;
  }

  &--item_box {
    width: 319px;
    height: 156px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;

    &_title {
      width: 100%;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 2px 2px 0 0;
      font: normal normal bold 16px/20px Noto Sans CJK JP;
      color: #FFFFFF;
      background-color: #6A6F7C;
    }

    &_contents {
      width: 100%;
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #2B3245;
    }

    &_unity {
      width: 100%;
      height: 156px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  &--item_box_left {
    width: 25%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;

    .staff_pay_detail--item_box_title {
      border-radius: 3px 0 0 0;
      border-right: 1px solid #A0AAB8;
    }
    .staff_pay_detail--item_box_contents {
      border-radius: 0 0 0 3px;
      border-right: 1px solid #A0AAB8;
      border-left: 1px solid #A0AAB8;
      border-bottom: 1px solid #A0AAB8;
    }
  }

  &--item_box_center {
    width: 25%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;

    .staff_pay_detail--item_box_title {
      border-right: 1px solid #A0AAB8;
      border-radius: 0;
    }

    .staff_pay_detail--item_box_contents {
      border-right: 1px solid #ffffff;
      border-bottom: 1px solid #A0AAB8;

      .staff_pay_detail--item_box_center_item {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &--item_box_right {
    width: 25%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;

    .staff_pay_detail--item_box_title {
      border-radius: 0 3px 0 0;
    }
    .staff_pay_detail--item_box_contents {
      border-radius: 0 0 3px 0;
      border-right: 1px solid #A0AAB8;
      border-bottom: 1px solid #A0AAB8;
    }
  }

  &--solo_contents {
    border-radius: 0 0 3px 3px;
    border-right: 1px solid #A0AAB8;
    border-left: 1px solid #A0AAB8;
    border-bottom: 1px solid #A0AAB8;
  }

  &--no-data {
    width: 100%;
    font-size: 14px;
    color: #2B3245;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 31%;
  }

  &--pay_year_area {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 40px;
  }

  &--total_info {
    width: 39%;
    height: 156px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 0.5px solid #A0AAB8;
    border-radius: 0 0 3px 3px;
  }

  &--total_info_left, &--total_info_right {
    width: 50%;
    height: 156px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;

    &_title, &_title_right {
      width: 100%;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      font: normal normal bold 16px/20px Noto Sans CJK JP;
      color: #FFFFFF;
      background-color: #6A6F7C;
      border-radius: 3px 0 0 0;
      border-right: 1px solid #A0AAB8;
    }

    &_title_right {
      border-radius: 0 3px 0 0;
      border-right: none;
    }

    &_contents {
      width: 100%;
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      font: normal normal bold 16px/20px Noto Sans CJK JP;
      color: #2B3245;
      border-right: 1px solid #ffffff;
      border-left: 1px solid #A0AAB8;
      border-bottom: 1px solid #A0AAB8;

      &_item {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid #A0AAB8;
      }
    }
  }

  &--pay_year_list {
    width: 36%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &--pay_year_list_contents {
    width: 46%;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
  }

  &--pay_year_list_title {
    width: 100%;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font: normal normal bold 16px/20px Noto Sans CJK JP;
    color: #FFFFFF;
    background-color: #6A6F7C;
    border-radius: 3px 0 0 0;
    border-right: 1px solid #A0AAB8;
  }

  &--pay_year_list_items {
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #2B3245;
    border-left: 1px solid #A0AAB8;
    border-right: 1px solid #A0AAB8;
    border-bottom: 1px solid #F3F3F3;
  }

  &--copy_right {
    width: 100%;
    line-height: 30px;
    font-size: 14px;
    color: #383F52;
    letter-spacing: 3px;
    text-align: center;

    @media screen and (max-width: 800px) {
      width: 100%;
      line-height: 28px;
      color: #383F52;
      font: normal normal normal 12px Noto Sans CJK JP;
    }
  }
}

.staffPayDetail_forSp {
  &--wrap {
    position: relative;
    width: 100vw;
    padding: 40px 0 0;
  }

  &--base {
    position: relative;
    width: 92%;
    padding: 32px 16px;
    background-color: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0 0 8px #383F523D;
    margin: 24px auto 90px;
    z-index: 1;
    min-height: 70vh;
    height: fit-content;

    .ant-tabs-nav-list {
      width: 100%;
      justify-content: center;
      gap: 30%;

      .ant-tabs-tab + .ant-tabs-tab {
        margin: 0;
      }
    }
  }

  &--dataArea {
    width: 100%;
    margin-top: 32px;

    .totalAmount {
      span {
        display: block;
        width: 100%;
        height: 30px;
        background-color: #6A6F7C;
        color: #FFFFFF;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        line-height: 30px;
        border-radius: 3px 3px 0 0;
      }

      p {
        width: 100%;
        height: 70px;
        border: 1px solid #A0AAB8;
        border-top: none;
        border-radius: 0 0 3px 3px;
        font-size: 14px;
        font-weight: bold;
        color: #2B3245;
        text-align: center;
        line-height: 70px;
        margin: 0;
      }
    }

    .summary {
      margin-top: 24px;

      * {
        box-sizing: border-box;
      }

      h3 {
        display: block;
        width: 100%;
        height: 30px;
        background-color: #6A6F7C;
        color: #FFFFFF;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        line-height: 30px;
        border-radius: 3px 3px 0 0;
        margin: 0;
      }

      div {
        width: 100%;
        height: 234px;
        border: 1px solid #A0AAB8;
        border-top: none;
        border-radius: 0 0 3px 3px;

        article {
          width: 100%;
          height: 58px;
          padding: 0 24px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #A0AAB8;

          label {
            font-size: 12px;
            color: #6A6F7C;
          }

          span {
            font-size: 12px;
            color: #242424;
          }
        }
      }
    }

    .year_table {
      margin-top: 24px;
      border: 1px solid #A0AAB8;
      border-top: none;
      border-radius: 3px;

      .head {
        display: flex;
        width: 100%;

        span {
          display: inline-block;
          height: 30px;
          background-color: #6A6F7C;
          color: #FFFFFF;
          text-align: center;
          font-size: 12px;
          font-weight: bold;
          line-height: 30px;
        }
      }

      .body {
        width: 100%;
        height: 60px;
        display: flex;
        border-bottom: 1px solid #F3F3F3;

        .month,
        .amount {
          display: inline-block;
          height: 58px;
          font-size: 12px;
          color: #242424;
          text-align: center;
          line-height: 58px;
        }

        .month {
          width: 34%;
          border-right: 1px solid #A0AAB8;
        }

        .amount {
          width: 66%;
        }
      }
    }
  }
}