@import '../../../../styles/normalize';
@import '../../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../../styles/mixin';
@import '../../../../styles/global';

.quote_register--mail {
  @media screen and (max-width: 800px) { 
    &--table {
    overflow-x: scroll;
    table {
      th {
        min-width: 100px;
      }
      thead {
        th:nth-child(1) {
          min-width: 220px;
        }
      }
    }
    }
  }

  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
  margin-top: 26px;
  &--table {
    table {
      border-collapse: collapse;
      width: 100%;
      th {
        border-left: 1px solid #a0aab8;
        padding: 6px 0 6px 24px;
        text-align: left;
      }
      td {
        border: 1px solid #a0aab8;
      }
      thead {
        background-color: #6a6f7c;
        font-weight: bold;
        color: #ffffff;
        height: 36px;
        th:nth-child(1) {
          border-left: 1px solid #6a6f7c;
          width: 40%;
        }
        th:nth-child(3) {
          width: 26%;
        }
        th:last-child {
          border-right: 1px solid #6a6f7c;
          width: 20%;
        }
      }
      tbody {
        tr td:nth-child(1) {
          padding: 24px 24px 25px 35px;
        }
        tr td:nth-child(2) {
          padding: 0 0 0 24px;
        }
        tr td:nth-child(3) {
          padding: 0 0 0 24px;
        }
        tr td:last-child {
          padding: 0 20px;
        }
      }
    }
    &--td {
      display: flex;
      flex-flow: column nowrap;
    }
  }
  &--footer {
    border: 1px solid #a0aab8;
    border-radius: 3px;
    padding: 40px 40px 40px 35px;
    display: flex;
    flex-flow: column nowrap;
    gap: 24px;
  }

  &--files_nm {
    text-decoration: underline;
    color: #7b7b7b;
  }

  &--tag {
    color: #486083;
    border: 1px solid #e9f2fc;
    border-radius: 6px;
    background-color: #e9f2fc;
    padding: 3px 0px;
    margin: 0px 0px 0px 60px;
    width: 168px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    span:nth-child(2) {
      margin: 0 14px 0 4px;
    }
    &--overlay {
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 0.5px solid #2b3245;
      border-radius: 3px;
    }
  }

  &--tag_div {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}
@media screen and (max-width: 800px) { 
  .pay_register--submit_buttons {
    height: 128px;
    align-items: flex-end;
    padding: 0px;
  }
}
