@import '../../../../styles/normalize';
@import '../../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../../styles/mixin';
@import '../../../../styles/global';

.denial_modal {
  &--startest {
    font-size: 14px;
    width: 100%;
    overflow: auto;
    display: flex;
    flex-flow: column nowrap;
    padding: 0 64px;
  }
  &--header {
    width: 100%;
    height: 11%;
  }
  //ボタン
  &--btndiv {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding-top: 6vh;
  }
  &--message {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    gap: 24px;
    &--span {
      height: 24px;
      white-space: nowrap;
    }
    &--box {
      width: 93%;
      textarea {
        height: 23vh;
      }
    }
  }
}
