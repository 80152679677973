@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.preview_modal {
  display: flex;
  flex-flow: column nowrap;
  gap: 40px;
  height: calc(100% - 88px);
  width: calc(100% - 80px);
  margin: auto;
  &--btndiv {
    position: sticky;
    text-align: center;
    background: #ffffff;
    bottom: 0;
  }
  table {
    width: 100%;
    @media screen and (max-width: 800px) {
      overflow-x: scroll;
    }
    tbody {
      tr {
        border: 1px solid #f3f3f3;
        td {
          white-space: pre-wrap;
        }
        td:first-child {
          text-align: center;
          width: 100px;
        }
        td:last-child {
          padding: 22px 0;
        }
      }
      tr:last-child {
        td:first-child {
          display: flex;
          justify-content: center;
          padding: 20px 0;
        }
      }
    }
  }
}
