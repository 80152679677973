@import '../../styles/normalize';
@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.multi_select {

  &--area {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
  }

  &--select_label_area {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
  }

  &--label {
    width: auto;
    height: 24px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-shrink: 0;
    font-size: 14px;
    color: #646464;
    white-space: nowrap;
    margin-top: 10px;

    span {
      font-size: 12px;
      color: #D80245;
    }
  }

  &--select {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;

    .ant-select {
      width: 100%;
      height: 44px;
      padding-right: 16px;

      .ant-select-selector {
        width: calc(100% + 16px);
        height: 44px;
        border-radius: 6px;
        border: 0.5px solid #2B3245;

        .ant-select-selection-search {

          input {
            height: 44px;
          }
        }

        .ant-select-selection-item {
          width: 100%;
          padding-left: 14.5px;
          padding-top: 0;
          color: #242424;
          font-size: 14px;
        }

        .ant-select-selection-placeholder {
          padding-left: 7.5px;
          padding-top: 0;
          color: #BCBCBC;
          font-size: 14px;
          width: 100%;
        }
      }

      .ant-select-clear {
        right: 13px;
        img {
          position: absolute;
          top: -8.5px;
          right: -5px;
        }
      }
    }

    .ant-select-disabled {
      .ant-select-selector {

        .ant-select-selection-item {
          width: 20px;
          padding-left: 5px;
          padding-top: 6px;
          color: #BCBCBC;
          font-size: 14px;
        }

      }
    }
  }

  &--display_value_area {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }

  &--item {
    width: auto;
    height: 44px;
    background-color: #E9F2FC;
    border-radius: 22px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    padding: 9px 12px 11px 16px;
  }

  &--value {
    font-size: 14px;
    color: #242424;
  }
}