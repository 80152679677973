@import '../../styles/normalize';
@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.add_button--area {
  position: relative;
  text-align: center;
  width: auto;

  .screen_register_button {
    &:hover {
      background-color: #0056d3;
      cursor: pointer;
      border: none;
    }
  }

  .add_button {
    width: 44px;
    height: 44px;
    border-radius: 6px;
    border: 1px solid #2b3245;
    padding: 10px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;

    @media screen and (min-width: 800px) {
      &:hover {
        background-color: #0056d3;
        cursor: pointer;
      }
    }
    @media screen and (max-width: 800px) {
      &:active {
        background-color: #0056d3;
        opacity: 0.5;
      }
    }
  }
  .add_button--round {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: 1px solid #2b3245;
    padding: 10px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: #0056d3;
    }
    &:active {
      opacity: 0.5;
    }
  }

  button:disabled {
    border: 1px solid #bcbcbc;
    background-color: #bcbcbc;
    opacity: 0.5;

    &:hover {
      background-color: #bcbcbc;
    }
  }
}

.add_button--text {
  position: absolute;
  top: -35px;
  left: -14px;
  margin: 0 auto;
  width: 70px;
  display: none;
  padding: 2px 3px;
  border-radius: 3px;
  background: #242424cc;
  color: #ffffff;
  font-size: 14px;
}
.add_button--text:after {
  position: absolute;
  bottom: -10px;
  right: 29px;
  border: solid transparent;
  border-top-color: #242424cc;
  border-width: 5px;
  pointer-events: none;
  content: ' ';
}
.add_button:hover + .add_button--text {
  display: block;
}
.add_button--round:hover + .add_button--text {
  display: block;
}

@media screen and (max-width: 800px) {
  .add_button--area {
    position: relative;
    text-align: center;
    width: auto;

    .add_button {
      width: 40px;
      height: 40px;
      border-radius: 6px;
      border: 1px solid #2b3245;
      padding: 10px;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .add_button--round {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 1px solid #2b3245;
      padding: 10px;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: #ffffff;
      }
      &:active {
        opacity: 0.5;
      }
    }
  }

  .add_button--text {
    position: absolute;
    top: -35px;
    left: -14px;
    margin: 0 auto;
    width: 64px;
    display: none;
    padding: 2px 3px;
    border-radius: 3px;
    background: #242424cc;
    color: #ffffff;
    font-size: 12px;
  }
  .add_button--text:after {
    position: absolute;
    bottom: -10px;
    right: 30px;
    border: solid transparent;
    border-top-color: #242424cc;
    border-width: 5px;
    pointer-events: none;
    content: ' ';
  }
  .add_button:hover + .add_button--text {
    display: block;
  }
  .add_button--round:hover + .add_button--text {
    display: block;
  }
}
