@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.venue_register {

  &--nearest_station_area {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
  }

  &--post_code_area {
    width: calc(100% - 70px);
    height: 44px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }

  .register_base--basic_info_image_area {
    img {
      background-color: #f3f3f3;
    }
  }

  &--venue_id_field {
    align-items: flex-end;

    .custom_input--input_area {
      #venueId {
        padding: 20px 15px 0;
      }
    }
  }

  &--mail_send_check {
    width: 220px;
    height: 36px;
    padding: 0 24px;
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 6px;
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    span {
      display: inline-block;
      font-size: 16px;
      line-height: 16px;
      height: 16px;
      color: #242424;
    }
  }

  &--submit_buttons {
    width: 520px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }
}

@media screen and (max-width: 800px) {
  .venue_register {

    &--nearest_station_area {
      width: 100%;
      height: auto;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 16px;
    }

    &--submit_buttons {
      width: 100%;
      height: 44px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 23px;
      padding-right: 16px;
      padding-left: 16px;
    }

    &--post_code_area {
      width: 100%;
      height: 68px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      gap: 8px;
    }

    &--venue_id_field {
      width: 100%;
      height: 18px;
      display: flex;
      flex-direction: row !important;
      justify-content: flex-start;
      align-items: flex-end;
      gap: 8px;

      .custom_input--input_area {
        width: 100%;
        height: 18px;

        #venueId {
          width: 100%;
          height: 18px;
          padding: 0;
        }
      }
    }
  }
}
