@import '../../../../styles/normalize';
@import '../../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../../styles/mixin';
@import '../../../../styles/global';

.quote_template_register {
  &--main {
    border: 1px solid #a0aab8;
    border-radius: 3px;
    margin: 40px 71px 0 71px;
    padding: 40px 58px 40px 54px;
    font: normal normal bold 14px/20px Noto Sans CJK JP;

    @include sp() {
      margin: 40px auto 0;
      min-width: 1000px;
    }
  }
  &--template_type {
    display: flex;
    flex-flow: row nowrap;
    gap: 24px;
    &--lable {
      width: 175px;
      text-align: right;

      @include sp() {
        width: 100%;
        text-align: left;
        font-size: 12px;
        color: #646464;
      }

      span {
        color: #d80245;
      }
    }
  }
}
