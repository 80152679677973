@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.user_register {

  &--menu_area {
    width: calc(100% - 70px);
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
  }

  &--menu_title {
    width: 100%;
    height: 22px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    span {
      font-size: 14px;
    }
  }

  &--menu_box {
    width: calc(100% + 70px);
    height: 100%;
    border: 1px solid #A0AAB8;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    padding: 40px 0;
    margin-left: -70px;
  }

  &--mail_send_check {
    width: 220px;
    height: 36px;
    padding: 0 24px;
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 6px;
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    span {
      display: inline-block;
      font-size: 16px;
      line-height: 16px;
      height: 16px;
      color: #242424;
    }
  }

  &--submit_buttons {
    width: 520px;
    height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
  }
}

.user_register-able-menu-tree {
  display: flex;
  justify-content: space-evenly;
  margin-top: 24px;
  .ant-tree-switcher_open {
    display: none;
  }
  .ant-tree-checkbox-checked {
    .ant-tree-checkbox-inner {
      background-color: #28d300;
      border-color: #28d300;
    }
  }
}

@media screen and (max-width: 800px) {

  .user_register {

    &--user_id_field {
      width: 100%;
      height: 18px;
      display: flex;
      flex-direction: row !important;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;

      .custom_input--input_area {
        width: 100%;
        height: 18px;

        #userId {
          width: 100%;
          height: 18px;
          padding: 0;
        }
      }
    }
    &--menu_area {
      width: 100%;
    }

    &--menu_box {
      width: 100%;
      height: 100%;
      border: 1px solid #A0AAB8;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 40px 0;
      margin-left: 0;
    }
  }
.custom_input--area {
  flex-direction: column;
  justify-content: flex-end;
  gap: 20px;
  .custom_input--label{
    .custom_input--label_disabled {
      color: #646464;
    }
  }
}
.custom_input--check_box_area .check_box--label_text {
  width: 100%;
}
.custom_input--check_box_area .custom_input--label {
  width: auto;
}
.custom_input--check_box_area {
  width: 100%;
}
  .user_register {
    &--mail_send_check {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
  
    }
  
    &--submit_buttons {
      width: 100%;
      height: 44px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 23px;
      padding-right: 16px;
      padding-left: 16px;
    }
  }
  
}
