@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.report_modal {
  padding: 0 30px;
  height: 90vh;

  &--contents {
    height: calc(100% - 68px - 104px);
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    padding-bottom: 10px;
  }
  &_title {
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 30px;
  }
  .report_modal--item_satisfaction {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    gap: 15px 0;
    flex-direction: column;
    & > span:nth-child(1) {
      color: #646464;
    }
  }
  &--actions {
    display: flex;
    justify-content: space-between;
    margin: 40px 0 20px;
  }
}

@media screen and (max-width: 800px) {
  .report_modal_base {
    min-width: unset !important;
  }
  .report_modal {
    padding: 0;
    height: 80%;

    &--contents {
      height: 400px;
      overflow-x: scroll;
      width: 100%;
    }

    &--actions {
      align-items: center;
      display: flex;
      gap: 23px;
      justify-content: space-between;
      padding-left: 0px;
      padding-right: 0px;
      width: 100%;
      margin: 38px auto 16px;
    }

    &_title {
      font-size: 18px;
      height: 23px;
      margin-bottom: 24px;
    }

    .report_modal--item_satisfaction {
      margin-top: 24px;
      margin-bottom: 0;
      display: flex;
      gap: 0;
      flex-direction: column;
      & > span:nth-child(1) {
        color: #646464;
        font-size: 12px;
      }

      span {
        font-size: 12px;
      }
    }
  }
}

.custom_payment_tab_content--item_list {
  margin: 30px 0 20px;

  @media screen and (max-width: 800px) {
    margin: 0 0 20px;
  }
  .payment_item {
    table {
      td {
        padding: 0 10px;
      }
    }
    .ant-descriptions-view table {
      border-collapse: collapse;
      th,
      td {
        border: 1px solid #a0aab8;
      }
    }
    .ant-descriptions {
      width: 100%;
      min-width: 100%;
    }

    .ant-descriptions-row {
      .ant-descriptions-item-content {
        text-align: center;
        display: unset;
      }
      .ant-descriptions-item-container {
        justify-content: center;
        align-items: center;
      }
      &:nth-child(2n + 1) {
        background-color: #6a6f7c;
      }
      &:nth-child(2n) {
        .ant-descriptions-item-container {
          padding: 15px 10px;
        }
      }
      &:nth-child(4) {
        .ant-descriptions-item-content {
          font-weight: bold;
        }
      }
    }
    .ant-descriptions-item,
    .ant-descriptions-item-container,
    .payment_item--cost_container {
      width: 100%;
    }
    .ant-descriptions-item-label {
      color: white;
      font-size: 12px;
      font-weight: bold;
    }
    .ant-descriptions-row > th,
    .ant-descriptions-row > td {
      padding-bottom: 0;
    }
    .recruit_matter_detail--various_application_info_area {
      overflow-x: hidden !important;
      width: 100%;
      align-items: unset;
    }

    .payment_item--cost {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 8px;
    }
    .custom_input--input_area {
      input {
        width: 100% !important;
      }
    }
    .payment_item--cost_images {
      gap: 0;
      & > .image_cell_container > .image_cell_name {
        display: none;
      }
    }
  }
}
