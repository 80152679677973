@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.assign_detail_page {
  textarea {
    height: 132px;
    width: 100%;
    border-radius: 7px;
    padding: 10px 15px;
    &::placeholder {
      color: #bcbcbc;
      font-size: 14px;
    }
  }
  &--title {
    @include shadow-box();
    margin-top: 15px;
    display: flex;
    background: #ffffff;
    min-height: 88px;
  }
  &--title-left {
    display: flex;
    padding-left: 30px;
    flex-direction: column;
    justify-content: center;
    padding-right: 250px;
    border-right: 1px solid $grey;
    margin-right: 10px;
    span {
      &:nth-child(1) {
        font-size: 14px;
        color: #a0aab8;
      }
      &:nth-child(2) {
        font-weight: bold;
        font-size: 24px;
      }
    }
  }
  &--title-right {
    display: flex;
    align-items: center;
    & > div:not(.report_page--title-end) {
      display: flex;
      flex-direction: column;
      margin-right: 8px;
      width: auto;
      span {
        &:nth-child(1) {
          margin: 0 20px 10px 0;
        }
      }
    }

    @include sp() {
      span {
        font-size: 12px;
      }
    }
  }
  &--title-end {
    display: flex;
    align-items: center;
    height: 100%;
    & > div {
      display: flex;
      flex-direction: column;
      span {
        margin-right: 5px;
      }
    }
    background-color: #e9f2fc;
    padding: 15px;
  }

  @media screen and (max-width: 800px) {
    &--title,
    &--title-left,
    &--title-right,
    &--title-end {
      padding-right: 0;
      width: 100%;
      min-height: 150px;
      flex: 0;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      overflow-x: hidden;
    }
    &--title-left {
      min-height: 80px;
      margin-top: 15px;
      border-bottom: 1px solid $grey;
      padding-bottom: 10px;
      padding-left: 15px;
      span {
        &:nth-child(1) {
          font-size: 12px;
          color: #a0aab8;
        }
        &:nth-child(2) {
          font-weight: bold;
          font-size: 20px;
        }
      }
    }
    &--title-right {
      margin-top: 15px;
      gap: 10px;
      & > div:not(.report_page--title-end) {
        margin-left: 15px;
      }
    }
    &--title-end {
      padding-left: 15px;
      & > div:nth-child(1) {
        flex-direction: row;
        margin-bottom: 5px;
        & > span {
          margin-right: 0;
        }
      }
      .custom_input--select_box_area {
        width: calc(85vw - 45px) !important;
      }
    }
    .placeholder {
      display: none;
    }
    .tab_content {
      padding: 0;
    }
    .switch_input--switch {
      flex-direction: column;
      display: flex;
      .switch_input--label {
        text-align: left;
      }
    }
    .switch_input--input {
      margin-left: 0;
    }
    .assign_info {
      padding: 20px 0;
      & > div:nth-child(1) {
        .assign_info--label {
          min-width: 50px;
        }
      }
      &--checkbox_container {
        flex-direction: column;
        .assign_info--label {
          margin-bottom: 10px;
        }
      }
      &--checkbox_area {
        flex-direction: column;
        flex-wrap: nowrap;
        margin-left: 0;
      }
      &--checkbox_area_other {
        flex-direction: column;
        display: flex;
        align-items: flex-start !important;
        gap: 5px;
      }
    }
    .custom_input--text_area_field {
      width: 100%;
    }
  }
}
