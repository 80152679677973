@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.survey_register {
  &--segment_area {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;

    &--mass {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;

      .custom_input--radio_button {
        width: fit-content;
      }
    }
  }

  &--search_area_note {
    color: #D80245;
    font-size: 12px;
  }
}