@import '../../styles/normalize';
@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.custom_input {
  &--area_column_not_error {
    width: 100%;
    height: 76px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;

    .custom_input {
      &--label {
        width: 100%;
        height: 24px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-shrink: 0;

        label {
          line-height: 24px;
          font-size: 14px;
          color: #646464;

          span {
            font-size: 14px;
            color: #d80245;
          }
        }
      }

      &--input_area {
        width: 100%;
        height: 44px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;

        .custom_input--unit {
          width: auto;
          height: 24px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 14px;
          color: #242424;
          white-space: nowrap;
        }
      }
    }
  }
}

.custom_input—pick_up_select_box_area {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: start;
  gap: 24px;

  .custom_input—pick_up_select_box_label {
    width: auto;
    height: 14px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-shrink: 0;
    margin-top: 15.5px;

    label {
      font-size: 14px;
      color: #646464;

      span {
        color: #d80245;
      }
    }
  }

  .custom_input—pick_up_select_box_input_area {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

    .custom_input—pick_up_select_box_input_button_area {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .custom_input--select_box_add_button {
        -webkit-appearance: none;
        display: inline-block;
        padding: 0 10px;
        color: #fff;
        width: 44px;
        height: 44px;
        vertical-align: top;
        border: none;
        border-radius: 0 6px 6px 0;
        background-color: #7b7b7b;
      }

      .custom_input—pick_up_select_box_input_with_icon {
        width: 100%;
        height: 44px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: flex-end;

        .custom_input--icon_field {
          position: relative;

          .select_icon {
            position: absolute;
            right: 16px;
            bottom: 11.5px;
          }
        }
      }
    }

    .custom_input—pick_up_select_box_value_area {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: 8px;
      row-gap: 8px;

      .pick_item {
        width: auto;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #e9f2fc;
        font-size: 14px;
        color: #242424;
        padding: 10px 16px;
        border-radius: 22px;
        opacity: 1;
        gap: 12px;
      }
    }
  }
}

.custom_input--select_box_add_button_field {
  -webkit-appearance: none;
  display: inline-block;
  margin-right: 0;
  padding: 10px 15px;
  width: 100%;
  height: 44px;
  font-size: 14px;
  box-sizing: border-box;
  border-radius: 6px 0 0 6px;
  border: 0.5px solid #2b3245;
  border-right: none;

  &:focus {
    box-shadow: 0px 0px 8px #003b9429;
    border: 1px solid #003b94;
    border-radius: 6px 0 0 6px;
  }
}

.custom_input--check_box_area {
  width: auto;
  height: 21px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  gap: 8px;

  /* チェックボックス全体 */
  .custom_input--label {
    width: auto;
    height: 20px;
    display: block;
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    user-select: none;
  }

  /* デフォルトチェックボックス非表示 */
  .custom_input--label input {
    display: none;
  }

  /* チェックボックスのスタイル */
  .custom_input--label .check_mark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background: #ffffff;
    box-sizing: border-box;
    border: 0.5px solid #2b3245;
    border-radius: 3px;
    opacity: 1;

    @media screen and (max-width: 800px) {
      border: 0.5pt solid #2b3245;
    }

    &:disabled {
      background: #ccc;
      border: 0.5px solid #2b3245;
    }

    &:hover {
      border: 0.5px solid #28d300;
      border-radius: 3px;
    }
  }

  /* チェックマーク作成 */
  .custom_input--label .check_mark:after {
    content: '';
    position: absolute;
    display: none;
    left: 5.45px;
    top: 1.5px;
    width: 7px;
    height: 12px;
    border: 2px solid #ffffff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);

    @media screen and (max-width: 800px) {
      left: 6px;
    }
  }

  /* チェック時のスタイル */
  .custom_input--label input:checked + .check_mark {
    background-color: #28d300;
    border: 0.5px solid #28d300;
    @media screen and (max-width: 800px) {
      border: 0.5pt solid #28d300;
    }
  }

  .custom_input--label input:disabled + .check_mark {
    background-color: #bcbcbc;
    border: 0.5px solid #bcbcbc;
    opacity: 0.5;
  }

  /* チェック時 チェックマーク表示 */
  .custom_input--label input:checked + .check_mark:after {
    display: block;
  }

  span {
    width: auto;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #242424;
  }
}

.custom_input--select_with_select_area {
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;

  .custom_input {
    &--select_with_select_label {
      width: auto;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-shrink: 0;

      label {
        font-size: 14px;
        color: #646464;

        span {
          color: #d80245;
        }
      }
    }

    &--select_with_select_input_area {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;

      .custom_input--select_box_input_with_icon {
        width: 100%;
        display: flex;
        flex-flow: column;
        text-align: right;

        .custom_input--icon_field {
          position: relative;

          .select_icon {
            position: absolute;
            right: 16px;
            bottom: 11.5px;
          }
        }
      }

      .custom_input--select_with_select_iunit {
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
      }
    }
  }
}

.custom_input--password_column {
  width: 480px;
  text-align: right;

  .custom_input--password_area {
    width: 100%;
    height: 76px;
    display: grid;
    grid-template:
      'label error' 24px
      'input input' 44px
      / 40% 60%;
    row-gap: 8px;

    .custom_input {
      &--password_label {
        grid-area: label;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-shrink: 0;

        label {
          font-size: 14px;
          color: #646464;

          span {
            color: #d80245;
          }
        }
      }

      &--password_error {
        grid-area: error;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 12px;
        color: #d80245;
      }

      &--password_input_area {
        width: 100%;
        grid-area: input;
      }
    }
  }

  .custom_input--icon_field {
    position: relative;

    .icon {
      position: absolute;
      right: 16px;
      bottom: 9.5px;
    }
  }
}

.custom_input--password,
.custom_input--password_not_error {
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .custom_input--icon_field {
    position: relative;

    .icon {
      position: absolute;
      right: 16px;
      bottom: -12.5px;
    }
  }

  .custom_input--password_area {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;

    .custom_input {
      &--password_label {
        width: auto;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-shrink: 0;

        label {
          font-size: 14px;
          color: #646464;

          span {
            color: #d80245;
          }
        }
      }

      &--password_input_area {
        width: 100%;
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }
}

.custom_input--tell_area {
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;

  .custom_input {
    &--tell_label {
      width: auto;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-shrink: 0;

      label {
        font-size: 14px;
        color: #646464;

        span {
          color: #d80245;
        }
      }
    }

    &--tell_input_area {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 7px;

      span {
        font-size: 14px;
        color: #242424;
      }
    }
  }
}

.custom_input--text_area {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;

  .custom_input {
    &--label {
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      flex-shrink: 0;

      label {
        line-height: 14px;
        height: 14px;
        font-size: 14px;
        margin-top: 10px;
        color: #646464;

        span {
          color: #d80245;
        }
      }
    }

    &--input_area {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
}

.custom_input--text_area_field {
  box-sizing: border-box;
  width: 100%;
  height: 132px;
  padding: 10px 15px;
  border: 0.5px solid #2b3245;
  border-radius: 6px;

  &::placeholder {
    font-size: 14px;
    color: #bcbcbc;
  }

  &:focus {
    box-shadow: 0px 0px 8px #003b9429;
    border: 1px solid #003b94;
    opacity: 1;
  }

  //&:hover {
  //  box-shadow: 0px 0px 8px #003b9429;
  //  border: 1px solid #003b94;
  //  opacity: 1;
  //}
}

.custom_input--toggle_input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}

.custom_input--toggle_label {
  width: 64px;
  height: 32px;
  background: #fff;
  border: 0.5px solid #bcbcbc;
  position: relative;
  display: inline-block;
  border-radius: 40px;
  transition: 0.4s;
  box-sizing: border-box;
  background-color: #bcbcbc;
}

.custom_input--toggle_label:after {
  content: '';
  position: absolute;
  width: 31px;
  height: 31px;
  border-radius: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  background: #ffffff;
  transition: 0.4s;
  border: 0.5px solid #bcbcbc;
}

.custom_input--toggle_input:checked + .custom_input--toggle_label {
  border: 0.5px solid #63c455;
  background-color: #63c455;
}

.custom_input--toggle_input:checked + .custom_input--toggle_label:after {
  left: 32px;
  background: #ffffff;
  border: 0.5px solid #63c455;
}

.custom_input--toggle_input:checked:disabled + .custom_input--toggle_label {
  border: 0.5px solid #63c45560;
  background-color: #63c45560;
}

.custom_input--toggle_button {
  position: relative;
  width: 64px;
  height: 32px;
  margin: auto;
}

.custom_input--toggle_text_off {
  position: relative;
  left: 32px;
  top: 4px;
  color: #ffffff;
  font-size: 14px;
}

.custom_input--toggle_text_on {
  position: relative;
  left: 4px;
  top: 4px;
  color: #ffffff;
  font-size: 14px;
}

input[type='radio'] {
  display: none;
}

.custom_input--radio_button_text:before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: auto 8px auto 0;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  padding: 5px;
  background-clip: border-box;
  vertical-align: -0.25em;
}

.custom_input--radio_button_text_disable:before {
  content: '';
  display: inline-block;
  width: 20.5px;
  height: 20.5px;
  margin: auto 8px auto 0;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  padding: 5px;
  background-clip: border-box;
  vertical-align: -0.25em;
}

input[type='radio']:not(:checked) + .custom_input--radio_button_text:before {
  border: 0.5px solid #2b3245;
  background-color: #ffffff;
  @media screen and (max-width: 800px) {
    border: 0.5pt solid #2b3245;
  }
}

input[type='radio']:not(:checked)
  + .custom_input--radio_button_text:hover:before {
  border: 0.5px solid #63c455;
}

input[type='radio']:checked + .custom_input--radio_button_text:before {
  width: 4px;
  height: 4px;
  border: 5px solid #63c455;
  background-color: #ffffff;
}

input[type='radio']:not(:checked)
  + .custom_input--radio_button_text_disable:before {
  border: 0.5px solid #bcbcbc;
  background-color: #ffffff;
}

input[type='radio']:not(:checked)
  + .custom_input--radio_button_text_disable:hover:before {
  pointer-events: none;
}

input[type='radio']:checked + .custom_input--radio_button_text_disable:before {
  width: 4px;
  height: 4px;
  border: 5px solid #bcbcbc;
  background-color: #ffffff;
}

.custom_input {
  &--radio_button {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: auto;
    gap: 24px;
    flex-shrink: 0;
  }

  &--radio_title_area {
    width: auto;
    display: flex;
    height: 24px;
    justify-content: flex-end;
    align-items: center;
    flex-shrink: 0;

    p {
      font-size: 14px;
      color: #646464;
      margin: auto 0;
    }

    span {
      color: #d80245;
    }
  }

  &--radio_input_area {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 12px;
    column-gap: 32px;
  }

  &--radio_display_area {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .custom_input--radio_button_text {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: #242424;
      @media screen and (max-width: 800px) {
        font-size: 12px;
      }
    }

    .custom_input--radio_button_text_disable {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: #bcbcbc;
      @media screen and (max-width: 800px) {
        font-size: 12px;
      }
    }
  }
}

.custom_input--area {
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;

  .custom_input {
    &--label {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-shrink: 0;

      label {
        font-size: 14px;
        color: #646464;

        span {
          color: #d80245;
        }
      }
    }

    &--input_area {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
    }

    &--unit {
      width: auto;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      white-space: nowrap;
    }
  }
}

.custom_input--area_ant_design {
  height: 44px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;

  .custom_input {
    &--label_ant_design {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-shrink: 0;

      label {
        font-size: 14px;
        color: #646464;

        span {
          color: #d80245;
        }
      }
    }

    &--input_area_ant_design {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      position: relative;

      //.ant_design--delete_icon {
      //  height: 33px;
      //  width: 33px;
      //  position: absolute;
      //  right: 11px;
      //  top: 6px;
      //}

      .ant-picker {
        width: 153px;
        height: 44px;
      }

      input {
        &::placeholder {
          font-size: 14px;
          color: #bcbcbc;
        }
      }

      .custom_input--select_box_unit {
        font-size: 14px;
        color: #242424;
      }
    }
  }
}

.custom_input--input_with_input_area {
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;

  .custom_input {
    &--input_with_input_label {
      width: 120px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-shrink: 0;

      label {
        font-size: 14px;
        color: #646464;

        span {
          color: #d80245;
        }
      }
    }

    &--input_with_input_input_area_no_unit {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;

      .custom_input--input_with_input_with_unit {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &--input_with_input_input_area {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;

      .custom_input--input_with_input_with_unit {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;

        .custom_input--unit {
          width: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          font-size: 14px;
          color: #242424;
        }
      }
    }
  }
}

.custom_input--select_box_add_button_area {
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;

  .custom_input {
    &--label {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-shrink: 0;

      label {
        font-size: 14px;
        color: #646464;

        span {
          color: #d80245;
        }
      }
    }

    &--input_area {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .custom_input--select_box_input_with_icon {
        width: 100%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: flex-end;

        .custom_input--select_box_add_button_field {
          -webkit-appearance: none;
          display: inline-block;
          margin-right: 0;
          padding: 10px 15px;
          width: 100%;
          height: 44px;
          font-size: 14px;
          box-sizing: border-box;
          border-radius: 6px 0 0 6px;
          border: 0.5px solid #2b3245;
          border-right: none;

          &:focus {
            box-shadow: 0px 0px 8px #003b9429;
            border: 1px solid #003b94;
            border-radius: 6px 0 0 6px;
          }
        }

        .custom_input--icon_field {
          position: relative;

          .select_icon {
            position: absolute;
            right: 16px;
            bottom: 13px;
          }
        }
      }

      .custom_input--select_box_add_button {
        -webkit-appearance: none;
        display: inline-block;
        padding: 0 10px;
        color: #fff;
        width: 44px;
        height: 44px;
        vertical-align: top;
        border: none;
        border-radius: 0 6px 6px 0;
        background-color: #7b7b7b;
        position: relative;

        .custom_input--select_box_add_button_icon {
          position: absolute;
          right: 1px;
          bottom: 2px;
        }
      }
    }
  }
}

.custom_input--select_box_area_column {
  width: 100%;
  height: auto;
  display: grid;
  grid-template:
    'label label' 24px
    'input input' 44px
    / 50% 50%;
  row-gap: 8px;

  .custom_input {
    &--select_box_label {
      grid-area: label;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      label {
        font-size: 14px;
        color: #646464;

        span {
          color: #d80245;
        }
      }
    }

    &--select_box_input_area {
      width: 100%;
      grid-area: input;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .custom_input--select_box_input_with_icon {
        width: 100%;
        flex-flow: column;
        justify-content: center;
        align-items: flex-end;

        .custom_input--icon_field {
          position: relative;

          .select_icon {
            position: absolute;
            right: 16px;
            bottom: 2px;
          }
        }
      }
    }
  }
}

.custom_input--select_box_area {
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;

  .custom_input {
    &--select_box_label {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-shrink: 0;

      label {
        font-size: 14px;
        color: #646464;

        span {
          color: #d80245;
        }
      }
    }

    &--select_box_input_area {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
    }

    &--select_box_input_with_icon {
      width: 100%;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: flex-end;

      .custom_input--icon_field {
        position: relative;

        .select_icon {
          position: absolute;
          right: 16px;
          bottom: 13px;
        }
      }
    }

    &--select_box_unit {
      font-size: 14px;
      color: #242424;
    }
  }
}

.custom_input--input_with_check_box_area {
  width: 100%;

  .custom_input--area {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;

    .custom_input {
      &--label {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-shrink: 0;

        label {
          font-size: 14px;
          color: #646464;

          span {
            color: #d80245;
          }
        }
      }

      &--input_area {
        width: 100%;
      }
    }
  }
}

.custom_input--input_with_check_box_area {
  width: 480px;

  .custom_input--area_column {
    width: 100%;

    .custom_input--error {
      font-size: 12px;
      color: #d80245;
    }
  }

  .custom_input--input_with_checkbox {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 18px;
    margin-bottom: 2px;

    .custom_input--input_with_checkbox_field {
      width: 20px;
      height: 20px;
      border: 0.5px solid #2b3245;
      border-radius: 3px;
      opacity: 1;
      margin-right: 8px;
    }

    label {
      font-size: 14px;
      color: #242424;

      span {
        color: #d80245;
      }
    }
  }
}

.custom_input--area_column {
  width: 480px;
  display: grid;
  grid-template:
    'label error' 24px
    'input input' 44px
    / 50% 50%;
  row-gap: 8px;

  .custom_input {
    &--label {
      grid-area: label;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-shrink: 0;

      label {
        font-size: 14px;
        color: #646464;
      }
    }

    &--error {
      grid-area: error;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 12px;
      color: #d80245;
    }

    &--input_area {
      width: 100%;
      grid-area: input;
    }
  }
}

.custom_input {
  &--select_box_input_field {
    appearance: none;
    width: 100%;
    height: 44px;
    padding: 10px 45px 10px 15px;
    border: 0.5px solid #2b3245;
    border-radius: 6px;
    opacity: 1;

    &::placeholder {
      font-size: 14px;
      color: #bcbcbc;
    }

    &:focus {
      box-shadow: 0px 0px 8px #003b9429;
      border: 1px solid #003b94;
      opacity: 1;
    }

    //&:hover {
    //  box-shadow: 0px 0px 8px #003b9429;
    //  border: 1px solid #003b94;
    //  opacity: 1;
    //}

    &:disabled {
      border: 0.5px solid #bcbcbc;
      border-radius: 6px;
      opacity: 1;
      background-color: #ffffff;
      color: #bcbcbc;

      //&:hover {
      //  box-shadow: 0px 0px 0px 0px;
      //}
    }
  }

  &--input_field {
    width: 100%;
    height: 44px;
    padding: 10px 15px;
    border: 0.5px solid #2b3245;
    border-radius: 6px;
    opacity: 1;

    &::placeholder {
      font-size: 14px;
      color: #bcbcbc;
    }

    &:focus {
      box-shadow: 0px 0px 8px #003b9429;
      border: 1px solid #003b94;
      opacity: 1;
    }

    //&:hover {
    //  box-shadow: 0px 0px 8px #003b9429;
    //  border: 1px solid #003b94;
    //  opacity: 1;
    //}

    &:disabled {
      border: 0.5px solid #bcbcbc;
      border-radius: 6px;
      opacity: 1;
      background-color: #ffffff;
      color: #bcbcbc;

      @media screen and (max-width: 800px) {
        border: 0.5pt solid #bcbcbc;
      }

      //&:hover {
      //  box-shadow: 0px 0px 0px 0px;
      //}
    }

    @media screen and (max-width: 800px) {
      border: 0.5pt solid #2b3245;
    }
  }

  &--input_field_error {
    width: 100%;
    height: 44px;
    padding: 10px 15px;
    box-shadow: 0px 0px 8px #d8024529;
    border: 1px solid #d80245;
    border-radius: 6px;
    opacity: 1;

    &::placeholder {
      font-size: 14px;
      color: #bcbcbc;
    }

    &:focus {
      box-shadow: 0px 0px 8px #003b9429;
      border: 1px solid #003b94;
      opacity: 1;
    }
  }

  &--no_display_filed {
    border: none;
    background-color: rgba(0, 0, 0, 0);
    pointer-events: none;
    padding: 10px 15px 10px 0;
  }
  &--suggest_input_area {
    z-index: 4;
    width: 99%;
    height: 44px;
    input {
      width: 100%;
      height: 42px;
      padding: 10px 15px;
      border-radius: 6px;
    }
    div {
      div {
        width: 100%;
        height: 42px;
        border-radius: 6px;
        pointer-events: none;
      }
      ul {
        padding: 0;
        width: 100%;
        background-color: #fff;
        border-radius: 6px;
        border: 0.5px solid #2b3245;
      }
      li {
        height: 25px;
        padding: 0px 15px;
        list-style-type: none;
      }
    }
    &::placeholder {
      font-size: 14px;
      color: #bcbcbc;
    }

    &:focus {
      box-shadow: 0px 0px 8px #003b9429;
      border: 1px solid #003b94;
      opacity: 1;
    }

    //&:hover {
    //  box-shadow: 0px 0px 8px #003b9429;
    //  border: 1px solid #003b94;
    //  opacity: 1;
    //}

    &:disabled {
      border: 0.5px solid #bcbcbc;
      border-radius: 6px;
      opacity: 1;
      background-color: #ffffff;
      color: #bcbcbc;

      //&:hover {
      //  box-shadow: 0px 0px 0px 0px;
      //}
    }
  }
}

@media screen and (max-width: 800px) {
  .custom_input {
    &--suggest_input_area {
      z-index: 4;
      width: 99%;
      height: 40px;
      input {
        width: 100%;
        height: 40px;
        padding: 10px 15px;
        border-radius: 6px;
        font-size: 12px;
      }
      div {
        div {
          width: 100%;
          height: 40px;
          border-radius: 6px;
          pointer-events: none;
          font-size: 12px;
        }
        ul {
          padding: 0;
          width: 100%;
          background-color: #fff;
          border-radius: 6px;
          border: 0.5px solid #2b3245;
        }
        li {
          height: 25px;
          padding: 0px 15px;
          list-style-type: none;
        }
      }
      &::placeholder {
        font-size: 12px;
        color: #bcbcbc;
      }

      &:focus {
        box-shadow: 0px 0px 8px #003b9429;
        border: 1px solid #003b94;
        opacity: 1;
      }

      //&:hover {
      //  box-shadow: 0px 0px 8px #003b9429;
      //  border: 1px solid #003b94;
      //  opacity: 1;
      //}

      &:disabled {
        border: 0.5px solid #bcbcbc;
        border-radius: 6px;
        opacity: 1;
        background-color: #ffffff;
        color: #bcbcbc;

        //&:hover {
        //  box-shadow: 0px 0px 0px 0px;
        //}
      }
    }
    &--radio_button {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      height: auto;
      gap: 8px;
    }

    &--radio_title_area {
      width: auto;
      display: flex;
      height: 20px;
      justify-content: flex-start;
      align-items: center;
      flex-shrink: 0;

      p {
        font-size: 12px;
        color: #646464;
        margin: auto 0;
      }

      span {
        color: #d80245;
      }
    }

    &--radio_input_area {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 0;
      row-gap: 12px;
      column-gap: 32px;
    }

    &--radio_display_area {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .custom_input--radio_button_text {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: #242424;
      }
    }
  }

  .custom_input--select_box_add_button_field {
    -webkit-appearance: none;
    display: inline-block;
    margin-right: 0;
    padding: 10px 15px;
    width: 100%;
    height: 40px;
    font-size: 12px;
    box-sizing: border-box;
    border-radius: 6px 0 0 6px;
    border: 0.5px solid #2b3245;
    border-right: none;

    &:focus {
      box-shadow: 0px 0px 8px #003b9429;
      border: 1px solid #003b94;
      border-radius: 6px 0 0 6px;
    }
  }

  .custom_input—pick_up_select_box_area {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;

    .custom_input—pick_up_select_box_label {
      width: auto;
      height: 12px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-shrink: 0;
      margin-top: 0px;

      label {
        font-size: 12px;
        color: #646464;

        span {
          color: #d80245;
        }
      }
    }

    .custom_input—pick_up_select_box_input_area {
      width: 100%;
      height: auto;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;

      .custom_input—pick_up_select_box_input_button_area {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .custom_input--select_box_add_button {
          -webkit-appearance: none;
          display: inline-block;
          padding: 0 10px;
          color: #fff;
          width: 40px;
          height: 40px;
          vertical-align: top;
          border: none;
          border-radius: 0 6px 6px 0;
          background-color: #7b7b7b;
        }

        .custom_input—pick_up_select_box_input_with_icon {
          width: 100%;
          height: 44px;
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: flex-end;

          .custom_input--icon_field {
            position: relative;

            .select_icon {
              position: absolute;
              right: 16px;
              bottom: 11.5px;
            }
          }
        }
      }

      .custom_input—pick_up_select_box_value_area {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 8px;
        row-gap: 8px;

        .pick_item {
          width: auto;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #e9f2fc;
          font-size: 12px;
          color: #242424;
          padding: 10px 16px;
          border-radius: 22px;
          opacity: 1;
          gap: 12px;
        }
      }
    }
  }

  .custom_input--select_box_area_column {
    width: 100%;
    height: auto;
    display: grid;
    grid-template:
      'label label' 20px
      'input input' 40px
      / 50% 50%;
    row-gap: 8px;

    .custom_input {
      &--select_box_label {
        grid-area: label;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        label {
          font-size: 12px;
          color: #646464;

          span {
            color: #d80245;
          }
        }
      }

      &--select_box_input_area {
        width: 100%;
        grid-area: input;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .custom_input--select_box_input_with_icon {
          width: 100%;
          flex-flow: column;
          justify-content: center;
          align-items: flex-end;

          .custom_input--icon_field {
            position: relative;

            .select_icon {
              position: absolute;
              right: 16px;
              bottom: 1px;
            }
          }
        }
      }
    }
  }

  .custom_input--check_box_area {
    span {
      font-size: 12px;
    }
  }

  .custom_input--select_with_select_area {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

    .custom_input {
      &--select_with_select_label {
        width: 100%;
        height: 12px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-shrink: 0;

        label {
          font-size: 12px;
        }
      }

      &--select_with_select_input_area {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;

        .custom_input--select_box_input_with_icon {
          width: 100%;
          display: flex;
          flex-flow: column;
          text-align: right;

          .custom_input--icon_field {
            position: relative;

            .select_icon {
              position: absolute;
              right: 16px;
              bottom: 10px;
            }
          }
        }

        .custom_input--select_with_select_iunit {
          width: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
        }
      }
    }
  }

  .custom_input--password_column {
    width: 100%;
    text-align: right;

    .custom_input--password_area {
      width: 100%;
      height: auto;
      display: grid;
      grid-template:
        'label error' 20px
        'input input' 40px
        / 50% 50%;
      row-gap: 8px;

      .custom_input--icon_field {
        position: relative;

        .icon {
          position: absolute;
          right: 16px;
          bottom: 7.5px;
        }
      }

      .custom_input {
        &--password_label {
          grid-area: label;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-shrink: 0;

          label {
            font-size: 12px;
            color: #646464;
          }
        }

        &--password_error {
          grid-area: error;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          font-size: 10px;
          color: #d80245;
        }

        &--password_input_area {
          width: 100%;
          grid-area: input;
        }
      }
    }

    .custom_input--icon_field {
      position: relative;

      .icon {
        position: absolute;
        right: auto;
        bottom: 7.5px;
        margin-left: -40px;
      }
    }
  }

  .custom_input--password {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .custom_input--icon_field {
      position: relative;

      .icon {
        position: absolute;
        right: 16px;
        bottom: -25.5px;
      }
    }

    .custom_input--password_area {
      width: 100%;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;

      .custom_input {
        &--password_label {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-shrink: 0;

          label {
            font-size: 12px;
            color: #646464;

            span {
              color: #d80245;
            }
          }
        }

        &--password_input_area {
          width: 100%;
          display: flex;
          flex-flow: column;
          justify-content: flex-end;
          align-items: center;
        }
      }
    }
  }

  .custom_input--tell_area {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

    .custom_input {
      &--tell_label {
        width: 100%;
        height: 12px;
        display: flex;
        justify-content: start;
        align-items: center;
        flex-shrink: 0;

        label {
          font-size: 12px;
          color: #646464;

          span {
            color: #d80245;
          }
        }
      }

      &--tell_input_area {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;

        span {
          font-size: 12px;
        }
      }
    }
  }

  .custom_input--text_area {
    width: 100%;
    height: auto;
    display: grid;
    grid-template:
      'label error' 20px
      'input input' auto
      / 50% 50%;
    gap: 0;
    row-gap: 8px;

    .custom_input {
      &--label {
        grid-area: label;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-shrink: 0;

        label {
          line-height: 12px;
          height: 12px;
          font-size: 12px;
          margin-top: 0;
        }
      }

      &--input_area {
        grid-area: input;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: calc(100% + 1px);
      }
    }
  }

  .custom_input--text_area_field {
    height: 80px;

    &::placeholder {
      font-size: 12px;
    }
  }

  .custom_input--toggle_input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
  }

  .custom_input--toggle_label {
    width: 48px;
    height: 24px;
    background: #fff;
    border: 0.5px solid #bcbcbc;
    position: relative;
    display: inline-block;
    border-radius: 40px;
    transition: 0.4s;
    box-sizing: border-box;
    background-color: #bcbcbc;
  }

  .custom_input--toggle_label:after {
    content: '';
    position: absolute;
    width: 23px;
    height: 23px;
    border-radius: 100%;
    left: 0;
    top: 0;
    z-index: 2;
    background: #ffffff;
    transition: 0.4s;
    border: 0.5px solid #bcbcbc;
  }

  .custom_input--toggle_input:checked + .custom_input--toggle_label {
    border: 0.5px solid #63c455;
    background-color: #63c455;
  }

  .custom_input--toggle_input:checked + .custom_input--toggle_label:after {
    left: 24px;
    background: #ffffff;
    border: 0.5px solid #63c455;
  }

  .custom_input--toggle_button {
    position: relative;
    width: 48px;
    height: 24px;
    margin: auto;
  }

  .custom_input--toggle_text_off {
    position: relative;
    left: 23px;
    top: -1px;
    color: #ffffff;
    font-size: 12px;
  }

  .custom_input--toggle_text_on {
    position: relative;
    left: 4px;
    top: -1px;
    color: #ffffff;
    font-size: 12px;
  }

  .custom_input--area_ant_design {
    width: 100%;
    height: 62px;
    display: grid;
    grid-template:
      'label error' 14px
      'input input' 40px
      / 50% 50%;
    gap: 0;
    row-gap: 8px;

    .custom_input {
      &--label_ant_design {
        grid-area: label;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-shrink: 0;

        label {
          font-size: 14px;
          color: #646464;

          span {
            color: #d80245;
          }
        }
      }

      &--input_area_ant_design {
        width: 100%;
        height: 41px;
        grid-area: input;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;

        .ant-picker {
          width: 165px;
          height: 40px;
        }

        input {
          width: 100%;
          &::placeholder {
            font-size: 14px;
            color: #bcbcbc;
          }
        }

        .custom_input--select_box_unit {
          width: auto;
          line-height: 18px;
          white-space: nowrap;
          font-size: 12px;
          color: #242424;
        }
      }
    }
  }

  .custom_input--input_with_input_area {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0;
    row-gap: 8px;

    .custom_input {
      &--input_with_input_label {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        label {
          font-size: 12px;
          color: #646464;

          span {
            color: #d80245;
          }
        }
      }

      &--input_with_input_input_area_no_unit {
        width: 100%;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        gap: 0;
        row-gap: 8px;

        .custom_input--input_with_input_with_unit {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 8px;
        }
      }

      &--input_with_input_input_area {
        grid-area: input;
        width: 100%;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        gap: 0;
        row-gap: 8px;

        .custom_input--input_with_input_with_unit {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 8px;

          .custom_input--unit {
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            font-size: 12px;
            color: #242424;
          }
        }
      }
    }
  }

  //.custom_input--select_box_area {
  //  width: 100%;
  //  height: 62px;
  //  display: grid;
  //  grid-template:
  //    'label error' 14px
  //    'input input' 40px
  //    / 50% 50%;
  //  gap: 0;
  //  row-gap: 8px;
  //
  //  .custom_input {
  //    &--select_box_label {
  //      grid-area: label;
  //      display: flex;
  //      justify-content: flex-start;
  //      align-items: center;
  //      flex-shrink: 0;
  //
  //      label {
  //        font-size: 14px;
  //        color: #646464;
  //
  //        span {
  //          color: #d80245;
  //        }
  //      }
  //    }
  //
  //    &--select_box_input_area {
  //      grid-area: input;
  //      width: 100%;
  //      display: flex;
  //      justify-content: flex-start;
  //      align-items: center;
  //      gap: 8px;
  //    }
  //
  //    &--select_box_input_with_icon {
  //      width: 100%;
  //      display: flex;
  //      flex-flow: column;
  //      justify-content: center;
  //      align-items: flex-end;
  //
  //      .custom_input--icon_field {
  //        position: relative;
  //
  //        .select_icon {
  //          position: absolute;
  //          right: 16px;
  //          bottom: 13px;
  //        }
  //      }
  //    }
  //
  //    &--select_box_unit {
  //      font-size: 14px;
  //      color: #242424;
  //    }
  //  }
  //}

  .custom_input--area_column {
    width: 100%;
    height: 68px;
    display: grid;
    grid-template:
      'label error' 20px
      'input input' 40px
      / 50% 50%;
    row-gap: 8px;

    .custom_input {
      &--label {
        label {
          font-size: 12px;
        }
      }

      &--error {
        font-size: 10px;
      }

      &--input_area {
        width: 100%;
      }
    }
  }

  .custom_input {
    &--input_field,
    &--select_box_input_field {
      height: 40px;

      &::placeholder {
        font-size: 12px;
      }
    }

    &--input_field_error {
      height: 40px;

      &::placeholder {
        font-size: 12px;
        color: #bcbcbc;
      }
    }
  }

  .custom_input--input_with_check_box_area {
    width: 100%;

    .custom_input--area_column {
      .custom_input--error {
        font-size: 10px;
      }
    }

    .custom_input--input_with_checkbox {
      margin-top: 16px;
      margin-bottom: 0;

      label {
        font-size: 12px;
        color: #242424;

        span {
          color: #d80245;
        }
      }
    }
  }
}

.display_select_box_button {
  position: relative;

  &_icon {
    position: absolute;
    right: 1px;
    bottom: 2px;
  }
}

.custom_input--text_area_field_disabled {
  color: #bcbcbc;
  background: #ffffff;
  border: 0.5px solid #bcbcbc;
}
.custom_input--text_area_field_error {
  box-shadow: 0px 0px 8px #d8024529;
  border: 1px solid #d80245;
  border-radius: 6px;
  opacity: 1;
}

.ant-select-auto-complete {
  width: 100%;
  .ant-select-selector {
    padding-left: 17px !important;
    border-radius: 6px !important;
    border: 0.5px solid #2b3245 !important;
    height: 44px !important;
    line-height: 44px;
    align-items: center;
    & {
      input {
        padding-left: 5px !important;
      }
    }
  }
}

::-webkit-full-page-media, :future, :root .custom_input--password_column {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-end;
}

::-webkit-full-page-media, :future, :root .custom_input--password_column .custom_input--icon_field .input_password--i_icon {
  position: absolute;
  right: 15px;
  bottom: 9.5px;
}
