@import '../../styles/normalize';
@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.side_menu {
  &--base {
    width: 56px;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
    background-color: #0f2341;
    transition: all 0.2s;
    overflow: scroll;
    flex-shrink: 0;
    z-index: 500;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media screen and (max-width: 800px) {
    &--base {
      display: none;
    }
  }

  &--wrap {
    position: relative;
    height: 56px;
    width: calc(100% - 15px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 15px;
    transition: all 0.2s;
    box-sizing: content-box;
  }

  &--main_menu {
    position: relative;
    list-style: none;
    width: 100%;
    margin: 0 0;
    padding: 0 0;
    overflow: hidden;
    flex-shrink: 0;

    a {
      text-decoration: none;
    }

    li {
      position: relative;
      height: 0px;
      opacity: 0;
      transition: all 0.2s;
    }

    &:hover > .side_menu--hover_text {
      display: block;
    }
  }

  &--hover_text {
    display: none;
    min-width: 100px;
    padding: 0 10px;
    height: 26px;
    text-align: center;
    background-color: #242424cc;
    position: fixed;
    left: 66px;
    z-index: 9000;
    border-radius: 6px;

    p {
      line-height: 26px;
      font-size: 14px;
      margin: 0 0;
      color: #ffffff;
    }
  }

  &--link_wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 56px;
    gap: 10px;
    padding-left: 13px;
    cursor: pointer;

    img {
      width: 32px;
      flex-shrink: 0;
      left: 13px;
      opacity: .6;
    }

    p {
      margin: 0 0;
      color: #ffffff;
      height: 14px;
      line-height: 14px;
      font-size: 14px;
      flex-shrink: 0;
      user-select: none;
    }

    &:hover {
      background-color: rgba(0, 86, 211, 0.5);
    }

    &:hover > img {
      opacity: 1;
    }

    &_active {
      @extend .side_menu--link_wrap;
      background-color: rgba(0, 86, 211, 1);

      img {
        opacity: 1;
      }
    }

    &:not(#各種マスタ):after,
    &_active:not(#各種マスタ):after {
      content: '';
      border-bottom: #7b7b7b solid 0.5px;
      width: 36px;
      position: absolute;
      bottom: 0;
      left: 10px;
      transition: all 0.2s;
    }

    &_li {
      @extend .side_menu--link_wrap;
      height: 44px;

      &_active {
        @extend .side_menu--link_wrap_li;
        background-color: rgba(0, 86, 211, 1);
      }
    }
  }

  &--privacy_policy {
    height: 16px;
    line-height: 16px;
    font-size: 14px;
    color: #ffffff;
    border-bottom: 1px solid #ffffff;
    margin: 0 0;
    text-decoration: none;
    white-space: nowrap;

    &_wrap {
      position: relative;
      width: 100%;
      padding-left: 18px;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: flex-start;
      margin-top: 3.5vh;
      padding-bottom: 24px;
      gap: 24px;
    }
  }
}

.view {
  display: block;
}

.open {
  width: 200px;
  flex-shrink: 0;
}

.hamburger_right {
  padding-left: calc(100% - 15px - 56px);
  width: 56px;
  justify-content: flex-end;
  padding-right: 15px;
  transition: all 0.2s;
}

.open_menu > li {
  height: 44px;
  opacity: 1;
}

.first_sub_menu:before {
  content: '';
  border-top: #7b7b7b solid 0.5px;
  width: 180px;
  position: absolute;
  top: 0;
  left: 10px;
}