@import '../../../../styles/normalize';
@import '../../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../../styles/mixin';
@import '../../../../styles/global';

.quote_register--quotation--nano_btn_area {
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  right: 2%;
}

@media screen and (max-width: 800px) {
  .page_base--tabs {
    .ant-tabs {
      // padding-left: 5%;
      // padding-right: 5%;
    }
  }
  .quote_register--quotation--nano_btn_area {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    position: absolute;
    right: 16px;
    left: 16px;
    bottom: 64px;
  }
  .quote_register--submit_buttons {
    align-items: flex-end;
    padding: 24px 16px;
    gap: 15px;
  }
  .bottom_btn_area {
    height: 135px;
    width: 100%;
  }
}
