@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

@media screen and (max-width: 800px) { 
.input_union {
    width: 100%;
}
}