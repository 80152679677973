@import '../../styles/normalize';
@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.login-form, .forget_pass_form, .change_pass_form {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
}

.login-form {
    //width: unquote('min(90%, 455px)');
    margin: 24px auto 0;
}

.custom_form--submit_button_area {
    width: 100%;
}

.is_corporate_id_form {
    gap: 0;
}

.custom_form--double_button_area {
    width: 520px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    margin-top: 20px;
}

@media screen and (max-width: 800px) {

    .login-form {
        width: unquote('min(90%, 455px)');
        margin: 16px auto 0;
    }

    .custom-form {
        gap: 20px;
    }

    .forget_pass_form, .change_pass_form {
        width: unquote('min(100%, 455px)');
        height: auto;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        align-items: center;
        gap: 24px;
        margin: 0 auto;
    }

    .custom_form--double_button_area {
        width: unquote('min(100%, 520px)');
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 23px;
        margin-top: 0px;

        button {
            span {
                font-size: 12px;
            }
        }
    }
}