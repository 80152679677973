@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.attendance_process_modal_sp {

  &--tooltip {
    .ant-tooltip-content {
      .ant-tooltip-arrow {
        left: calc(50% + 15px);
      }
      .ant-tooltip-inner {
        white-space: pre-wrap;
      }
    }
  }

  &--main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 40px 0;
  }

  &--title {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-bottom: 2px solid #F3F3F3;
    padding-bottom: 8px;

    span {
      font: normal normal bold 18px Noto Sans CJK JP;
      color: #242424;
    }
  }

  &--contents {
    width: 100%;
    height: 244px;
    overflow: scroll;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    margin: 24px 0 10px;
  }

  &--item, &--calc_area {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 8px;

    span {
      font-size: 12px;
    }
  }

  &--calc_area {
    flex-flow: column;
    align-items: flex-start;

    .custom_input--radio_button {
      gap: 8px;

      .custom_input--radio_input_area {
        .custom_input--radio_display_area {
          height: 20px;
        }
      }
    }
  }

  &--calc_title {
    font: normal normal bold 12px Noto Sans CJK JP;
    line-height: 18px;
  }

  &--common_field, &--pay_closing_date {
    .custom_input--input_area_ant_design {
      height: 40px;

      .ant-picker {
        width: 100%;
        height: 40px;
        box-sizing: border-box;
      }
    }
  }

  &--pay_closing_date {
    width: calc(50% - 14px);
  }

  &--btn {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    margin-top: 30px;
  }
}