@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

@media screen and (max-width: 800px) {
  .myPage_forPc {
    display: none;
    visibility: hidden;
  }
  .myPage_forSp {
    display: unset;
    visibility: visible;
  }
}

@media screen and (min-width: 801px) {
  .myPage_forPc {
    display: unset;
    visibility: visible;
  }
  .myPage_forSp {
    display: none;
    visibility: hidden;
  }
}

.my_page {
  &--area {
    width: 100%;
    height: auto;
    padding: 40px 0 109px;
  }
}