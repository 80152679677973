@import '../../../../../../styles/normalize';
@import '../../../../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../../../../styles/mixin';
@import '../../../../../../styles/global';

.quote_register--quotation_info--table {
  position: relative;
  min-width: 820px;
  width: calc(100% - 212px);
  margin: 0 106px;
  &--title {
    height: 118px;
    font: normal normal normal 32px/46px Noto Sans CJK JP;
    color: #242424;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--header {
    display: flex;
    justify-content: space-between;
    &--left {
      &--company_nm {
        font: normal normal normal 20px/28px Noto Sans CJK JP;
        margin-bottom: 16px;
      }
      &--info {
        display: flex;
        margin-top: 12px;
        gap: 8px;
        &--left {
          background: #e9f2fc;
          padding: 8px 24px;
          text-align: left;
          display: flex;
          flex-flow: column;
          gap: 8px;
          @media screen and (max-width: 800px) { 
            padding: 8px 16px;
          }

          //span:nth-child(1) {
          //  margin-bottom: 12px;
          //}
          //span:nth-child(2) {
          //  margin-bottom: 25px;
          //}

          span {
            height: 36px;
            width: 100%;
            text-align: left;
            line-height: 36px;

            &:nth-child(3) {
              height: 44px;
              line-height: 44px;
            }
          }
        }
        &--right {
          display: flex;
          flex-flow: column;
          padding: 8px 0;
          justify-content: space-between;

          //span:nth-child(1) {
          //  margin-bottom: 12px;
          //}
          //span:nth-child(2) {
          //  margin-bottom: 15px;
          //}

          span {
            height: 36px;
            width: 100%;
            text-align: left;
            line-height: 36px;
            padding-left: 15px;
          }
        }
      }
      &--total {
        display: flex;
        margin-top: 16px;
        gap: 23px;
        div:nth-child(1) {
          width: 176px;
          height: 78px;
          background: #e9f2fc;
          display: flex;
          align-items: center;
          justify-content: center;
          font: normal normal normal 32px/46px Noto Sans CJK JP;
          color: #242424;
        }
        div:nth-child(2) {
          display: flex;
          align-items: center;
          justify-content: center;
          font: normal normal normal 32px/46px Noto Sans CJK JP;
        }
      }
    }
    &--right {
      display: flex;
      gap: 20px;
      &--catalog {
        width: 90px;
        height: 112px;
        display: flex;
        flex-flow: column;
        padding: 18px 18px 18px 24px;
        background-color: #e9f2fc;
        gap: 28px;
      }
      &--no {
        display: flex;
        align-items: center;
        margin: 8px 0;
      }
      &--info {
        display: flex;
        flex-flow: column;
        margin: 24px 0 0 15px;
        span:nth-child(1) {
          margin-bottom: 8px;
        }
      }
      .header-right-column {
        height: 40px;
        width: 100px;
        padding-top: 12px;
        //align-items: center;
        //justify-content: center;
      }
      .custom_input--area_ant_design {
        .ant-picker {
          width: 165px;
        }
      }
    }
  }

  &--area {
    margin-top: 12px;
    // thead tr th:nth-child(7) {
    //   min-width: 140px;
    // }
    tbody {
      tr {
        position: relative;
      }
      //後ろから３行の後ろから３番目のtd
      tr:nth-last-child(-n + 3) {
        td:nth-last-child(3) {
          background-color: #6a6f7c;
          font: normal normal bold 14px Noto Sans CJK JP;
          color: #ffffff;
          padding: 19px 0 17px 16px;
        }
      }
    }
  }

  &--note {
    border: 1px solid #a0aab8;
    margin: 16px 0 100px 0;
    &--header {
      background: #a0aab8;
      font: normal normal bold 14px/20px Noto Sans CJK JP;
      color: #ffffff;
      padding: 6px 0 6px 24px;
    }
    &--input {
      padding: 8px;
    }
  }
}

.quote_register--quotation_info {
  border: 1px solid #a0aab8;
  border-radius: 3px;
  overflow-x: auto;
  margin-top: 16px;
}
// スクロールなしスクロール
// .quote_register--quotation_info::-webkit-scrollbar {
//   display: none;
// }

.info_table--column {
  &_order {
    position: absolute !important;
    left: -72px;
    //top: 14px;
    margin: 11px 0;
  }
  &_action {
    position: absolute !important;
    right: -80px;
    //top: 14px;
    margin: 11px 0;
  }
}

.row-dragging {
  td {
    padding: 0 8px 0 0;
  }
  .info_table--column_order {
    padding-left: 6px;
  }
  .info_table--column_action {
    padding-right: 4px;
  }
  td:nth-child(2).info_table--column {
    width: 100%;
  }
  td:nth-child(4).info_table--column {
    width: 20%;
  }
  td:nth-child(6).info_table--column {
    width: 20%;
  }
  td:nth-last-child(2).info_table--column {
    width: 30%;
  }
}

@media screen and (max-width: 800px) {
  .quote_register {
    &--custom_input_date {
      .custom_input--area_ant_design {
        .custom_input--input_area_ant_design {
          .ant-picker {
            width: 100%;
          }
        }
      }
    }
  }
}
