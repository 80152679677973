@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.analyze_profit {
  &--main {
    padding: 0 40px;
    position: relative;
    @media screen and (max-width: 800px) {
      padding: 0 20px;
    }

    .list_view--buttons_base {
      padding: 0 0;
    }

    .list_view--list_wrapper {
      width: 100%;
      margin: 0 0;
    }
  }

  &--list {
    padding: 0 40px;
    position: relative;
    margin-bottom: 109px;
    @media screen and (max-width: 800px) {
      padding: 0 0;
    }

    .list_view--buttons_base {
      padding: 0 0;
    }

    .list_view--list_wrapper {
      width: 100%;
      margin: 0 0;
    }
  }

  &--btn {
    text-align: right;
    margin: 16px 0;
  }
  &--chart {
    height: 755px;
    background-color: #ffffff;
    margin-top: 17px;
    position: relative;
    padding: 7px 100px 40px 40px;
    z-index: 2;

    &--title {
      line-height: 22px;
      color: #6a6f7c;
      font: normal normal normal 14px/20px Noto Sans CJK JP;
      margin-bottom: 32px;
    }

    &--main {
      height: calc(100% - 54px);
      display: flex;
      align-items: flex-start;
      gap: 170px;

      &--legend {
        display: flex;
        flex-flow: column nowrap;
        gap: 16px;
        width: 96px;
        height: 184px;
        flex-shrink: 0;
      }
    }

    #budget {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 11px;
        left: -48px;
        background-color: #505050;
        width: 40px;
        height: 3px;
      }
      &::before {
        content: '';
        position: absolute;
        top: 6px;
        left: -34px;
        background-color: #505050;
        width: 12px;
        height: 12px;
        border-radius: 100%;
      }
    }
    #profitTy {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 11px;
        left: -48px;
        background-color: #1ba466;
        width: 40px;
        height: 3px;
      }
      &::before {
        content: '';
        position: absolute;
        top: 6px;
        left: -34px;
        background-color: #1ba466;
        width: 12px;
        height: 12px;
        border-radius: 100%;
      }
    }
    #profitLy {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: 11px;
        left: -48px;
        background-color: #fdc27a;
        width: 40px;
        height: 3px;
      }
      &::before {
        content: '';
        position: absolute;
        top: 6px;
        left: -34px;
        background-color: #fdc27a;
        width: 12px;
        height: 12px;
        border-radius: 100%;
      }
    }
    #profitTyTotal {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: 2px;
        left: -48px;
        background-color: #3e97eb;
        width: 40px;
        height: 20px;
        border-radius: 3px;
      }
    }
    #profitLyTotal {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: 2px;
        left: -48px;
        background-color: #dbedff;
        width: 40px;
        height: 20px;
        border-radius: 3px;
      }
    }
  }
}
