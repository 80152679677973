@import '../../../../../styles/normalize';
@import '../../../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../../../styles/mixin';
@import '../../../../../styles/global';

.info_item {
  display: flex;
  flex-direction: row;
  & > div:nth-child(1) {
    color: #646464;
  }
  & > div:nth-child(2) {
    margin-left: 20px;
    flex: 1;
    textarea {
      margin-top: 16px;
      width: 80%;
      min-height: 132px;
      padding: 10px 16px;
      &::placeholder {
        font-size: 14px;
        color: #bcbcbc;
      }
    }
  }
}
.report_item {
  margin-bottom: 20px;
  .report_item_left > div {
    display: flex;
    & > div {
      display: flex;
      margin: 6px 0;
      .report-text {
        color: grey;
      }
    }
    .report_item_cell {
      display: flex;
      flex-direction: column;
    }
    .report_item_label {
      display: inline-block;
      text-align: right;
      min-width: 150px;
      margin-right: 30px;
    }
  }
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .sub_text {
    color: #242424;
    font-size: 14px;
    position: absolute;
    bottom: 10px;
    right: 0;
    white-space: nowrap;
  }
  & > div {
    display: flex;
    flex: 50%;
    & > div {
      display: flex;
      flex-direction: column;
      text-align: right;
      justify-content: center;
      min-width: 150px;
      margin-right: 5px;
      position: relative;
      & > span:nth-child(1) {
        color: #646464;
      }
    }
    &:nth-child(1) {
      .gw-btn {
        width: 260px;
        background-color: #ffffff;
        border: 1px solid #bcbcbc;
        height: 44px;
        color: #bcbcbc;
      }
      .gw-btn.status_btn {
        &_3 {
          background-color: #bcbcbc;
          color: #ffffff;
        }
        &_1 {
          background-color: #ffffff;
          border: 1px solid #2b3245;
          color: #2b3245;
        }
        &_0 {
          background-color: #d80245;
          color: #ffffff;
        }
      }
    }
    &:nth-child(2) {
      & > div {
        margin-left: 40px;
      }
      .gw-btn {
        width: 240px;
        background-color: #0056d3;
        height: 44px;
      }
    }
  }
  .report_item_wrapper {
    display: flex;
    align-items: center;
  }
  .report_item_plan {
    display: flex;
    align-items: center;
  }
}

.payment_item {
  table {
    @include baseTable();
    th {
      text-align: start;
      padding-left: 20px;
      &:nth-child(2) {
        text-align: center;
        padding-left: 0;
      }
    }
    td {
      padding: 30px 10px;
      &:nth-child(2) {
        font-weight: bold;
        padding: 0 30px;
        font-size: 20px;
      }
      &:nth-child(3) {
        min-width: 440px;
        padding: 5px 15px 18px;
      }
    }
  }
  .gw-btn {
    background: #ffffff;
    color: #2b3245;
    width: 240px;
    border: 1px solid #2b3245;
    height: 44px;
    margin: 15px 0 10px;
  }
  &--cost_container {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  &--cost {
    display: flex;
  }
  &--cost_images {
    margin-top: 10px;
    display: flex;
    gap: 15px 15px;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 800px) {
  .payment_item {
    table {
      td {
        padding: 0 10px;
      }
    }
    .ant-descriptions-view table {
      border-collapse: collapse;
      th,
      td {
        border: 1px solid #a0aab8;
      }
    }
    .ant-descriptions {
      width: 100%;
      min-width: 100%;
    }

    .ant-descriptions-row {
      .ant-descriptions-item-content {
        text-align: center;
        display: unset;
      }
      .ant-descriptions-item-container {
        justify-content: center;
        align-items: center;
      }
      &:nth-child(2n + 1) {
        background-color: #6a6f7c;
      }
      &:nth-child(2n) {
        .ant-descriptions-item-container {
          padding: 15px 10px;
        }
      }
      &:nth-child(4) {
        .ant-descriptions-item-content {
          font-weight: bold;
        }
      }
    }
    .ant-descriptions-item,
    .ant-descriptions-item-container,
    .payment_item--cost_container {
      width: 100%;
    }
    .ant-descriptions-item-label {
      color: white;
      font-size: 12px;
      font-weight: bold;
    }
    .ant-descriptions-row > th,
    .ant-descriptions-row > td {
      padding-bottom: 0;
    }
  }
  .recruit_matter_detail--various_application_info_area {
    overflow-x: hidden !important;
    width: 100%;
    align-items: unset;
  }

  .payment_item--cost {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
  }
  .custom_input--input_area {
    input {
      width: 100% !important;
    }
  }
  .payment_item--cost_images {
    gap: 0;
    & > .image_cell_container > .image_cell_name {
      display: none;
    }
  }
}
