@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

@media screen and (max-width: 800px) {
  .shift_forPc {
    display: none;
    visibility: hidden;
  }
  .shift_forSp {
    display: unset;
    visibility: visible;
  }
}

@media screen and (min-width: 801px) {
  .shift_forPc {
    display: unset;
    visibility: visible;
  }
  .shift_forSp {
    display: none;
    visibility: hidden;
  }
}

.staff_pay_approval {
  &--body {
    font-family: Noto Sans CJK JP;
  }

  &--area {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 48px;
  }

  &--search {
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 88px;

    &_input,
    &_input_short {
      width: 424px;
      height: 44px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 24px;
    }

    &_input_short {
      width: 200px;
    }
  }

  &--title {
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 40px;
  }

  &--sub_title {
    font: normal normal bold 16px/20px Noto Sans CJK JP;
    padding-bottom: 5px;
    color: #646464;
  }

  &--pay_detail {
    width: 90%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
    padding-bottom: 40px;
  }

  &--item_box {
    width: 319px;
    height: 156px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;

    &_title {
      width: 100%;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 2px 2px 0 0;
      font: normal normal bold 16px/20px Noto Sans CJK JP;
      color: #ffffff;
      background-color: #6a6f7c;
    }

    &_contents {
      width: 100%;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #2b3245;
    }

    &_unity {
      width: 100%;
      height: 156px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  &--item_box_left {
    width: 25%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;

    .staff_pay_approval--item_box_title {
      border-radius: 3px 0 0 0;
      border-right: 1px solid #a0aab8;
    }
    .staff_pay_approval--item_box_contents {
      border-radius: 0 0 0 3px;
      border-right: 1px solid #a0aab8;
      border-left: 1px solid #a0aab8;
      border-bottom: 1px solid #a0aab8;
    }
  }

  &--item_box_center {
    width: 25%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;

    .staff_pay_approval--item_box_title {
      border-right: 1px solid #a0aab8;
      border-radius: 0;
    }

    .staff_pay_approval--item_box_contents {
      border-right: 1px solid #ffffff;
      border-bottom: 1px solid #a0aab8;

      .staff_pay_approval--item_box_center_item {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &--item_box_right {
    width: 25%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;

    .staff_pay_approval--item_box_title {
      border-radius: 0 3px 0 0;
    }
    .staff_pay_approval--item_box_contents {
      border-radius: 0 0 3px 0;
      border-right: 1px solid #a0aab8;
      border-bottom: 1px solid #a0aab8;
    }
  }

  &--solo_contents {
    border-radius: 0 0 3px 3px;
    border-right: 1px solid #a0aab8;
    border-left: 1px solid #a0aab8;
    border-bottom: 1px solid #a0aab8;
  }

  &--no-data {
    width: 100%;
    font-size: 14px;
    color: #2b3245;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 31%;
  }

  &--table {
    border-collapse: collapse;
    border: #a0aab8 solid 1px;
    width: 100%;
    margin-bottom: 20px;
  }

  &--table_caption {
    border-radius: 2px 2px 0 0;
    border: #a0aab8 solid 1px;
    font: normal normal bold 16px/20px Noto Sans CJK JP;
    color: #ffffff;
    background-color: #6a6f7c;
    padding-left: 15px;
    vertical-align: middle;
    height: 30px;
  }

  &--table_row {
    display: table-row;
    border: #a0aab8 solid 1px;
  }

  &--table_cell {
    vertical-align: middle;

    &_title {
      color: #ffffff;
      background-color: #a0aab8;
      border-top: #e0e0e0 solid 0.5px;
      border-left: #e0e0e0 solid 0.5px;
      padding-left: 15px;
      vertical-align: middle;
    }

    &_title_column {
      color: #ffffff;
      background-color: #a0aab8;
      border-left: #e0e0e0 solid 0.5px;
      text-align: center;
      vertical-align: middle;
    }

    &_column {
      background-color: #ffffff;
      border-left: #a0aab8 solid 0.5px;
      text-align: center;
      vertical-align: middle;
    }

    &_colum_detail {
      background-color: #ffffff;
      border-bottom: #a0aab8 solid 0.5px;
      vertical-align: middle;
      padding-left: 15px;
    }
  }

  &--pay_year_area {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 40px;
  }

  &--total_info {
    width: 39%;
    height: 156px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 0.5px solid #a0aab8;
    border-radius: 0 0 3px 3px;
  }

  &--total_info_left,
  &--total_info_right {
    width: 50%;
    height: 156px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;

    &_title,
    &_title_right {
      width: 100%;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      font: normal normal bold 16px/20px Noto Sans CJK JP;
      color: #ffffff;
      background-color: #6a6f7c;
      border-radius: 3px 0 0 0;
      border-right: 1px solid #a0aab8;
    }

    &_title_right {
      border-radius: 0 3px 0 0;
      border-right: none;
    }

    &_contents {
      width: 100%;
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      font: normal normal bold 16px/20px Noto Sans CJK JP;
      color: #2b3245;
      border-right: 1px solid #ffffff;
      border-left: 1px solid #a0aab8;
      border-bottom: 1px solid #a0aab8;

      &_item {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid #a0aab8;
      }
    }
  }

  &--pay_year_list {
    width: 36%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &--pay_year_list_contents {
    width: 46%;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
  }

  &--pay_year_list_title {
    width: 100%;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font: normal normal bold 16px/20px Noto Sans CJK JP;
    color: #ffffff;
    background-color: #6a6f7c;
    border-radius: 3px 0 0 0;
    border-right: 1px solid #a0aab8;
  }

  &--pay_year_list_items {
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #2b3245;
    border-left: 1px solid #a0aab8;
    border-right: 1px solid #a0aab8;
    border-bottom: 1px solid #f3f3f3;
  }

  &--copy_right {
    width: 100%;
    line-height: 50px;
    font-size: 14px;
    color: #383f52;
    letter-spacing: 3px;
    text-align: center;
  }

  &--submit_buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    padding-top: 30px;

    @include sp() {
      width: 100vw;
      justify-content: center;
      gap: 23px;
    }
  }
}
