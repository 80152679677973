@import '../../styles/normalize';
@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.add_line_button {
  border-radius: 36px;
  height: 36px;
  width: 36px;
  border: solid 1px grey;
  &:hover {
    cursor: pointer;
  }

  &--disabled {
    border-radius: 36px;
    height: 36px;
    width: 36px;
    background-color: #bcbcbc;
    opacity: 0.5;

    &:hover {
      cursor: pointer;
    }
  }
}
