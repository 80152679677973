@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.naming_edit--area {
  width: 67%;
  height: 14px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  margin-top: 26px;

  .naming_edit {

    &--label_area {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-shrink: 0;
      width: 232px;

      label {
        font-size: 14px;
        color: #646464;

        span {
          color: #d80245;
        }
      }
    }

    &--display_area {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 14px;
      color: #242424;
    }
  }
}
@media screen and (max-width: 800px) { 
  .naming_edit--area {
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 0px;

    gap: 8px;
    flex-flow: column;
    align-items:flex-start;
    justify-content: flex-start;

  
    .naming_edit {
      &--label_area {
        width: 100%;
        justify-content: flex-start;

        label {
          font-size: 12px;
        }
  
      }
      &--display_area {
        width: 100%;
        font-size: 12px;
        align-items:flex-start;
        justify-content: flex-start;

      }
    }
  }
}