@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.change_pass_modal {
  &--title_area {
    width: 100%;
    height: 43px;
    font: normal normal bold 24px/30px Noto Sans CJK JP;
    color: #242424;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 2px solid #F3F3F3;
  }

  &--input_area {
    width: 100%;
    height: auto;
  }

  &--button_area {
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;
    gap: 40px;
  }
}

.change_pass_modal_form {
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;

  input {
    box-sizing: border-box;
  }
}

@media screen and (max-width: 800px) {
  .change_pass_modal {
    &--title_area {
      width: 100%;
      line-height: 20px;
      font-size: 18px;
      height: 18px;
      padding-bottom: 8px;
    }

    &--input_area {
      .change_pass_modal_form {
        gap: 8px;
        .custom_input--password_column {
          .custom_input--password_area {
            width: 100%;
            height: auto;
            display: grid;
            grid-template:
              'label' 18px
              'input' 40px
              'error' 18px
              / 100%;
            row-gap: 8px;
          }

          .custom_input--password_error {
            justify-content: flex-start;
          }
          .custom_input--icon_field {
            position: relative;

            .icon {
              position: absolute;
              right: 16px;
              bottom: 33px;
            }
          }
        }
      }
    }
  }
}