@import '../../styles/normalize';
@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.free_form_cell {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  position: relative;
  align-items: center;
  border: 3px solid #ffffff;
  background-size: cover;
  background-position: center;
  &--text {
    border: none;
  }
  min-height: 1384px;
  .gw-btn {
    height: 44px;
    width: 240px;
    background-color: white;
    color: #2b3245;
    border: 1px solid #2b3245;
    border-radius: 6px;
  }
  textarea {
    flex: 1;
  }
  .free_form_image {
    object-fit: cover;
  }
  &--tip {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    color: #7b7b7b;
    margin-top: -10px;
  }
}

.free_form--container_pattern_2 {
  .free_form_cell {
    max-height: 692px;
    min-height: 692px;
    .free_form_image {
      max-height: 692px;
    }
  }
  flex-direction: column;
}
.free_form--container_pattern_3 {
  flex-wrap: wrap;
  .free_form_cell {
    max-height: 692px;
    min-height: 692px;
    width: 692px;
    flex: 45%;
    .free_form_image {
      max-height: 692px;
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 800px) {
  .free_form_cell {
    min-height: 540px;
    .free_form_image {
      max-height: 540px;
      max-width: 100%;
    }
  }
  .free_form--container_pattern_2,
  .free_form--container_pattern_3 {
    .free_form_cell {
      max-height: 270px;
      min-height: 270px;
      .free_form_image {
        max-height: 270px;
        max-width: 100%;
      }
    }
  }
  .free_form--container_pattern_3 {
    .free_form_cell {
      width: 45%;
      .gw-btn {
        width: 95%;
        align-self: center;
        margin-left: 0px !important;
        margin-right: 0px !important;
      }

      & > span {
        width: 95%;
        .ant-upload,
        .gw-btn {
          width: 100%;
        }
      }
    }
  }
}
