@import '../../styles/normalize';
@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

@media screen and (max-width: 800px) {

  .custom_select_add_button_area {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;

    .custom_input--label {
      width: 100%;
      height: 18px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      label {
        line-height: 18px;
        font-size: 12px;
        color: #646464;
      }
    }

    .custom_input--input_area {
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;

      .custom_input--select_box_unit {
        width: auto;
        line-height: 18px;
        font-size: 12px;
        white-space: nowrap;
      }

      .custom_select--area {
        width: 100%;
        height: 40px;

        .ant-select {
          width: 100%;
          height: 40px;
          padding-right: 16px;

          .ant-select-selector {
            width: calc(100% + 16px);
            height: 40px;
            border-radius: 6px 0 0 6px;
            border-top: 1px solid #2b3245;
            border-left: 1px solid #2b3245;
            border-bottom: 1px solid #2b3245;
            border-right: none;

            .ant-select-selection-placeholder {
              font-size: 12px;
              padding-top: 4px;
            }

            .ant-select-selection-item {
              padding-top: 4px;
              font-size: 12px;
            }

            .ant-select-selection-search {
              .ant-select-selection-search-input {
                height: 38px;
                font-size: 12px;
                padding-left: 5px;
              }
            }

            .ant-select-selection-overflow {
              .ant-select-selection-overflow-item {
                .ant-select-selection-item-content {
                  font-size: 12px;
                }
              }
            }
          }
        }

        .add_button--area {
          .add_button {
            width: 40px;
            height: 40px;
            border-radius: 0 6px 6px 0;
            border-top: 1px solid #2b3245;
            border-right: 1px solid #2b3245;
            border-bottom: 1px solid #2b3245;
            border-left: none;
            padding: 10px;
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #7B7B7B;

            &:hover {
              background-color: #0056d3;
            }
            &:active {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
}