@import '../../../../styles/normalize';
@import '../../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../../styles/mixin';
@import '../../../../styles/global';

.tab_content {
  padding: 0 70px;
  position: relative;
}
.performance_table,
.free_form {
  &--title {
    display: flex;
    margin-top: 20px;
  }
  &--add-btn {
    background-color: #464646;
    width: 40px;
    height: 40px;
    color: white;
    font-size: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-left: 0px;
  }
  &--layout_container_father {
    position: relative;
    width: 100%;
    & > .gw-btn {
      width: 240px;
      height: 44px;
      border-width: 1px;
      position: absolute;
      left: -10px;
    }
  }
  &--layout_container_wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-x: scroll;
  }
  &--layout_container {
    background-color: $sky-blue;
    display: flex;
    margin-top: 40px;
    @media screen and (max-width: 800px) {
      margin-top: 40px;
    }
    width: 100%;
    min-width: 1492px;
    justify-content: center;
    padding: 30px 15px 8px;
    gap: 10px;
    & > .delete_icon {
      top: 42px;
      @media screen and (max-width: 800px) {
        top: 102px;
      }
    }
  }
  &--empty_layout {
    display: flex;
    flex: 1;
    height: 100%;
    width: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 3px solid #ffffff;
    & > img {
      height: 60px;
      width: 60px;
      margin-bottom: 15px;
    }
    span {
      color: #646464;
    }
  }
  &--content {
    position: relative;
    overflow-y: scroll;
    width: 1552px;
    max-width: 100%;
    .report_switch {
      position: absolute;
      left: 25px;
      z-index: 4;
      @media screen and (max-width: 800px) {
        left: 5px;
      }
    }
    margin-top: 20px;
    display: flex;
    justify-content: center;
    border: 1px solid var(--unnamed-color-a0aab8);
    border: 1px solid #a0aab8;
    border-radius: 3px;
    padding: 20px 30px 100px;
    @media screen and (max-width: 800px) {
      padding: 20px 5px 100px;
    }
    margin-bottom: 15px;
  }

  &--empty {
    height: 503px;
    justify-content: center;
    padding-bottom: 50px;
  }
  &--description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & .gw-btn {
      width: 240px;
      height: 44px;
      background-color: #ffffff;
      & > span {
        color: black !important;
        margin: 0px !important;
      }
      border-width: 1px;
      margin-top: 10px;
      display: flex;
      justify-content: center;
    }
    span {
      &:nth-child(1) {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      &:nth-child(2) {
        margin-bottom: 10px;
        color: #a0aab8;
      }
    }
    @media screen and (max-width: 800px) {
      span {
        margin: 0 20px;
        &:nth-child(1) {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 20px;
        }
      }
      & .gw-btn {
        width: #{'min(240px, calc(85vw - 100px))'};
      }
    }
  }

  @media screen and (max-width: 800px) {
    &--content {
      width: unset;
    }
  }
}

.performance_table--layout_container {
  // max-height: 845px;
}

.report_image {
  padding-top: 15px;
  &--images {
    background-color: $sky-blue;
    display: flex;
    padding: 15px;
    flex-wrap: wrap;
    justify-content: center;
    & > i {
      width: 188px;
    }
  }
  .custom_input--area {
    margin: 15px 0;
  }
  .custom_input--label {
    width: 120px;
  }
  border: 1px solid #a0aab8;
  border-bottom: none;
  .report_switch {
    margin-left: 50px;
    display: inline-block;
    margin-bottom: 25px;

    @media screen and (max-width: 800px) {
      display: flex;
      margin-bottom: 0;
      padding-bottom: 25px;
    }
    .common_switch {
      &--label {
        padding-right: 20px;
      }
      @media screen and (max-width: 800px) {
        margin-right: 12px !important;
        &--label {
          padding-right: 10px;
        }
      }
    }
  }

  &--action {
    display: flex;
    align-items: center;
    margin-top: 20px;
    &_tip {
      color: #bcbcbc;
      margin-left: 15px;
    }
  }
}

.customer_voice {
  &--checkbox_container {
    display: flex;
    .gw-checkbox-label {
      margin-right: 30px;
    }
  }
  .tab_content .switch_input:last-child {
    margin-bottom: 0px;
    .switch_input--input {
      margin-top: 0;
    }
  }
  &--inputs_custom {
    max-width: 900px;
    display: flex;
    flex-wrap: wrap;
    gap: 15px 50px;
    .custom_input--select_box_area {
      width: fit-content;
      .custom_input--select_box_input_area {
        width: fit-content;
        .custom_select--area {
          width: fit-content;
          .ant-select {
            width: 85px;
          }
        }
      }
    }

    @media screen and (max-width: 800px) {
      .custom_input--select_box_area {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;

        .custom_input--select_box_label {
          width: 156px;
          line-height: 40px;
        }

        .custom_input--select_box_input_area {
          .custom_select--area {
            .ant-select {
              width: 63px;
            }
          }
        }
      }
    }
  }
}
.customer_attribute {
  &--inputs {
    display: flex;
  }
  &--inputs_flexwrap {
    max-width: 500px;
    flex-wrap: wrap;
    gap: 15px 50px;
  }
  .switch_input:last-child {
    .switch_input--input {
      margin-left: 90px !important;
    }
  }
}

.free_form {
  &--content {
    width: 100%;
    padding: 20px 5px 20px;
  }
  &--layout_container {
    min-width: unset;
    & > .delete_icon {
      top: 63px;
      right: 10px;
    }
    padding: 35px 7px 8px;
    width: 100%;
  }
  &--layout_container_wrapper {
    overflow-x: hidden;
  }
}

@media screen and (max-width: 800px) {
  .report_image {
    &.tab_content {
      padding: 10px 7px 0 !important;
    }
    .report_switch {
      margin-left: 3px;
    }
    &--images {
      padding: 15px 2px;
      border-left: 1px solid #a0aab8;
      border-right: 1px solid #a0aab8;
      & > span,
      & .image_uploader {
        width: calc((85vw - 55px) / 2);
        height: calc((85vw - 55px) / 2);
        .image_uploader--upload-image {
          max-width: calc((85vw - 55px) / 2);
        }
      }
    }
  }
  .customer_voice {
    .custom_input--radio_display_area {
      &:nth-child(2n + 1) {
        flex: 20%;
      }
      &:nth-child(2n) {
        flex: 50%;
      }
    }
  }
  .customer_attribute {
    .custom_input--select_box_area {
      margin-bottom: 15px;
      flex-flow: row;
      max-width: 30%;
      align-items: center;
      .custom_input--select_box_label {
        width: 50px;
        align-items: center;
        justify-content: flex-end;
      }
    }
    &--inputs {
      flex-direction: column;
    }
    &--inputs_flexwrap {
      flex-wrap: wrap;
      gap: 0;
      .custom_input--select_box_label {
        min-width: 50px !important;
      }
    }
    .switch_input:last-child {
      .switch_input--input {
        margin-left: 0 !important;
      }
    }
  }

  .free_form {
    .free_form_cell .gw-btn {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}
