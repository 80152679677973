@import '../../styles/normalize';
@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.birthday_selector {
  &--wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 14px;
  }
}