@import '../../styles/normalize';
@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.hamburger {
  &--box {
    position: relative;
    height: 26px;
    width: 26px;
    display: flex;
    cursor: pointer;

    span {
      position: absolute;
      margin: 0 0;
      content: '';
      width: 100%;
      border-bottom: solid 3px #FFFFFF;
      transition: all 0.3s;
    }
  }
}