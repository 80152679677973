@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.apply_format_modal {
  padding: 0 20px;
  &--item {
    margin: 30px 24px 24px;
    display: flex;
    flex-direction: column;
  }
  &--title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #242424;
    margin-bottom: 30px;
  }
  &--text {
    display: flex;
    flex-direction: column;
    text-align: center;
    color: #242424;
  }
  &--actions {
    display: flex;
    justify-content: center;
    margin: 50px 0 20px;
    gap: 40px;
    .gw-btn {
      width: 240px !important;
    }
  }
}

.apply_format_modal_wrapper {
  @media screen and (max-width: 800px) {
    .ant-modal-body {
      height: unset !important;
    }
  }
}
