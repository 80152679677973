@import '../../../../styles/normalize';
@import '../../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../../styles/mixin';
@import '../../../../styles/global';

//並び替えるとき
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: space-between;

  td:nth-child(2).quote_template_list--main_table--column {
    width: 26%;
  }
  td:nth-child(3).quote_template_list--main_table--column {
    width: 64%;
  }
  td:nth-child(4).quote_template_list--main_table--edit {
    width: 3%;
  }
}
.row-dragging {
  .quote_template_list--main_table--column,
  .quote_template_list--main_table--edit,
  .quote_template_list--main_table--del {
    padding: 24px;
  }
}

.quote_template_list {
  background-color: #e9f2fc;
  //addボタン
  &--add--btn {
    float: right;
    margin: 18px 42px;
  }
  //テーブル
  &--main_table {
    margin: 40px 40px 0 40px;
    font: normal normal bold 14px/16px Noto Sans CJK JP;
    min-width: 680px;
    //列
    &--column {
      border-right: 1px dashed #e0e0e0;
    }
    //編集列
    &--edit {
      padding: auto;
    }
    thead {
      tr th:nth-child(-n + 3) {
        border-right: 1px solid #e0e0e0;
      }
      tr th:last-child {
        padding: 0 24px 0 12px;
        min-width: 64px;
      }
      tr th:first-child {
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 50px;
        min-height: 45px;
      }
      tr {
        th {
          height: 36px;
          color: #ffffff;
          background-color: #6a6f7c;
          padding: 0 0 0 24px;
        }
      }
    }
    tbody tr:nth-child(odd) {
      background-color: #f3f3f3;
    }
    tbody {
      tr {
        td {
          font-weight: normal;
          padding: 28px 24px;
        }
      }
      tr td:nth-child(4) {
        padding: 24px 12px 24px 24px;
      }
      tr td:nth-child(5) {
        padding: 24px 24px 24px 12px;
      }
    }
  }
  .ant-table-thead tr th {
    font-weight: bold;
  }
  //addボタン吹き出し
  .add_button--text {
    z-index: 8000;
  }
}
