@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.survey_start_modal {

  &--question_description_area {
    width: 100%;

    a {
      color: #D80245;
      text-decoration: underline;
    }
  }
  &--question_image {
    max-width: 100%;
    height: auto;
  }

  &--wrap {
    width: 100%;
    height: fit-content;
    max-height: 690px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 40px;
    overflow-y: auto;
    scroll-snap-align: start;

    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: #ffffff;
      border-radius: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #bcbcbc;
      border-radius: 5px;
    }

    @media screen and (max-width: 800px) {
      max-height: 450px;
    }
  }

  &--close_btn {
    position: absolute;
    top: -35px;
    right: 0;
  }

  &--title, &--result_title {
    width: 100%;
    height: fit-content;
    font-size: 22px;
    font-weight: bold;
    color: #242424;
    text-align: center;
    margin-bottom: 24px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    @media screen and (max-width: 800px) {
      width: 90%;
      font-size: 20px;
    }
  }

  &--result_title {
    margin-bottom: 52px;
  }

  &--description {
    width: 90%;
    margin-bottom: 40px;
    text-align: center;
    font-size: 14px;
    color: #242424;

    @media screen and (max-width: 800px) {
      font-size: 12px;
    }
  }

  &--btn {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;

    @media screen and (max-width: 800px) {
      gap: 15px;
    }
  }

  &--survey_area {
    width: 90%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
  }

  &--survey_character {
    position: absolute;
    top: -40px;
    right: 0;
    width: 72px;
    z-index: 0;

    @media screen and (max-width: 800px) {
      top: -16px;
      width: 48px;
    }
  }

  &--survey_no {
    width: 150px;
    height: 33px;
    background-color: #E9F2FC;
    border-radius: 6px 6px 0 0;
    padding: 8px 0 0 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    span {
      font-size: 14px;
      font-weight: bold;
      color: #0056D3;
    }
  }

  &--is_required {
    width: 42px;
    height: 25px;
    background-color: #D80245;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
  }

  &--survey_info {
    width: 100%;
    height: fit-content;
    min-height: 40px;
    background-color: #E9F2FC;
    border-radius: 0 3px 0 0;
    padding: 8px 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    z-index: 1;
    box-sizing: border-box;
  }

  &--survey_input {
    width: 100%;
    height: fit-content;
    padding: 20px 16px 16px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    border-right: solid 1px #E9F2FC;
    border-left: solid 1px #E9F2FC;
    border-bottom: solid 1px #E9F2FC;
    box-sizing:border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    margin-bottom: 54px;
    border-radius: 0 0 3px 3px;

    .custom_input--radio_button {
      .custom_input--radio_input_area {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 24px;
      }
    }

    @media screen and (max-width: 800px) {
      margin-bottom: 24px;
    }
  }

  &--thumbnail_image {
    width: 90%;
    height: 284px;
    margin-bottom: 40px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

