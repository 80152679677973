@import '../../styles/normalize';
@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

@media screen and (max-width: 800px) {
  .custom_input {

    &--select_box_area, &--select_box_area_column {
      width: 100%;
      height: auto;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 8px;

      .custom_input {

        &--select_box_label {
          width: 100%;
          height: 18px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          label {
            line-height: 18px;
            font-size: 12px;
            color: #646464;
          }

          .custom_input--label_disabled {
            line-height: 18px;
            font-size: 12px;
            color: #BCBCBC;
          }
        }

        &--select_box_input_area {
          width: 100%;
          height: 42px;

          .custom_input--select_box_unit, .custom_input--select_box_unit_disabled {
            width: auto;
            line-height: 18px;
            font-size: 12px;
            white-space: nowrap;
            flex-shrink: 0;
            color: #242424;
          }

          .custom_input--select_box_unit_disabled {
            color: #BCBCBC;
          }

          .custom_select--area, .custom_select--area_multiple {
            width: 100%;
            height: 42px;

            .ant-select {
              width: 100%;
              height: 42px;
              padding-right: 16px;
              padding-top: 1px;
              box-sizing: border-box;

              .ant-select-selector {
                width: calc(100% + 16px);
                height: 40px;
                border-radius: 6px;
                border: 0.5px solid #2b3245;


                .ant-select-selection-placeholder {
                  font-size: 12px;
                  padding-top: 4px;
                }

                .ant-select-selection-item {
                  padding-top: 4px;
                  font-size: 12px;
                }

                .ant-select-selection-search {
                  .ant-select-selection-search-input {
                    height: 38px;
                    font-size: 12px;
                    padding-left: 5px;
                  }
                }

                .ant-select-selection-overflow {
                  .ant-select-selection-overflow-item {
                    .ant-select-selection-item-content {
                      font-size: 12px;
                    }
                  }
                }
              }
            }

            .ant-select-multiple {
              .ant-select-selector {
                width: calc(100% + 16px);
                height: 40px;
                border-radius: 6px;
                border: 0.5pt solid #2b3245;

                .ant-select-selection-placeholder {
                  font-size: 12px;
                  padding-top: 2px;
                }

                .ant-select-selection-item {
                  padding-top: 0;
                  font-size: 12px;
                }
              }

              .ant-select-clear {
                right: 14px;

                img {
                  position: absolute;
                  right: -5px;
                  top: -8.5px;
                //  margin-bottom: 20px;
                }
              }
            }

            .ant-select-disabled {
              .ant-select-selector {
                border: 0.5px solid #bcbcbc;
              }
            }

            .ant-select-arrow {
              top: 52%;
            }
          }
        }

      }
    }
  }
}