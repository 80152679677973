@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.staff_pay_list {
  &--status {
    border-radius: 22px;
    width: 100%;
    min-width: 100%;
    padding: 3px 10px;
    display: inline-block;
    text-align: center;
  }
}
.staff_pay_base {
  margin-bottom: 100px;
  .list_view--list_table td {
    line-height: unset;
  }
}

@media screen and (max-width: 800px) {
  .staff_pay_base {
    margin-bottom: 0;
    padding-bottom: 265px !important;
  }
}
