@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

td {
  word-wrap: break-word;
}

.invoice_list {
  &--button_area {
    width: calc(100% - 56px);
    height: 92px;
    position: fixed;
    bottom: 0;
    background-color: rgba(106, 111, 124, 0.6);
    z-index: 30;
    padding: 24px 0;
  }

  &--button_area_unity {
    position: fixed;
    right: 40px;
    bottom: 28px;
    width: 520px;
    height: 36px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
  }

  &--checkbox {
    width: 76px;
    height: 36px;
    border-radius: 6px;
    background-color: #E0E0E0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.claim_list--option_button_area {
  height: 44px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.claim_list_input_date_area {
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  .custom_input {
    &--label_ant_design {
      width: 100%;
      height: 24px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-shrink: 0;

      label {
        font-size: 14px;
        color: #646464;

        span {
          color: #d80245;
        }
      }
    }

    &--input_area_ant_design {
      width: 100%;
      height: 44px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;

      .ant-picker {
        width: 100%;
        height: 44px;
      }

      .custom_input--unit_ant_design {
        font-size: 14px;
        color: #646464;
      }
    }
  }
}

.claim_list_select_with_select {
  width: 100%;
  height: auto;
  display: grid;
  grid-template:
    'label error' 24px
    'input input' 44px
    / 50% 50%;
  row-gap: 8px;
  column-gap: 0;

  .custom_input {
    &--select_with_select_label {
      grid-area: label;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-shrink: 0;

      label {
        font-size: 14px;
        color: #646464;

        span {
          color: #d80245;
        }
      }
    }

    &--select_with_select_input_area {
      grid-area: input;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;

      .custom_input--select_box_input_with_icon {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: flex-end;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .invoice_list {
    &--button_area {
      width: 100%;
      height: 135px;
      position: fixed;
      bottom: 0;
      background-color: rgba(106, 111, 124, 0.6);
      z-index: 30;
      padding: 24px 16px;
      @media screen and (max-width: 400px) {
        height: 88px;
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
    }

    &--button_area_unity {
      position: static;
      right: 16px;
      left: 16px;
      bottom: 64px;
      width: calc(100% - 32px);
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      flex-direction: row;

      padding: 8px 16px;
      background-color: #F3F3F3;
      border-radius: 6px;

      @media screen and (max-width: 400px) {
        flex-direction: column;
        height: 96px;
        .invoice_list--button_area_unity_sub_area {
          width: 100% !important;
        }
      }
      button {
        width: 100% !important;
        overflow: hidden;
        span {
          font-size: 12px !important;
        }
      }
    }

    &--checkbox {
      padding: 0 5px;
      width: 76px;
      height: 36px;
      border-radius: 6px;
      background-color: #E0E0E0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .claim_list_input_date_area {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

    .custom_input {
      &--label_ant_design {
        width: 100%;
        height: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-shrink: 0;

        label {
          font-size: 12px;
          color: #646464;

          span {
            color: #d80245;
          }
        }
      }

      &--input_area_ant_design {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;

        .ant-picker {
          width: 100%;
          height: 40px;
        }

        .custom_input--unit_ant_design {
          font-size: 12px;
        }
      }
    }
  }

  .claim_list_select_with_select {
    width: 100%;
    height: auto;
    display: grid;
    grid-template:
      'label error' 20px
      'input input' 40px
      / 50% 50%;
    row-gap: 8px;
    column-gap: 0;

    .custom_input {
      &--select_with_select_label {
        grid-area: label;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-shrink: 0;

        label {
          font-size: 12px;
          color: #646464;

          span {
            color: #d80245;
          }
        }
      }

      &--select_with_select_input_area {
        grid-area: input;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;

        .custom_input--select_box_input_with_icon {
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: flex-end;
        }
      }
    }
  }
}
