@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.invoice_edit {
  &--input_unity {
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }

  &--radio_area {
    width: 100%;
    height: auto;
  }
}

