@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.analyze_trend {
  &--main {
    position: relative;
    width: 100%;
    padding: 28px 40px 40px;
    transition: all 0.5s;
    transform-origin: top;
    max-height: 1080px;
    margin-bottom: 109px;
  }

  &--search_from_to {
    p {
      line-height: 20px;
      font-size: 14px;
      color: #646464;
    }
  }

  &--calendar_unity {
    p {
      line-height: 44px;
      font-size: 16px;
      margin-bottom: 0;
    }
  }

  &--graph_area {
    width: 100%;
    height: 756px;
    margin-top: 24px;
    padding: 8px 5% 40px 40px;
    background-color: #ffffff;
  }

  &--graph_unit {
    width: 100%;
    height: 22px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 32px;

    span {
      line-height: 22px;
      font-size: 14px;
      color: #6a6f7c;
    }
  }

  &--graph_display {
    width: 100%;
    height: calc(100% - 54px);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &--graph_label_area {
    width: 80px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
  }

  &--graph {
    width: 80%;
    height: 100%;
  }

  &--label {
    width: 80px;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;

    div {
      width: 40px;
      height: 24px;
      border-radius: 3px;
    }

    span {
      line-height: 24px;
      font-size: 14px;
      color: #242424;
    }
  }

  &--mark_unity {
    width: 40px;
    height: 22px;
    position: relative;
  }

  &--mark_circle {
    width: 12px !important;
    height: 12px !important;
    border-radius: 50% !important;
    background-color: #ec5e3a;
    position: absolute;
    bottom: 4px;
    right: 14px;
  }

  &--mark_line {
    width: 40px;
    height: 3px !important;
    background-color: #ec5e3a;
    position: absolute;
    top: 12px;
  }
}

@media screen and (max-width: 800px) {
  .analyze_trend {
    &--search_from_to {
      width: 100%;
      height: 66px;

      p {
        line-height: 18px;
        font-size: 12px;
        color: #646464;
      }
    }

    &--calendar_unity {
      p {
        line-height: 40px;
        font-size: 12px;
        color: #646464;
        margin-bottom: 0;
      }
    }

    &--main {
      position: relative;
      width: 100%;
      padding: 28px 16px 40px;
      transition: all 0.5s;
      transform-origin: top;
      max-height: 1080px;
    }

    &--graph_area {
      white-space: nowrap;
      padding: 20px 0 50px 20px;
      height: auto;
    }

    &--graph_unit {
      width: 100%;
      height: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      span {
        line-height: 20px;
        font-size: 12px;
        color: #6a6f7c;
      }
    }

    &--graph_display {
      width: 100%;
      height: calc(100% - 54px);
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 24px;
      overflow: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &--graph_label_area {
      width: 100%;
      display: flex;
      flex-flow: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 20px;
      padding-bottom: 20px;
    }

    &--graph {
      width: 100%;
      height: 100%;
    }

    &--label {
      width: 80px;
      height: auto;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 8px;

      div {
        width: 100%;
        height: 23px;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      span {
        line-height: 22px;
        font-size: 12px;
        color: #242424;
      }
    }

    &--tooltip-list-item {
      width: 100%;
    }

    &--mark_unity {
      width: 100%;
      height: 23px;
      position: relative;
    }

    &--mark_circle {
      width: 12px !important;
      height: 12px !important;
      border-radius: 50% !important;
      background-color: #ec5e3a;
      position: static;
    }

    &--mark_line {
      width: 40px;
      height: 3px !important;
      background-color: #ec5e3a;
      position: absolute;
      top: 11px;
    }
  }
}
