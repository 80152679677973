@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.modal_base {
  &--overlay {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: #24242466;
    transition: all 0.5s;
    display: none;
    justify-content: center;
    align-items: center;
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &--visible {
    display: flex;
  }

  &--dialog {
    background-color: #FFFFFF;
    box-shadow: 0 0 8px #383F524D;
    border-radius: 6px;
    box-sizing: content-box;
    padding: 74px 50px;
    max-height: calc(95% - 148px);

    @media screen and (max-width: 800px) { 
      border-radius: 12px;
      padding: 32px 16px;
    }

    * {
      box-sizing: content-box;
    }
  }
}

