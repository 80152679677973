@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.message_modal {
  &--message_area {
    margin: 0 0;
    color: #242424;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
  }

  &--text_area {
    margin: auto 40px;
  }
}
