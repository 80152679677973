@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.staff_list {

  &--search_area_height_unity {
    width: 350px;
    height: 76px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 8px;
    @media screen and (max-width: 800px) { 
      width: 100%;
    }
  }

  &--height_from_to_span {
    width: 16px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--image_with_name {
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;

    img {
      height: 100%;
      border-radius: 50%;
      cursor: pointer;
      aspect-ratio: 1;
      object-fit: cover;
      object-position: center center;
    }

    div {
      width: 100%;
      height: 90px;
    }
  }

  &--search_area {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 24px;

    .search_select--area {
      width: 400px;
      height: 76px;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;

      .search_select--label {
        width: 100%;
        height: 24px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }

  &--search_area_unity {
    width: 527px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    @media screen and (max-width: 800px) { 
      width: 100%;
      flex-direction: column;
      gap: 14px;
    }
  }
}

.invoice_list {

  &--search_area {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;

    .search_select--area {
      width: 24%;
      height: 76px;
      display: flex;
      flex-flow: column;
      justify-content: flex-end;
      align-items: flex-start;
      gap: 8px;

      .search_select--label {
        width: 100%;
        height: 24px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      .search_select--input_ul_wrap {
        width: 100%;
      }

      img {
        bottom: 13px;
        right: 16px;
      }
    }
  }

  &--search_area_date {
    width: 360px;
    height: 76px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 8px;
    @media screen and (max-width: 800px) { 
      width: 100%;
    }

    .invoice_list--search_area_date_unit {
      height: 44px;
      color: #242424;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .custom_input--area_ant_design {
      height: 76px;
      display: flex;
      flex-flow: column;
      justify-content: flex-end;
      align-items: flex-start;
      gap: 8px;

      .ant-picker {
        width: 165px;
        @media screen and (max-width: 800px) { 
          width: 100%;
        }    
      }
    }
  }

  &--search_area_select {
    width: 31%;
    height: 76px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 8px;
    @media screen and (max-width: 800px) { 
      width: 100%;
      flex-direction: column;
      height: auto;
    }
  }
}