@import '../../styles/normalize';
@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.performance_table--empty_layout {
  min-height: 745px;
  img {
    width: 70px;
    height: 70px;
  }
}
