@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.naming_list {
  &--search_area {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
  }

  &--input_area {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
  }
}

.naming_list_page {
  #th_checkAll {
    //width: 200px;
  }
}

.income_tax {
  &--list_page {
    width: calc(100% - 80px);
    margin: 0 auto;
    padding: 68px 0 0;
    position: relative;

    .list_view--buttons_base {
      padding: 0;
    }

    .list_view--list_wrapper {
      margin: 0 0;
    }
  }

  &--list_start_date {
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
    margin-top: 24px;
    margin-bottom: 24.5px;
  }

  &--list_label {
    font-size: 14px;
    color: #646464;
    @media screen and (max-width: 800px) {
      width: 25%;
    }

    span {
      font-size: 14px;
      color: #d80245;
    }
  }

  &--border {
    width: calc(100% - 80px);
    height: 1px;
    position: absolute;
    top: 109px;
    border-top: 1.5px solid #bcbcbc;
    margin-left: 40px;

    @media screen and (max-width: 800px) {
      width: 90%;
      margin-left: 20px;
    }
  }
}

.income_tax_list--button_area {
  width: 100%;
  height: 92px;
  position: fixed;
  bottom: 0;
  background-color: rgba(106, 111, 124, 0.6);
  z-index: 30;
  padding: 24px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 800px) {
    position: fixed;
    width: 100%;
  }
}

