@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.client_list {
  &--search_area {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
  }

  &--input_area {
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
    @media screen and (max-width: 800px) {
      width: 100%;
    }
  }

  &--search_area_unity {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
}
