@import '../../../../styles/normalize';
@import '../../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../../styles/mixin';
@import '../../../../styles/global';

.attend_forStaffSp {
  &-page_wrap {
    width: 100vw;
    margin-top: 40px;
    padding-bottom: 200px;
  }

  &-calendar {
    &_area {
      width: 92%;
      margin: 0 auto;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: center;
      gap: 24px;
    }

    &_box {
      width: 100%;

      &_date {
        width: 100%;
        padding: 20px 24px;
        background-color: #FFFFFF;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;
        white-space: nowrap;
        font-size: 12px;
      }

      &_detail {
        width: 100%;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 8px;
        padding: 16px;
        background-color: #FFFFFF;
        border-top: 1px solid #F3F3F3;

        div {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 12px;
          margin-top: 8px;

          a {
            display:block;
            height: 100%;
          }
        }

        p {
          width: 100%;
          margin: 0;
          text-align: left;
          font-size: 12px;
          font-weight: bold;
          color: #242424;
        }

        span {
          display: inline-block;
          color: #242424;
          font-size: 12px;
        }
      }
    }
  }
}

.shiftForSp--static_area {
  .day_count {
    display: block;
    width: 100%;
    font-size: 12px;
    text-align: left;
    color: #646464;
  }
}