@import '../../../../styles/normalize';
@import '../../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../../styles/mixin';
@import '../../../../styles/global';

.corporate_register--pay_info {
  .custom_input--area .custom_input--unit {
    position: relative;
  }
}

.corporate_register--pay_info_unity {
  width: 100%;
  height: auto;

  .custom_input--area {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .custom_input--label_disabled, .custom_input--label {
      width: 175px;
      flex-shrink: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @media screen and (max-width: 800px) {
        justify-content: flex-start;
        width: 100%;
      }
    }

    .custom_input--input_area {
      flex-shrink: 0;
      @media screen and (max-width: 800px) {
        flex-direction: column;
        align-items: flex-start;
        height: auto;
      }
      .custom_input--unit_disable, .custom_input--unit {
        font-size: 14px;
        color: #D80245;
        @media screen and (max-width: 800px) {
          font-size: 12px;
        }
      }
    }
  }
}