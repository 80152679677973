@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.shiftForSp {
  &--sp_check_all_area {
    width: 100%;
    height: 21px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 24px;
  }
  &--static {
    &_area {
      width: 100vw;
      height: 270px;
      background-color: #ffffff;
      padding: 40px 4% 16px;
      position: relative;
    }

    &_date {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 24px;
      gap: 16px;

      .custom_input--select_box_area {
        .custom_input--select_box_input_area {
          .custom_select--area {
            .ant-select {
              div {
                div {
                  .ant-select-dropdown {
                    z-index: 3;
                  }
                }
              }
            }
          }
        }
      }
    }

    &_state {
      height: 24px;
      position: absolute;
      bottom: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;

      strong {
        height: 24px;
        line-height: 24px;
        font-size: 16px;
        font-weight: bold;
        color: #242424;
      }

      div {
        height: 24px;
        width: 56px;
        border-radius: 12px;
        text-align: center;
        font-size: 12px;
        line-height: 24px;
      }

      span {
        color: #646464;
        font-size: 12px;
        height: 24px;
        line-height: 24px;
        text-align: left;
      }
    }
  }

  &--calendar {
    &_area {
      width: 92%;
      padding: 24px 0 200px;
      margin: 0 auto;
      overflow-x: hidden;
    }

    &_box {
      width: 100%;
      margin-bottom: 24px;
    }

    &_head {
      width: 100%;
      height: 36px;
      background-color: #e2e4e8;

      span {
        display: inline-block;
        margin-left: 6%;
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        color: #242424;
        text-align: left;
      }
    }

    &_body {
      width: 100%;
      padding: 30px 6%;
      background-color: #ffffff;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 32px;

      .custom_input--toggle_button {
        margin: 0 !important;
      }
    }

    &_toggle_btn_wrap {
      height: 24px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;

      span {
        display: inline-block;
        font-size: 12px;
        font-weight: bold;
        color: #646464;
        height: 24px;
        line-height: 24px;
      }
    }

    &_detail_wrap {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 36px;
      width: 100%;

      div {
        .custom_input--area_ant_design {
          .custom_input--input_area_ant_design {
            .ant-picker {
              width: 100%;
            }
            div {
              div {
                .ant-picker-dropdown {
                  z-index: 3;
                }
              }
            }
          }
        }
      }

      span {
        display: inline-block;
        font-size: 12px;
        //font-weight: bold;
        //color: #646464;
        height: 24px;
        line-height: 24px;
        white-space: nowrap;
      }

      .ant-picker-suffix {
        height: unset;
        line-height: unset;
      }

      .custom_input--area_ant_design {
        width: 100%;
        height: fit-content;
        display: flex;
        grid-template: none;
        gap: 0;
        row-gap: 8px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .shiftForSp {
    .custom_input--area,
    .custom_input--select_box_area,
    .custom_select--area .ant-select .ant-select-selector {
      display: flex !important;
      height: 40px !important;
    }

    .custom_select--area:not(.custom_select--area_multiple)
    .ant-select
    .ant-select-selector
    .ant-select-selection-search
    input,
    .custom_select--area .ant-select,
    .custom_select--area {
      height: 40px !important;
    }

    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      font-size: 12px !important;
      padding-top: 4px !important;
    }

    .add_button--area {
      text-align: left !important;
      margin-top: -16px;

      .add_button--round {
        width: 34px !important;
        height: 34px !important;
        margin: 0 !important;
      }
    }

    .custom_select--area_multiple {
      .ant-select-selection-placeholder {
        padding-top: 0 !important;
      }
      .ant-select-selection-item {
        padding-top: 0 !important;
      }
      .ant-select-selection-item-remove {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .custom_input--select_box_unit {
      width: 24px;
      margin-left: 8px;
    }

    .custom_input--input_area {
      display: flex;
      gap: 8px;
      align-items: center;

      .custom_input--unit {
        width: 24px;
      }
    }

    .custom_input--radio_button {
      width: unset;
      height: fit-content;
      display: flex;
      grid-template: none;
      gap: 0;
      row-gap: 16px;

      .custom_input--radio_button_text {
        font-weight: normal;
        font-size: 14px;
      }
    }

    .custom_input--area_ant_design .custom_input--input_area_ant_design .ant-picker {
      width: 100%;
      height: 40px;
    }

    .custom_input--toggle_label:after {
      z-index: 0;
    }

    label {
      white-space: nowrap;
    }
  }
}
