@import '../../../../styles/normalize';
@import '../../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../../styles/mixin';
@import '../../../../styles/global';

.pay_detail_sp--table {
  margin-top: 26px;
  thead {
    tr {
      height: 36px;
      th:not(:last-child):not(:first-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        background-color: #e0e0e0;
        height: 100%;
      }
      th {
        padding: 0;
        text-align: center;
        background-color: #a0aab8;
        font: normal normal bold 12px/20px Noto Sans CJK JP;
        color: #ffffff;
      }
      th:nth-child(1) {
        background-color: #6a6f7c;
        border-bottom: 0;
      }
    }
  }
  tbody {
    tr:nth-child(1) {
      //勤務情報列
      td:nth-child(1) {
        background-color: #6a6f7c;
        padding: 0 10px;
        border-bottom: 1px solid #6a6f7c;
      }
    }

    tr {
      td:not([rowspan]):not([colspan]) {
        border-right: 1px solid #a0aab8;
        border-bottom: 1px solid #a0aab8;
        padding: 7px;
      }
    }

    tr:last-child {
      td:nth-child(1) {
        border-bottom: 0;
      }
      td:nth-child(2) {
        background-color: #6a6f7c;
        border-bottom: 1px solid #6a6f7c;
      }
    }
  }

  &--column {
    &--info {
      font: normal normal bold 12px/20px Noto Sans CJK JP;
      color: #ffffff;
      height: 116px;
    }
    &--subtotal {
      font: normal normal bold 12px/20px Noto Sans CJK JP;
      color: #ffffff;
      text-align: center;
    }
  }

  &--data {
    &--center {
      text-align: center;
    }
    &--right {
      text-align: right;
    }
  }
}
.pay_detail_sp {
  &--diligence_top {
    display: inline-flex;
    width: 100%;
    height: 36px;
    justify-content: space-between;
    align-items: center;
    font: normal normal bold 16px/20px Noto Sans CJK JP;
    color: #ffffff;
    background-color: #6a6f7c;
    border: #a0aab8 0.25px solid;

    &_left {
      padding-left: 15px;
    }

    &_right {
      padding-right: 15px;
    }
  }

  &--diligence_small_total {
    display: inline-flex;
    width: 100%;
    height: 34px;
    justify-content: center;
    align-items: center;
    font: normal normal bold 12px Noto Sans CJK JP;
    color: #ffffff;
    background-color: #6a6f7c;
    border: #a0aab8 0.25px solid;
    margin-top: 8px;
  }

  &--diligence_title {
    display: inline-flex;
    width: 100%;
    height: 34px;
    justify-content: center;
    align-items: center;
    text-align: center;
    font: normal normal bold 12px Noto Sans CJK JP;
    color: #ffffff;
    background-color: #a0aab8;
    border-left: #a0aab8 0.25px solid;
    border-right: #a0aab8 0.25px solid;
    border-bottom: #ffffff 0.25px solid;

    &_left {
      padding-left: 15px;
    }

    &_right {
      padding-right: 15px;
    }
  }

  &--diligence_contents {
    display: inline-flex;
    width: 100%;
    height: 36px;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    font: normal normal 14px Noto Sans CJK JP;
    color: #242424;
    background-color: #ffffff;
    border-left: #a0aab8 0.25px solid;
    border-right: #a0aab8 0.25px solid;
    border-bottom: #ffffff 0.25px solid;

    &_left {
      padding-left: 15px;
    }

    &_right {
      padding-right: 15px;
    }
  }

  &--diligence_contents_list {
    display: inline-flex;
    width: 100%;
    height: 34px;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    font: normal normal 12px Noto Sans CJK JP;
    color: #242424;
    background-color: #ffffff;
    border-left: #a0aab8 0.25px solid;
    border-right: #a0aab8 0.25px solid;

    &_left {
      padding-left: 16px;
    }

    &_right {
      padding-right: 16px;
    }

    &_border_last {
      border-top: #a0aab8 0.25px solid;
      height: 40px;
    }
  }
}
