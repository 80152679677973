@import '../../styles/normalize';
@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.dashboard_user {
  width: 96.5%;
  height: auto;
  margin: 80px auto 111.5px;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  gap: 64px;

  &--matter_report_situation_area {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;
    gap: 24px;
  }

  &--title_bar {
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
    padding: 10px 24px 9px;
    background-color: #646464;
    border-radius: 6px;
    color: #ffffff;
  }

  &--title {
    width: auto;
    height: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    font: normal normal bold 20px Noto Sans CJK JP;
  }

  &--date {
    width: auto;
    height: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    font: normal normal normal 20px Noto Sans CJK JP;
  }

  &--text_display_area {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-template-rows: 1fr 1fr;
    gap: 24px 40px;
  }

  &--on_click_area {
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }

    &:active {
      opacity: 0.5;
    }
  }

  &--text_display {
    width: 100%;
    height: 96px;
    background-color: #ffffff;
    box-shadow: 0 0 8px #383f524d;
    border-radius: 6px;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }

  &--headline {
    width: 80px;
    height: 29px;
    font: normal normal bold 20px Noto Sans CJK JP;
    color: #6a6f7c;
    white-space: nowrap;
  }

  &--item {
    width: auto;
    min-width: 105px;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;
  }

  &--report {
    width: 100%;
    height: auto;
    font-size: 14px;
    color: #242424;
    word-break: break-all;
    display: flex;
    flex-wrap: wrap;
  }

  &--alert {
    width: 100%;
    height: 24px;
    font-size: 14px;
    color: #d80245;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 4px;

    img {
      width: 21px;
      height: 21px;
    }
  }

  &--assign_situation_area {
    width: 100%;
    height: auto;
    min-height: 515px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;
    gap: 24px;
  }

  &--calender {
    width: 100%;
    height: auto;
    min-height: 443px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    box-shadow: 0 0 8px #383f524d;
    border-radius: 6px;
  }

  &--calender_items_area {
    padding: 24px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
  }

  &--calender_item_header {
    width: 100%;
    font: normal normal bold 16px Noto Sans CJK JP;
    color: #6a6f7c;
    word-break: break-word;
  }

  &--calender_item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &--graph_area {
    width: 100%;
    height: 572px;
    background-color: #ffffff;
    border-radius: 6px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px 24px;
    gap: 26px;
  }

  &--graph_bar {
    width: 100%;
    height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &--graph_title {
    width: 296px;
    font-size: 14px;
    color: #6a6f7c;
  }

  &--graph_date {
    font: normal normal bold 14px Noto Sans CJK JP;
    color: #6a6f7c;
  }

  &--graph_mark {
    width: 296px;
    height: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
  }

  &--matter_mark_area {
    width: 128px;
    height: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }

  &--matter_mark {
    width: 40px;
    height: 20px;
    background-color: #87c2a5;
    border-radius: 3px;
  }

  &--matter_text,
  &--assign_text {
    font-size: 14px;
    color: #6a6f7c;
  }

  &--assign_mark_area {
    width: 144px;
    height: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }

  &--assign_mark_unity {
    width: 40px;
    height: 20px;
    position: relative;
  }

  &--assign_circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #c87e7e;
    position: absolute;
    bottom: 4px;
    right: 14px;
  }

  &--assign_line {
    width: 40px;
    height: 3px;
    background-color: #c87e7e;
    position: absolute;
    bottom: 8.6px;
  }

  &--graph {
    width: 100%;
    height: 482px;
  }

  &--quote_and_profit_area {
    width: 100%;
    height: 168px;
    display: grid;
    grid-template:
      'quote profit profit profit' 1fr
      / 1fr 1fr 1fr 1fr;
    gap: 40px;
  }

  &--quote {
    grid-area: quote;
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
  }

  &--profit {
    grid-area: profit;
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
  }

  &--enrolled_staff_area {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
  }

  &--enrolled_contents_area {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(368px, 1fr));
    grid-template-rows: 1fr;
    gap: 8px;
  }

  &--enrolled_graph {
    width: 100%;
    height: 268px;
    background-color: #ffffff;
    border-radius: 6px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &--operation_situation_area {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
  }

  &--operation_situation_chart {
    width: 100%;
    height: 715px;
    background-color: #ffffff;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 64px;
    padding: 40px 109px 40px 40px;
    box-shadow: 0 0 8px #383f524d;
    border-radius: 6px;
  }

  &--operation_situation_ranking_area {
    width: 32%;
    height: 635px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }

  &--ranking_title {
    width: 100%;
    height: 24px;
    font: normal normal bold 14px Noto Sans CJK JP;
    color: #6a6f7c;
    text-align: left;
  }

  &--chart {
    width: 100%;
    height: 603px;
    // display: flex;
    // flex-flow: column;
    // justify-content: flex-start;
    // align-items: center;
    overflow-y: auto;
    display: inline-block;
    border: 1px solid #a0aab8;
  }

  &--chart_headline {
    width: 100%;
    height: 36px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    // padding-right: 36px;
    background-color: #646464;
    color: #ffffff;
    // border: 1px solid #a0aab8;
    font: normal normal bold 14px Noto Sans CJK JP;
    text-align: center;
  }

  &--ranking {
    width: 100%;
    height: 50px;
    // border-right: 1px solid #a0aab8;
    // border-left: 1px solid #a0aab8;
    // border-bottom: 1px solid #a0aab8;
    overflow-y: auto;
    // padding: 20px 36px 87px 28px;
    display: flex;
    // flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    text-align: center;

    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: #ffffff;
      border-radius: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #bcbcbc;
      border-radius: 5px;
    }
  }

  &--ranking_data {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #242424;
  }

  &--operation_situation_graph {
    width: 68%;
    height: 604px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
  }

  &--operation_situation_graph_title_area {
    width: 100%;
    height: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    color: #242424;
  }

  &--operation_situation_graph_label {
    width: 100%;
    height: 24px;
    text-align: left;
    font-size: 14px;
    color: #6a6f7c;
    margin-top: 23px;
  }

  &--operation_situation_graph_area {
    width: 100%;
    height: 100%;
  }

  &--square {
    width: 40px;
    height: 20px;
    background-color: #87acc2;
    border-radius: 3px;
  }

  &--text_display_quote_and_profit {
    width: 100%;
    height: 96px;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0 0 8px #383f524d;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 34px 25px 33px 64px;
  }

  &--headline_and_item {
    width: 100%;
    height: 29px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
  }

  &--quote_item {
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    color: #242424;
    flex-wrap: wrap;
  }

  &--text_display_profit {
    width: 100%;
    height: 96px;
    border-radius: 6px;
    box-shadow: 0 0 8px #383f524d;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
  }

  &--profit_item {
    width: 100%;
    height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 24px;
    background-color: #ffffff;
    flex-wrap: wrap;
    padding: 10px 0;
  }

  &--enrolled_graph_area {
    width: 60%;
    height: 268px;
  }

  &--enrolled_graph_label {
    width: 40%;
    height: 268px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
  }

  &--enrolled_graph_label_title {
    width: 100%;
    height: 29px;
    font: normal normal bold 16px Noto Sans CJK JP;
    color: #6a6f7c;
    margin-bottom: 0;
  }

  &--maker_and_label {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
  }

  &--maker {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    border-radius: 3px;
  }

  &--pie_label {
    width: 100%;
    height: auto;
    color: #242424;
    font-size: 14px;
    padding-right: 5px;
  }
  &--footer {
    display: flex;
    float: left;
    width: 100%;
    justify-content: center;
  }
  &--copy_right {
    width: 80%;
    padding-left: 15%;
    line-height: 30px;
    font-size: 14px;
    color: #383f52;
    letter-spacing: 0;
    text-align: center;
  }
  &--tag {
    width: 14.5%;
    justify-content: right;
    text-align: right;
    height: auto;
    font-size: 14px;
    color: #7b7b7b;
    padding-right: 0.5%;
  }

  @media screen and (max-width: 800px) {
    width: auto;
    height: auto;
    margin: 80px 20px 111.5px 20px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    gap: 64px;

    &--matter_report_situation_area {
      width: 100%;
      height: auto;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: center;
      flex-shrink: 0;
      gap: 24px;
    }

    &--title_bar {
      width: 100%;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding: 10px 0px 9px 15px;
      background-color: #646464;
      border-radius: 6px;
      color: #ffffff;
    }

    &--title {
      width: auto;
      height: 29px;
      display: flex;
      justify-content: center;
      align-items: center;
      font: normal normal bold 17px Noto Sans CJK JP;
    }

    &--date {
      width: 65%;
      height: 29px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font: normal normal normal 15px Noto Sans CJK JP;
    }

    &--text_display_area {
      width: 100%;
      height: auto;
      display: contents;
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
      grid-template-rows: 1fr 1fr;
      gap: 24px 40px;
    }

    &--on_click_area {
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }

      &:active {
        opacity: 0.5;
      }
    }

    &--text_display {
      width: 100%;
      height: 96px;
      background-color: #ffffff;
      box-shadow: 0 0 8px #383f524d;
      border-radius: 6px;
      padding: 0 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
    }

    &--headline {
      width: 80px;
      height: 29px;
      font: normal normal bold 20px Noto Sans CJK JP;
      color: #6a6f7c;
      white-space: nowrap;
    }

    &--item {
      width: auto;
      min-width: 105px;
      height: auto;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: center;
      flex-shrink: 0;
    }

    &--report {
      width: 100%;
      height: auto;
      font-size: 14px;
      color: #242424;
      word-break: break-all;
      display: flex;
      flex-wrap: wrap;
    }

    &--alert {
      width: 100%;
      height: 24px;
      font-size: 14px;
      color: #d80245;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      gap: 4px;

      img {
        width: 21px;
        height: 21px;
      }
    }

    &--assign_situation_area {
      width: 100%;
      height: auto;
      min-height: 515px;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: center;
      flex-shrink: 0;
      gap: 24px;
    }

    &--calender {
      width: 100%;
      height: auto;
      min-height: 443px;
      display: inline-block;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      box-shadow: 0 0 8px #383f524d;
      border-radius: 6px;
      text-align: center;
    }

    &--calender_items_area {
      padding: 17px;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: center;
      gap: 15px;
    }

    &--calender_item_header {
      width: 100%;
      font: normal normal bold 16px Noto Sans CJK JP;
      color: #6a6f7c;
      word-break: break-word;
    }

    &--calender_item {
      width: 60%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &--graph_area {
      width: 100%;
      height: 622px;
      background-color: #ffffff;
      border-radius: 6px;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 20px 24px;
      gap: 26px;
    }

    &--graph_bar {
      width: 100%;
      height: 24px;
      display: grid;
      justify-content: space-between;
      align-items: center;
      gap: 5px;
    }

    &--graph_title {
      width: 296px;
      font-size: 12px;
      color: #a0a0a0;
      text-align: start;
      top: 20px;
      position: relative;
    }

    &--graph_date {
      font: normal normal bold 14px Noto Sans CJK JP;
      color: #6a6f7c;
      text-align: center;
    }

    &--graph_mark {
      width: 296px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 24px;
    }

    &--matter_mark_area {
      width: 128px;
      height: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
    }

    &--matter_mark {
      width: 40px;
      height: 20px;
      background-color: #87c2a5;
      border-radius: 3px;
    }

    &--matter_text,
    &--assign_text {
      font-size: 14px;
      color: #6a6f7c;
    }

    &--assign_mark_area {
      width: 144px;
      height: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
    }

    &--assign_mark_unity {
      width: 40px;
      height: 20px;
      position: relative;
    }

    &--assign_circle {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #c87e7e;
      position: absolute;
      bottom: 4px;
      right: 14px;
    }

    &--assign_line {
      width: 40px;
      height: 3px;
      background-color: #c87e7e;
      position: absolute;
      bottom: 8.6px;
    }

    &--graph {
      width: 100%;
      height: 100%;
      padding-top: 50px;
      overflow-x: scroll;
    }

    &--quote_and_profit_area {
      width: 100%;
      height: 168px;
      display: contents;
      grid-template:
        'quote profit profit profit' 1fr
        / 1fr 1fr 1fr 1fr;
      gap: 40px;
    }

    &--quote {
      grid-area: quote;
      width: 100%;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: center;
      gap: 24px;
    }

    &--profit {
      grid-area: profit;
      width: 100%;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: center;
      gap: 24px;
    }

    &--enrolled_staff_area {
      width: 100%;
      height: auto;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: center;
      gap: 24px;
    }

    &--enrolled_contents_area {
      width: 100%;
      height: auto;
      display: contents;
      grid-template-columns: repeat(auto-fit, minmax(368px, 1fr));
      grid-template-rows: 1fr;
      gap: 8px;
    }

    &--enrolled_graph {
      width: 100%;
      // height: auto;
      background-color: #ffffff;
      border-radius: 6px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &--operation_situation_area {
      width: 100%;
      height: auto;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: center;
      gap: 24px;
    }

    &--operation_situation_chart {
      width: 100%;
      height: auto;
      background-color: #ffffff;
      display: inline;
      justify-content: flex-start;
      align-items: flex-end;
      gap: 64px;
      padding: 20px;
      box-shadow: 0 0 8px #383f524d;
      border-radius: 6px;
      padding-bottom: 44px;
    }

    &--operation_situation_ranking_area {
      width: 100%;
      height: 635px;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
    }

    &--ranking_title {
      width: 100%;
      height: 24px;
      font: normal normal bold 14px Noto Sans CJK JP;
      color: #6a6f7c;
      text-align: left;
    }

    &--chart {
      width: 100%;
      height: 603px;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: center;
      overflow-y: auto;
      overflow-x: scroll;
      display: inline-block;
      // border: 1px solid #a0aab8;
    }

    &--chart_headline {
      width: calc(100% + 100px);
      height: 36px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      // padding-right: 36px;
      background-color: #646464;
      color: #ffffff;
      border: 1px solid #a0aab8;
      font: normal normal bold 14px Noto Sans CJK JP;
      text-align: center;
    }

    &--ranking {
      width: calc(100% + 100px);
      height: 50px;
      // border-right: 1px solid #a0aab8;
      // border-left: 1px solid #a0aab8;
      // border-bottom: 1px solid #a0aab8;
      overflow-y: auto;
      // padding: 20px 36px 87px 28px;
      display: flex;
      // flex-flow: column;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
      text-align: center;

      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-track {
        background-color: #ffffff;
        border-radius: 6px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #bcbcbc;
        box-shadow: #2424244d 0 0 8px;
        border-radius: 5px;
      }
    }

    &--ranking_data {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: #242424;
    }

    &--operation_situation_graph {
      width: 100%;
      height: 604px;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;
      padding-top: 34px;
    }

    &--operation_situation_graph_title_area {
      width: 100%;
      height: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      color: #242424;
    }

    &--operation_situation_graph_label {
      width: 100%;
      height: 24px;
      text-align: left;
      font-size: 14px;
      color: #6a6f7c;
      margin-top: 23px;
    }

    &--operation_situation_graph_area {
      width: 100%;
      height: 100%;
      overflow-x: scroll;
    }

    &--square {
      width: 40px;
      height: 20px;
      background-color: #87acc2;
      border-radius: 3px;
    }

    &--text_display_quote_and_profit {
      width: 100%;
      height: 96px;
      border-radius: 6px;
      background-color: #ffffff;
      box-shadow: 0 0 8px #383f524d;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 34px 25px 33px 64px;
    }

    &--headline_and_item {
      width: 100%;
      height: 29px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 40px;
    }

    &--quote_item {
      width: auto;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      font-size: 16px;
      color: #242424;
      flex-wrap: wrap;
    }

    &--text_display_profit {
      width: 100%;
      height: 96px;
      border-radius: 6px;
      box-shadow: 0 0 8px #383f524d;
      display: inline-table;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr;
    }

    &--profit_item {
      width: 100%;
      height: 96px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 24px;
      background-color: #ffffff;
      flex-wrap: wrap;
      padding: 10px 20px;
    }

    &--enrolled_graph_area {
      width: 55%;
      height: auto;
    }

    &--enrolled_graph_label {
      width: 45%;
      height: auto;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;
    }

    &--enrolled_graph_label_title {
      width: 100%;
      height: 29px;
      font: normal normal bold 16px Noto Sans CJK JP;
      color: #6a6f7c;
      margin-bottom: 0;
    }

    &--maker_and_label {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 8px;
    }

    &--maker {
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      border-radius: 3px;
    }

    &--pie_label {
      width: 100%;
      height: auto;
      color: #242424;
      font-size: 14px;
      padding-right: 5px;
    }
    &--footer {
      display: flex;
      float: left;
      width: 100%;
      justify-content: center;
    }
    &--copy_right {
      width: 100%;
      padding-left: 0;
      line-height: 30px;
      font-size: 14px;
      color: #383f52;
      letter-spacing: 0;
      text-align: center;
    }
    &--tag {
      width: 100%;
      justify-content: center;
      text-align: center;
      height: auto;
      font-size: 14px;
      color: #7b7b7b;
      margin-bottom: 0;
    }
  }
}
