@import '../../styles/normalize';
@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.change_pass {
  &--background {
    position: relative;
    height: 100vh;
    width: 100%;
    background-color: #E9F2FC;
    text-align: center;

    .copyright--contents {
      width: 100%;
      left: 0;

      @media screen and (max-width: 800px) {
        position: absolute;
      }
    }
  }

  &--session_expired_area {
    width: 416px;
    height: 105px;
    margin: auto;
    position: absolute;
    top: calc((100vh - 105px) / 2);
    right: 0;
    left: 0;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    gap: 26px;
  }

  &--title_mark_area {
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 3px;

    span {
      font: normal normal bold 24px Noto Sans CJK JP;
      color: #242424;
    }
  }

  &--text_area {
    width: 100%;
    height: 44px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;

    span {
      font: normal normal normal 14px Noto Sans CJK JP;
      color: #242424;
    }
  }
}