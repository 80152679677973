@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap');

@each $key, $val in $text-align-map {
  .#{$key} {
    text-align: #{$val};
  }
}

* {
  font-family: "Noto Sans JP", sans-serif !important;
  overscroll-behavior-y: none;
}

html{
  height: -webkit-fill-available;
}
body{
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

button {
  border: none;
  background-color: transparent;
  outline: none;
}

input {
  box-sizing: border-box;
}

.commonPage {
  &--base {
    width: 100%;
    padding-top: 40px;
    padding-bottom: 140px;
    z-index: 1;
    position: relative;

    @include sp() {
      z-index: auto;
    }
  }
}

.page_base {
  &--container {
    width: 85%;
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    gap: 40px;
    padding-top: 40px;
    margin: 0 auto;
    &_notice {
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      color: #d80245;
      position: absolute;
      top: 8px;
    }
  }
  &--tabs {
    background-color: #ffffff;
    box-shadow: 0 0 8px #383f524d;
    border-radius: 6px;
    padding: 40px 40px;
    @media screen and (max-width: 800px) {
      padding: 40px 20px;
    }
  }
}

.display-linebreak {
  white-space: pre-wrap;
}

.bottom_btn_area {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  background-color: rgba(106, 111, 124, 0.6);
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  z-index: 30;
  transition: width .2s;

  &--common_actions {
    height: 92px !important;
    width: 520px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 35px;
    @media screen and (max-width: 800px) {
      align-items: center;
      display: flex;
      gap: 23px;
      justify-content: space-between;
      padding-left: 16px;
      padding-right: 16px;
      width: 100%;
    }
  }

  @include sp() {
    button {
      width: 100% !important;
      height: 38px !important;
      overflow: hidden;

      span {
        height: 40px !important;
        line-height: 40px !important;
      }
    }
  }
}

.common_modal_title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  border-bottom: 1px solid $grey;
  padding-bottom: 5px;
  @media screen and (max-width: 800px) {
    text-align: center;
  }
}
.common_modal_base {
  .ant-modal-body {
    padding: 24px 0 0 0;
  }
  .add_btn {
    background-color: #0056d3;
    height: 44px;
  }
  .add_btn.add_btn_disabled {
    background-color: #bcbcbc;
  }
  .common_modal_title {
    margin: 24px 24px 20px;
  }
  @media screen and (max-width: 800px) {
    min-width: unset !important;
  }
}
.common_modal_content {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden !important;
  .common_search_area {
    margin: 0 24px;
  }
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: $dark-grey;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-track {
    background: lighten($light-grey, 17%);
    border-radius: 5px;
  }
}
.common_modal_actions {
  display: flex;
  justify-content: center;
  margin: 40px 0 20px;
  gap: 20px;
}

.ant-picker-disabled {
  pointer-events: none;
}

.ant-picker {
  border: 0.5px solid #2b3245;
  border-radius: 6px;
  font-size: 20px;
}

.ant-picker:hover {
  border: 0.5px solid #2b3245;
  border-radius: 6px;
  font-size: 20px;
}

.ant-picker-focused,
.ant-picker-focused:hover {
  box-shadow: 0px 0px 8px #003b9429;
  border: 1px solid #003b94;
  border-radius: 6px;
}

.table_area {
  table {
    border-collapse: collapse;
  }
  background-color: #e9f2fc;
  padding: 30px 0;
  .pager--wrapper {
    margin-top: 30px;
    justify-content: center;
  }
  .ant-table-wrapper {
    margin: 0 30px;
  }
  .ant-table-thead > tr > th {
    background-color: #6a6f7c;
    color: #ffffff;
    font-weight: bold;
    border-right: 1px solid grey;
    white-space: pre-wrap;
  }
  .ant-table-tbody > tr {
    &:nth-child(even) {
      background-color: #f8f8f8;
    }
    & > td:not(:last-child) {
      border-right: 1px dashed #f0f0f0;
    }
    & > td:last-child {
      border-right: 1px solid #f0f0f0;
    }
  }
}

.input_union {
  height: auto;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  &--checkbox {
    display: flex;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    & > label {
      color: #646464;
      min-width: 70px;
    }
  }
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #ffffff;
  opacity: 0.6;
}

.ant-select-item-option-content {
  color: #242424;
  @media screen and (max-width: 800px) {
    font-size: 12px;
  }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-family: Noto Sans CJK JP;
  background-color: #ffffff;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #2b3245;
  border-right-width: 0.5px;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: #003b94;
  box-shadow: 0 0 8px #003b9429;
  border-right-width: 1px;
  outline: 0;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  color: #bcbcbc;
  background: #ffffff;
  border: 0.5px solid #bcbcbc;
  cursor: auto;

  @media screen and (max-width: 800px) {
    border: 0.5pt solid #bcbcbc;
  }
}

.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background: #ffffff;
}
.ant-notification {
  margin-right: 10%;
  overflow-y: auto;
  max-height: 70vh;
  max-width: 462px;
  width: 462px;
  animation-name: fadeUpAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
  inset: auto 10px 92px auto !important;
}
.ant-notification-notice {
  width: 100%;
}
.error_notification {
  border: 1px solid #d80245;
  border-radius: 6px;
  background: #fdf2f5 0% 0% no-repeat padding-box;
  padding: 8px 24px;
  margin-bottom: 7px;
  box-shadow: none;

  &:hover {
    cursor: pointer;
  }
  .ant-notification-notice-message {
    color: #d80245;
    font-size: 14px;
    padding: 0;
    line-height: 24px;
    margin-bottom: 0;
    white-space: pre-wrap;
  }
  .noterror_notification_close_icon {
    height: 28px;
    width: 28px;
    bottom: -18px;
    right: -16px;
    position: absolute;
  }
}
.ant-notification.ant-notification-bottomRight {
  bottom: 100px !important;
  right: 100px !important;
  @media screen and (max-width: 800px) {
    right: 0px !important;
  }
}

.fadeUp {
  animation-name: fadeUpAnime;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  opacity: 0;
}
@keyframes fadeUpAnime {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.tel_area_for_CFI {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .custom_input--input_area {
    gap: 0;

    .custom_input--unit {
      width: 18.86px;
    }
  }
}
.ant-picker-status-error.ant-picker,
.ant-picker-status-error.ant-picker:not([disabled]):hover {
  background-color: #fff;
  box-shadow: 0 0 8px #d8024529;
  border: 1px solid #d80245;
}

.ant-radio-group .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #28d300;
  background-color: #28d300;
}

.ant-radio-inner::after {
  background-color: #ffffff !important;
}

@include sp() {
  .ant-notification {
    margin-right: 2.5%;
    max-height: 50vh;
    max-width: 300px;
    width: 300px;

    .ant-notification-notice-message {
      font-size: 12px;
    }
  }
}

.ant-tabs-tab.ant-tabs-tab-active,
.ant-tabs-tab-btn {
  color: #646464 !important;
  font-size: 16px !important;
  text-shadow: none !important;
}

.ant-picker-now {
  display: none !important;
}

.ant-select-item-option-content {
  padding: 4px 0;
}
.ant-modal-content {
  border-radius: 6px;
}

