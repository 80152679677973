@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

// .corporate_register--able--menu--tree {
//   display: flex;
//   justify-content: space-evenly;
//   .ant-tree-switcher_open {
//     // display: none;
//   }
//   .ant-tree-checkbox-checked {
//     .ant-tree-checkbox-inner {
//       background-color: #28d300;
//       border-color: #28d300;
//     }
//   }
// }
.corporate_register {
  .register_base--basic_info_image_area {
    img {
      background-color: #f3f3f3;
    }
  }
}
.corporate_register--corporate--info {
  &--input {
    margin-bottom: 24px;
  }
  &--ntanumber {
    display: flex;
    align-items: flex-end;
    gap: 24px;
    margin: 10px 0 -10px;
    @media screen and (max-width: 800px) {
      margin: 8px 0 8px;

    }
  }
  &--corporate_seal {
    width: 200px;
    height: 200px;
    background-color: #f3f3f3;
    display: flex;
    justify-content: center;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    &:active {
      opacity: 0.6;
    }
    &--area {
      position: absolute;
      top: 0;
      right: 49px;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media screen and (max-width: 800px) {
        right: 0;
        position: relative;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        margin: 24px 0;
      }
    }
  }
  &--corporate_stamp {
    width: 200px;
    height: 200px;
  }
}

.corporate_register--corporate--sign {
  float: right;
}

.corporate_register {
  &--submit_buttons {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    @media screen and (max-width: 800px) {
      padding: 24px 16px;
      gap: 23px;
    }
  }
}

@media screen and (max-width: 800px) {
  .corporate_register {
    .no_display_input_field {
      width: 100%;
      height: 18px;
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;

      .custom_input--label {
        width: auto;
        height: 18px;

        label {
          line-height: 18px;
        }
      }

      .custom_input--input_area {
        width: 100%;
        height: 18px;

        input {
          width: 100%;
          height: 18px;
          padding: 0;
        }
      }
    }
  }
}