@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.custom_list_view {
  &--first_td {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;

    &_contents {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 6px;
      font-size: 14px;
      color: #242424;
      height: 14px;
    }
  }

  &--td_contents,
  &--td_center_contents,
  &--td_flex_end_contents {
    width: 100%;
    height: 100%;
    font-size: 14px;
    color: #242424;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
  }

  &--td_center_contents {
    color: #ffffff;
  }

  &--buttons {
    &_base {
      height: 75px;
      display: flex;
      justify-content: space-between;
      padding: 0 0;
      align-items: center;
      position: relative;
      background-color: #e9f2fc;

      @media screen and (max-width: 800px) {
        height: fit-content !important;
        width: 100% !important;
        flex-flow: column;
        align-items: flex-start !important;
        padding: 24px 0 32px !important;
        gap: 24px;
        z-index: 1;
      }
    }

    &_left {
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 24px;
      @media screen and (max-width: 800px) {
        width: 100%;
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 24px;
      }
    }
    &_right {
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 16px;
      @media screen and (max-width: 800px) {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 16px;
      }
    }
  }

  &--list {
    &_wrapper {
      position: relative;
      width: calc(100% - 80px);
      height: 100%;
      margin: 0 auto;
      z-index: 1;

      @media screen and (max-width: 800px) {
        width: 100% !important;
        height: fit-content !important;
        margin: 0 0 !important;
        overflow-x: scroll;
      }
    }
  }
}

.custom_list_view--list_table {
  thead {
    tr {
      pointer-events: none;
      border-bottom: none;
    }
  }
}
