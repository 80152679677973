@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.myPageSp {
  &--wrap {
    width: 100%;
    padding: 40px 4% 200px;
  }

  &--static_area {
    width: 100%;
    //height: 60px;
    margin-top: 8px;
    padding: 0 5%;

    &_notice {
      display: inline-block;
      width: 100%;
      color: #d80245;
      text-align: left;
      font-size: 13px;
    }

    &_step {
      width: 100%;
      height: 36px;
      margin-top: 8px;
      position: relative;

      &_number {
        display: inline-block;
        width: 16px;
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        top: 16px;
        position: absolute;
        text-align: center;
      }
    }
  }

  &--basic {
    width: 100%;
    margin-top: 32px;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;

    p {
      width: 100%;
      margin: 0;
      font-size: 12px;

      span {
        color: #646464;
      }
    }

    &_contents {
      width: 100%;
      display: flex;
      flex-flow: column;
      gap: 16px;
    }
  }

  &--info {
    &_base {
      width: 100%;
      background-color: #ffffff;
      border-radius: 12px;
      box-shadow: 0 0 8px #383f523d;
      padding: 32px 16px;
      margin-top: 32px;
      display: flex;
      flex-flow: column;
      gap: 24px;
      justify-content: space-between;

      .info_label {
        color: #646464;
        display: block;
        font-size: 12px;
        height: 12px;
        line-height: 12px;
        margin-bottom: 8px;
      }
    }

    &_title {
      width: 100%;

      p {
        height: 24px;
        width: 100%;
        font-size: 16px;
        font-weight: bold;
        color: #646464;
        margin: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;

        span {
          width: 4px;
          height: 100%;
          display: inline-block;
          background-color: #0056d3;
        }
      }
    }
  }

  &--access {
    &_box {
      width: 100%;
      height: 424px;
      border: 1px solid #a0aab8;
      position: relative;
    }

    &_delete_btn {
      position: absolute;
      width: 32px;
      height: 32px;
      top: 8px;
      right: 8px;

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
      }
    }
  }

  &--bottom_float {
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: 88px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    background-color: #6a6f7c60;
  }
}

.myPage_forSp {
  .custom_input--area,
  .custom_input--select_box_area,
  .custom_select--area .ant-select .ant-select-selector {
    display: flex !important;
    height: 40px !important;
  }

  .custom_select--area:not(.custom_select--area_multiple)
    .ant-select
    .ant-select-selector
    .ant-select-selection-search
    input,
  .custom_select--area .ant-select,
  .custom_select--area {
    height: 40px !important;
  }

  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    font-size: 12px !important;
    padding-top: 4px !important;
  }

  .add_button--area {
    text-align: left !important;
    margin-top: -16px;

    .add_button--round {
      width: 34px !important;
      height: 34px !important;
      margin: 0 !important;
    }
  }

  .custom_select--area_multiple {
    .ant-select-selection-placeholder {
      padding-top: 0 !important;
    }
    .ant-select-selection-item {
      padding-top: 0 !important;
    }
    .ant-select-selection-item-remove {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .page2,
  .page3 {
    .custom_input--select_box_unit {
      width: 24px;
      margin-left: 8px;
    }

    .custom_input--input_area {
      display: flex;
      gap: 8px;
      align-items: center;

      .custom_input--unit {
        width: 24px;
      }
    }

    .custom_input--area_column_not_error {
      .custom_input--input_area {
        #bankCode, #bankBranchCode {
          color: #bcbcbc;
          border: 0.5pt solid #bcbcbc;
        }
      }
    }
  }

  label {
    white-space: nowrap;
  }
}
