@import '../../styles/normalize';
@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.list_view {
  &--search {
    &_base {
      position: relative;
      width: 100%;
      background-color: #ffffff;
      padding: 20px 40px 40px;
      transition: all 0.5s;
      transform-origin: top;
      max-height: 1080px;

      @media screen and (max-width: 800px) {
        padding: 32px 4% !important;
        z-index: 1;
        max-height: none;
      }
      &_with_tip {
        padding-bottom: 70px;
      }
    }

    &_title_area {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 20px;
      gap: 8px;
      margin-bottom: 16px;

      img {
        height: 16px;
      }

      p {
        height: 16px;
        line-height: 16px;
        margin: 0 0;
      }

      @media screen and (max-width: 800px) {
        margin-left: 5px;

        p {
          font-size: 16px;
          height: 16px;
          line-height: 16px;
          margin: 0 0;
          font-weight: bold;
        }
      }
    }

    &_form_area {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      gap: 24px;
      transition: all 0.5s;
      transform-origin: top;
    }

    &_button {
      &_area {
        width: 100%;
        height: 44px;
        margin-top: 40px;
        text-align: center;
        transition: all 0.5s;
        transform-origin: top;

        @media screen and (max-width: 800px) {
          height: 40px;
          font-size: 12px;
          margin-top: 32px;
        }
      }
    }
  }
  &--search_title_tip {
    color: #d80245;
    margin-bottom: 10px;
  }

  &--buttons {
    &_base {
      height: 75px;
      display: flex;
      justify-content: space-between;
      padding: 0 40px;
      align-items: center;
      position: relative;
      background-color: #e9f2fc;

      @media screen and (max-width: 800px) {
        height: fit-content !important;
        width: 100% !important;
        flex-flow: column;
        align-items: flex-start !important;
        padding: 24px 4% 32px !important;
        gap: 24px;
        z-index: 1;

        .list_view--count_forSp {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 8px;
        }

        .list_view--pagerWrap_forSp {
          width: 100%;
          text-align: center;
        }

        .list_view--count_page {
          width: 100%;
          text-align: center;
        }
      }
    }

    &_left {
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 24px;
    }

    &_right {
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 16px;
    }
  }

  &--count {
    &_area {
      display: flex;
      justify-content: flex-start;
      align-items: end;
      height: calc(100% - 32px);
      gap: 16px;
    }

    &_search {
      margin: 0 0;
      font-size: 14px;
      line-height: 14px;
      color: #7b7b7b;
    }

    &_page {
      margin: 0 0;
      font-size: 14px;
      line-height: 14px;
      color: #7b7b7b;
    }

    &_select {
      height: calc(100% - 30px);
      display: flex;
      justify-content: flex-start;
      align-items: end;
      gap: 8px;
      margin: 0 16px 0 0;
      font-size: 14px;
      color: #242424;

      .custom_input--select_box_area {
        width: 120px;
      }

      //select {
      //  //width: 120px;
      //  height: 44px;
      //  border: .5px solid #2B3245;
      //  border-radius: 6px;
      //  padding: 10px 16px;
      //}

      p {
        margin: 0 0;
        font-size: 14px;
        color: #242424;
      }
    }
  }

  &--list {
    &_wrapper {
      position: relative;
      width: calc(100% - 80px);
      height: 100%;
      margin: 0 auto;
      z-index: 1;
      //overflow-x: scroll;

      @media screen and (max-width: 800px) {
        width: 92% !important;
        height: fit-content !important;
        margin: 0 4% !important;
        overflow-x: scroll;
      }
    }

    &_table {
      width: 100%;
      margin: 0 0;
      border-spacing: 0;
      border-collapse: collapse;

      thead {
        width: 100%;
        background-color: #6a6f7c;
      }

      tbody {
        background-color: #ffffff;
      }

      th {
        height: 36px;
        line-height: 36px;
        text-align: left;
        color: #ffffff;
        cursor: pointer;
        white-space: nowrap;
        padding: 0 15px;
        user-select: none;
        position: relative;
        border-right: 1px solid #e0e0e0;

        .list_view--sort_icon {
          width: 18px;
          display: inline-block;
          padding-left: 4px;
          vertical-align: center;

          img {
            width: 14px;
          }
        }

        &:hover {
          opacity: 0.6;
        }
      }

      tr {
        border-bottom: solid 2px #f3f6f9;
      }

      td {
        height: 90px;
        //line-height: 90px;
        text-align: left;
        padding: 0 15px;
        vertical-align: middle;

        @media screen and (max-width: 800px) {
          height: 80px !important;
          // line-height: 80px !important;
        }

        img {
          width: 32px;
          height: 32px;
          vertical-align: middle;
        }
      }

      @media screen and (max-width: 800px) {
        white-space: nowrap;
      }
    }
  }

  &--static_th {
    text-align: center !important;
    padding: 0 6px !important;
    width: 50px !important;
    cursor: unset !important;

    &:hover {
      opacity: 1 !important;
    }
  }

  &--bottom_pager_wrapper {
    position: relative;
    width: 100%;
    padding-top: 40px;
    //z-index: 1;
    background-color: #e9f2fc;
  }

  &--empty_text {
    margin-left: 40px;
  }
}

#searchFormTriangle {
  cursor: pointer;
}

.addBtn_listView_sp {
  width: 100%;
  height: 40px;
  background-color: #ffffff;
  border: 1px solid #2b3245;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    color: #2b3245;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      display: inline-block;
      width: 32px;
      height: 32px;
      background-image: url(../../images/icon/icon_plus_nv.svg);
      background-size: 100%;
      background-position: center center;
    }
  }

  &:active {
    background-color: #0056d3;
    border: none;

    p {
      color: #ffffff;

      span {
        background-image: url(../../images/icon/icon_plus_wh.svg);
      }
    }
  }
}
.list_view_table_th {
  cursor: unset !important;
  &:hover {
    opacity: 1 !important;
  }
}
