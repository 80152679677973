@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.recruit_matter_detail {
  &--upper_area {
    width: 85%;
    height: auto;
    margin: 80px auto 0;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
  }
  &--content_area {
    width: 85%;
    background-color: #ffffff;
    padding: 16px 40px 16px;
    border-radius: 6px;
    margin: 15px auto 234px;
  }

  &--headline {
    width: 100%;
    height: auto;
    background-color: #ffffff;
    box-shadow: 0 0 8px #383f524d;
    border-radius: 6px;
    padding: 16px 40px 16px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }

  &--title {
    width: 100%;
    height: 43px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font: normal normal bold 24px/30px Noto Sans CJK JP;
    color: #242424;
    border-bottom: 1px solid #f3f3f3;

    @media screen and (max-width: 800px) {
      height: auto;
      font: normal normal bold 12px Noto Sans CJK JP;
      padding-bottom: 15px;
    }
  }

  &--contents {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;

    @media screen and (max-width: 800px) {
      gap: 12px;
    }

    label {
      width: 130px;
      font-size: 14px;
      color: #646464;

      @media screen and (max-width: 800px) {
        width: 75px;
        height: 18px;
        font-size: 12px;
      }
    }

    span {
      font-size: 14px;
      color: #242424;

      @media screen and (max-width: 800px) {
        font-size: 12px;
      }
    }
  }

  &--button_area {
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &--implementation_info_area {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
  }

  &--implementation_contents {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    white-space: pre-wrap;
    padding: 16px 0;
    border-bottom: 1px solid #f3f3f3;

    @media screen and (max-width: 800px) {
      padding: 8px 0;
    }

    label {
      font: normal normal bold 14px Noto Sans CJK JP;
      color: #646464;

      @media screen and (max-width: 800px) {
        font-size: 12px;
        color: #5C6C83;
      }
    }

    span {
      font-size: 14px;
      color: #242424;
    }
  }

  &--implementation_item {
    @media screen and (max-width: 800px) {
      font-size: 12px;
      color: #242424;
    }
  }

  &--attendance_report_area {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 40px;
    margin-top: 16px;
  }

  &--attendance_report_infos_area {
    width: 100%;
    height: auto;
    padding: 0 0 16px;
    border: 1px solid #a0aab8;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
  }

  &--attendance_report_infos_upper {
    width: 100%;
    height: 58px;
    padding: 0 16px 0;
    box-sizing: border-box;
    border-bottom: 1px solid #f3f3f3;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
  }

  &--attendance_report_infos_upper_item,
  &--attendance_report_infos_upper_info_item {
    height: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

    label {
      font-size: 14px;
      color: #646464;

      @media screen and (max-width: 800px) {
        font-size: 12px;
      }
    }

    span {
      font-size: 14px;
      color: #242424;

      @media screen and (max-width: 800px) {
        font-size: 12px;
      }
    }
  }

  &--attendance_report_schedule {
    padding: 0 16px;
    font: normal normal bold 14px Noto Sans CJK JP;
    color: #242424;

    @media screen and (max-width: 800px) {
      font-size: 12px;
    }
  }

  &--attendance_report_infos_upper_info_item {
    padding: 0 16px;
    @media screen and (max-width: 800px) {
      height: 18px;
    }
  }

  &--attendance_report_infos_upper_info_link {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 8px;
    padding-left: 32px;

    a {
      color: #7b7b7b;
      border-bottom: 1px solid #7b7b7b;

      @media screen and (max-width: 800px) {
        font-size: 12px;
      }
    }

    img {
      width: 20px;
      height: 20px;

      @media screen and (max-width: 800px) {
        width: 18px;
        height: 18px;
      }
    }
  }

  &--attendance_report_input_area {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 14px;
    .gw-btn {
      width: 260px;
      height: 44px;
    }
    .gw-btn.method_btn {
      &_0 {
        background-color: #bcbcbc;
        color: #ffffff;
      }
      &_1 {
        background-color: #ffffff;
        border: 1px solid #2b3245;
        color: #2b3245;
      }
      &_2 {
        background-color: #d80245;
        color: #ffffff;
      }
    }
    .ant-picker {
      width: 115px !important;
      @media screen and (max-width: 800px) {
        width: 140px !important;
      }
    }
  }

  &--attendance_report_input {
    width: 100%;
    height: 54px;
    display: flex;
    align-items: center;
    gap: 24px;
  }

  &--attendance_report_input_label {
    width: 200px;
    height: 54px;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    align-items: flex-end;
    margin-right: 8px;
    label {
      font-size: 14px;
      color: #646464;
      height: 24px;
    }
    span {
      font-size: 12px;
      color: #242424;
      height: 20px;
    }
  }

  &--input_time {
    .custom_input--input_area_ant_design {
      .ant-picker {
        width: 115px;
      }
    }
  }

  &--report_text {
    margin-top: 10px;
    height: 24px;
    font-size: 14px;
    color: #7b7b7b;

    @media screen and (max-width: 800px) {
      margin-top: 0;
    }
  }

  &--bottom_btn_area {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 92px;
    background-color: rgba(106, 111, 124, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: width .2s;

    .update-tip {
      color: #ffffff;
      margin-bottom: 15px;
    }
  }

  &--various_application_info_area {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    overflow-x: scroll;

    &::-webkit-scrollbar{
      display:none;
    }
    .remand_info {
      display: flex;
      flex-direction: column;
      & > span:nth-child(1) {
        background-color: #d80245;
        color: #ffffff;
        text-align: center;
        width: 80px;
        display: inline-block;
        margin: 10px 0;
        font-size: 14px;
        font-weight: bold;
      }
      & > span:nth-child(2) {
        color: #d80245;
        font-size: 14px;
      }
    }
    .payment_tab_content--item_list {
      margin-top: 0px;
    }
  }

  &--table_left_content {
    width: 100%;
    height: 100%;
    padding: 24px 10px 40px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
  }

  &--table_inputs {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
  }

  &--table_total_amount {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font: normal normal bold 14px Noto Sans CJK JP;
    color: #242424;
  }

  &--table_receipt {
    width: 100%;
    max-width: 470px;
    height: 100%;
    min-height: 380px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 8px 24px 24px;

    label {
      width: 240px;
      height: 44px;
      border-radius: 6px;
      background-color: #ffffff;
      color: #2b3245;
      border: 1px solid #2b3245;
      font-size: 14px;
      margin: 16px 0 0;
      padding: 0 0;
      user-select: none;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      gap: 4px;

      &:hover {
        opacity: 0.75;
      }

      &:active {
        opacity: 0.5;
      }
    }

    input[type='file'] {
      display: none;
    }
  }

  &--receipt_images {
    width: 100%;
    min-width: 276px;
    max-width: 422px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
  }

  &--receipt_info_area {
    width: 130px;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
  }

  &--receipt_image_area {
    width: 130px;
    height: 130px;
    background-color: #e9f2fc;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 130px;
      object-fit: contain;
      width: 100%;
    }
  }

  &--image_name {
    font-size: 14px;
    color: #bcbcbc;
    width: 100%;
    height: 22px;
    border-bottom: 1px solid #bcbcbc;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &--various_application_table {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
  }

  &--various_application_contents {
    &_left {
      width: 60%;

      &_title {
        width: 100%;
        height: 37px;
        padding-left: 24px;
        background-color: #6a6f7c;
        color: #ffffff;
        font: normal normal bold 14px Noto Sans CJK JP;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-right: 1px solid #e0e0e0;
        border-radius: 3px 0 0 0;
      }

      &_items {
        width: 100%;
        height: calc(100% - 37px);
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 24px;
        padding-top: 24px;
        padding-bottom: 48px;
        padding-right: 24px;
        border-right: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;
        border-left: 1px solid #e0e0e0;
        border-radius: 0 0 0 3px;
      }
    }

    &_center {
      width: 10%;
      min-width: 140px;
      border-bottom: 1px solid #e0e0e0;

      &_title {
        width: 100%;
        height: 37px;
        background-color: #6a6f7c;
        color: #ffffff;
        font: normal normal bold 14px Noto Sans CJK JP;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid #e0e0e0;
      }

      &_items {
        width: 100%;
        height: calc(100% - 37px);
        padding: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        font: normal normal bold 14px Noto Sans CJK JP;
        color: #242424;
        word-break: break-word;
        border-right: 1px solid #e0e0e0;
      }
    }

    &_right {
      width: 30%;
      max-width: 470px;

      &_title {
        width: 100%;
        height: 37px;
        padding-left: 24px;
        background-color: #6a6f7c;
        color: #ffffff;
        font: normal normal bold 14px Noto Sans CJK JP;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-right: 1px solid #6a6f7c;
        border-top: 1px solid #6a6f7c;
        border-bottom: 1px solid #6a6f7c;
        border-left: 1px solid #6a6f7c;
        border-radius: 0 3px 0 0;
      }

      &_items {
        width: 100%;
        height: calc(100% - 37px);
        border-right: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;
        border-radius: 0 0 3px 0;
        padding: 24px;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;

        label {
          width: 240px;
          height: 44px;
          border-radius: 6px;
          background-color: #ffffff;
          color: #2b3245;
          border: 1px solid #2b3245;
          font-size: 14px;
          padding: 0 0;
          user-select: none;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          gap: 4px;

          &:hover {
            opacity: 0.75;
          }

          &:active {
            opacity: 0.5;
          }
        }

        input[type='file'] {
          display: none;
        }
      }
    }
  }
}

.delete_image_button {
  left: 100px;
  top: 29px;
}
@media screen and (max-width: 800px) {
  .recruit_matter_detail {


    &--headline,
    &--content_area {
      padding: 16px 16px 32px;
    }
    &--bottom_btn_area {
      height: 100px;
    }
    &--attendance_report_infos_upper {
      flex-direction: column;
      align-items: flex-start;
      height: unset;
      gap: 10px;
      padding: 16px;
    }
    &--attendance_report_input {
      flex-direction: column;
      align-items: flex-start;
      height: unset;
      gap: 5px;

      .recruit_matter_detail--attendance_report_input_label {
        height: fit-content;
        label {
          color: #646464;
          font: normal normal bold 12px Noto Sans CJK JP;
        }
      }

      .recruit_matter_detail--attendance_report_input_field {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 16px;

        .custom_input--area_ant_design {
          .custom_input--input_area_ant_design {
            .ant-picker, .ant-picker-disabled {
              @media screen and (max-width: 800px) {
                width: 140px;
              }
            }
          }
        }
      }
    }
    &--attendance_report_input_custom {
      width: 100%;
      height: 40px;
      background-color: red;
    }
    &--attendance_report_input_label {
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      height: unset;
      gap: 0 30px;
    }
  }
}
