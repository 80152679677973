@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.staff_register {
  &--search_area_label {
    width: 119px;
    height: 44px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-shrink: 0;
    font-size: 14px;
    color: #646464;
    @media screen and (max-width: 800px) {
      height: 20px;
      justify-content: flex-start;
    }

    span {
      color: #d80245;
    }
  }

  &--search_area_name {
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    @media screen and (max-width: 800px) {
      flex-direction: column;
      align-items: flex-start;
      height: auto;
    }
  }

  &--disable {
    .custom_input {
      &--label {
        label {
          color: #bcbcbc;
        }
      }
      &--input_area {
        .custom_input--unit {
          color: #bcbcbc;
        }
      }
    }
  }

  &--gender {
    .custom_input--radio_button {
      .custom_input--radio_input_area {
        column-gap: 28px;
      }
    }
  }

  &--radio_unity {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
  }

  &--shift_settings {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    @media screen and (max-width: 800px) {
      flex-direction: column;
      gap: 16px;
    }
  }

  &--shift_settings_label {
    width: 232px;
    height: 24px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
    color: #646464;
    @media screen and (max-width: 800px) {
      justify-content: flex-start;
      font-size: 14px;
      height: 20px;
    }
  }

  &--shift_setting_items {
    width: calc(100% - 232px);
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    @media screen and (max-width: 800px) {
      gap: 20px;
      width: 100%;
    }
  }

  &--shift_setting_items_unity {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 80px;
    row-gap: 20px;
    @media screen and (max-width: 800px) {
      height: auto;
      gap: 20px;
      flex-direction: column;
      .custom_input--radio_button {
        flex-direction: row;
      }
    }
  }

  &--background_infos_area {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    margin-top: 26px;

    @media screen and (max-width: 800px) {
      margin-top: 8px;
    }

    .staff_register--input_note_area {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      @media screen and (max-width: 800px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .staff_register--note_message {
        font-size: 12px;
        color: #d80245;
      }
    }

    .custom_input—pick_up_select_box_area {
      margin-bottom: 16px;
    }

    .custom_input--area {
      .custom_input--unit {
        width: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
      }
    }

    .custom_input--radio_input_area {
      gap: 40px;
    }
  }

  &--body_infos_area {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    margin-top: 26px;

    @media screen and (max-width: 800px) {
      margin-top: 8px;
    }

    .custom_input--select_box_area {
      .custom_input--select_box_unit {
        width: 23px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
      }
    }

    .custom_input--area {
      .custom_input--unit {
        width: 23px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
      }
    }
  }

  &--access_infos_area {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    margin-top: 26px;

    @media screen and (max-width: 800px) {
      margin-top: 8px;
    }
  }

  &--basic_infos_area {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    margin-top: 26px;
    @media screen and (max-width: 800px) {
      margin-top: 8px;
    }

    .custom_input--radio_display_area {
      white-space: nowrap;
      @media screen and (max-width: 800px) {
        width: auto;
      }
    }
    .custom_input--radio_input_area {
      @media screen and (max-width: 800px) {
        column-gap: 24px;
        row-gap: 8px;
      }
    }
  }

  &--zip_code_area {
    width: 496px;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 7px;

    .staff_register--zip_code_unit {
      width: 6px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
    }
  }

  &--tel_area {
    .custom_input--select_box_unit {
      width: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
    }
  }

  &--unity_area {
    width: unquote('min(95%, 1052px)');
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
    @media screen and (max-width: 800px) {
      gap: 20px;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
    }

    .custom_input--area_ant_design {
      .ant-picker {
        width: 145px;
        @media screen and (max-width: 800px) {
          width: 165px;
        }
      }
    }

    .staff_register--input_unity {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      @media screen and (max-width: 800px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .ant-picker {
        width: 165px;
      }
    }
  }

  &--unity_area_daily_pay {
    width: unquote('min(95%, 1052px)');
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
    padding-left: 176px;
    @media screen and (max-width: 800px) {
      padding-left: 0;
      width: 100%;
      flex-direction: column;
      gap: 20px;
      align-items: flex-start;
    }

    .custom_input--unit {
      width: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
    }
  }

  &--recruit_cost {
    .custom_input--unit {
      width: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
    }
  }

  &--hope_assign {
    .custom_input--input_area {
      gap: 6px;

      .custom_input--unit {
        width: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
      }
    }
  }

  &--birthday_input_area {
    width: 679px;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
    @media screen and (max-width: 800px) {
      width: 100%;
      flex-direction: column;
      gap: 0px;
    }
    .custom_input--select_box_unit {
      width: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
    }

    .staff_register--label_area {
      width: 232px;
      height: 24px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-shrink: 0;
      @media screen and (max-width: 800px) {
        height: 20px;
        width: 100%;
        align-items: flex-start;
        justify-content: flex-start;
      }
      label {
        font-size: 14px;
        color: #646464;
        @media screen and (max-width: 800px) {
          font-size: 12px;
        }
      }
    }

    .birthday_selector--wrap {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
  &--mail_send_check {
    width: 220px;
    height: 36px;
    padding: 0 24px;
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 6px;
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    box-sizing: content-box;

    span {
      display: inline-block;
      font-size: 14px;
      line-height: 14px;
      height: 14px;
      color: #242424;
    }
  }

  &--submit_buttons {
    width: 520px;
    height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;

    @include sp() {
      width: 100vw;
      justify-content: center;
      gap: 23px;
      padding-right: 16px;
      padding-left: 16px;
    }
  }
}

.error_area {
  width: 100%;
  height: auto;
  position: fixed;
  bottom: 136px;
  padding: 0 10% 24px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-end;
  row-gap: 8px;

  .error_message {
    width: 24%;
    min-width: 350px;
    height: 48px;
    background-color: #fdf2f5;
    border: 1px solid #d80245;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0 16px 16px;
    color: #d80245;
    font-size: 14px;
  }
}

.my_page {
  &--user_infos_area {
    width: 85%;
    height: auto;
    margin: 0 auto;
    padding-top: 40px;
  }

  &--container_notice {
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    color: #d80245;
    position: absolute;
    top: 8px;
  }

  &--input_area {
    width: 70%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
  }

  &--staff_id_area {
    width: 100%;
    height: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
  }

  &--staff_id_label {
    font-size: 14px;
    color: #646464;
    width: 272px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    span {
      color: #d80245;
    }
  }

  &--staff_id {
    font-size: 14px;
    color: #242424;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &--bottom_btn_area {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: rgba(106, 111, 124, 0.6);
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    z-index: 30;
  }
}
