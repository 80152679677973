@import '../../styles/normalize';
@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.title_bar {
  &--base {
    width: 100%;
    height: 40px;
    background-color: #0056d3;
    position: fixed;
    display: flex;
    align-items: center;
    z-index: 5;
    gap: 40px;
    padding-left: 40px;

    @media screen and (max-width: 800px) {
      padding-left: 20px;
      justify-content: space-between;
    }
  }

  &--icon_title_area {
    height: 32px;
    display: flex;
    align-items: center;
    gap: 8px;

    img {
      height: 100%;
    }

    p {
      height: 20px;
      line-height: 20px;
      font-size: 20px;
      color: #ffffff;
      font-weight: bold;
      margin: 0 0;

      @media screen and (max-width: 800px) {
        white-space: nowrap;
      }
    }
  }

  &--counter_area {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;

    p {
      height: 14px;
      line-height: 14px;
      font-size: 14px;
      color: #ffffff;
      margin: 0 0;
    }

    @media screen and (max-width: 800px) {
      flex-flow: column;
      gap: 0;
      margin-right: 16px;
      min-width: 30%;

      p {
        height: 17px;
        font-size: 12px;
        line-height: 17px;
        width: 100%;
        text-align: left;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          display: inline-block;
        }
      }
    }
  }
}
