@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.quote_num_modal {
  &--header {
    margin-bottom: 6.3vh;
    p {
      margin: 0;
    }
  }
  &--main {
    margin-bottom: 6.7vh;
  }
  &--btndiv {
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (max-width: 800px) {
  .quote_num_modal {

    &--startest {
      .import_modal--title_area {
        height: 30px;
        justify-content: center;

        p {
          font: normal normal bold 20px Noto Sans CJK JP;
          color: #242424;
          line-height: 30px;
        }
      }
    }

    &--header {
      margin-top: 8px;
      margin-bottom: 24px;

      p {
        font-size: 12px;
        color: #242424;
      }
    }

    &--main {
      margin-bottom: 24px;
    }

    &--btndiv {
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 23px;
    }
  }
}