@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.staff_assign_list {
  .list_view--list_table td {
    line-height: inherit;
  }

  .list_view--list_table {
    .tr-excess {
      background-color: #fdf2f5 !important;
    }
  }
}
