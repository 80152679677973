@import '../../../../styles/normalize';
@import '../../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../../styles/mixin';
@import '../../../../styles/global';

.staff_matter_tab {
  & > div {
    &:nth-child(1) {
      display: flex;
      align-items: center;
      margin: 30px 0;
      & > label {
        width: 175px;
        text-align: center;
        color: #646464;
      }
      .gw-checkbox-wrapper {
        margin-right: 20px;
      }
    }
  }
  &--item {
    height: 36px;
    background-color: #6a6f7c;
    line-height: 36px;
    color: #ffffff;
    font-weight: bold;
    padding-left: 20px;
    margin-bottom: 25px;
  }
  &--item_list {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;

    @include sp() {
      align-items: flex-start;
      flex-flow: column;
    }
  }
  &--item_detail {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  &--item_custom {
    display: flex;
    align-items: center;
    gap: 28px;
    & > label {
      width: 175px;
      min-width: 175px;
      text-align: right;
      color: #646464;
      @include requiredItem();
    }
  }
  &--custom-input {
    display: flex;
    width: 100%;
    flex: 1;
    margin-bottom: 30px;
    & > label {
      width: 175px;
      text-align: right;
      margin-right: 23px;
      color: #646464;
    }

    @include sp() {
      flex-flow: column;
      gap: 16px;

      & > label {
        width: 100%;
        text-align: left;
        margin: 0;
        color: #646464;
        font-size: 12px;
      }
    }
  }
}
