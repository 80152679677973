@import '../../../../styles/normalize';
@import '../../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../../styles/mixin';
@import '../../../../styles/global';

.assign_state_list {

  &--search_area {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
  }

  &--matter_date_area {
    width: 336px;
    height: 76px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 8px;

    .assign_state_list--matter_date_unit {
      width: 16px;
      line-height: 44px;
      color: #242424;
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 800px) {
  .assign_state_list {

    &--matter_date_area {
      width: 100%;
      height: 66px;

      .matter_list--date_column {
        height: 66px;

      }
    }
  }

  .custom_list_view {
    td {
      height: 0 !important;
    }
  }
}