@import '../../../../styles/normalize';
@import '../../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../../styles/mixin';
@import '../../../../styles/global';

.pay_detail--table {
  margin-top: 26px;
  thead {
    tr {
      height: 36px;
      th:not(:last-child):not(:first-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        background-color: #e0e0e0;
        height: 100%;
      }
      th {
        padding: 0;
        text-align: center;
        background-color: #a0aab8;
        font: normal normal bold 12px/20px Noto Sans CJK JP;
        color: #ffffff;
      }
      th:nth-child(1) {
        background-color: #6a6f7c;
        border-bottom: 0;
      }
    }
  }
  tbody {
    tr:nth-child(1) {
      //勤務情報列
      td:nth-child(1) {
        background-color: #6a6f7c;
        padding: 0 10px;
        border-bottom: 1px solid #6a6f7c;
      }
    }

    tr {
      td:not([rowspan]):not([colspan]) {
        border-right: 1px solid #a0aab8;
        border-bottom: 1px solid #a0aab8;
        padding: 7px;
      }
    }

    tr:last-child {
      td:nth-child(1) {
        border-bottom: 0;
      }
      td:nth-child(2) {
        background-color: #6a6f7c;
        border-bottom: 1px solid #6a6f7c;
      }
    }
  }

  &--column {
    &--info {
      font: normal normal bold 12px/20px Noto Sans CJK JP;
      color: #ffffff;
      height: 116px;
    }
    &--subtotal {
      font: normal normal bold 12px/20px Noto Sans CJK JP;
      color: #ffffff;
      text-align: center;
    }
  }

  &--data {
    &--center {
      text-align: center;
    }
    &--right {
      text-align: right;
    }
  }
}
