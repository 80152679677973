@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.upload_modal {
  &--upload_area {
    background-color: $sky-blue;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0 50px;
    .gw-btn {
      height: 44px;
      width: 240px;
    }
    & > span {
      &:nth-child(1),
      &:nth-child(2) {
        font-size: 20px;
        font-weight: bold;
      }
      &:nth-child(3) {
        margin: 20px 0 0;
      }
      &:nth-child(n + 3) {
        font-size: 14px;
      }
    }
  }
  &--images {
    display: flex;
    margin-top: 20px;
    gap: 10px;
  }
}

.image_cell_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  & > div {
    font-size: 14px;
    color: #7b7b7b;
    text-decoration: underline;
  }
  .image_cell_name {
    @include maxRows(1);
    display: inline-block;
    max-width: 120px;
  }
}

.image_cell {
  width: 120px;
  height: 120px;
  position: relative;
  background-color: #e9f2fc;
  display: flex;
  & > img:not(.delete_icon) {
    width: 120px;
  }
  & > img.delete_icon {
    right: 1px;
  }
  .empty-image {
    width: 40px;
    height: 40px;
    align-self: center;
  }
}
