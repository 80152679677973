@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.implement_page {
  &--title {
    @include shadow-box();
    margin-top: 15px;
    display: flex;
    background: #ffffff;
    min-height: 88px;
  }
  &--title-left {
    min-height: 88px;
    height: 100%;
    display: flex;
    padding-left: 30px;
    flex-direction: column;
    justify-content: center;
    padding-right: 150px;
    border-right: 1px solid $grey;
    margin-right: 10px;
    span {
      &:nth-child(1) {
        font-size: 14px;
        color: #a0aab8;
      }
      &:nth-child(2) {
        font-weight: bold;
        font-size: 24px;
      }
    }
  }
  &--title-right {
    display: flex;
    align-items: center;
    & > div:not(.report_page--title-end) {
      display: flex;
      flex-direction: column;
      margin-right: 8px;
      span {
        &:nth-child(1) {
          margin: 0 20px 10px 0;
        }
      }
    }
  }
  &--title-end {
    display: flex;
    align-items: center;
    height: 100%;
    & > div {
      display: flex;
      flex-direction: column;
      span {
        margin-right: 5px;
      }
      &:nth-child(2) {
        span:nth-child(1) {
          width: 166px;
          height: 20px;
          background: #a0aab8;
          border-radius: 10px;
          text-align: center;
          color: #ffffff;
          font-size: 12px;
          font-weight: bold;
        }
        span:nth-child(2) {
          font-weight: bold;
          margin-top: 3px;
        }
      }
    }
    padding: 15px;
    border-left: 1px solid $grey;
  }
  @media screen and (max-width: 800px) {
    .payment_tab_content--item {
      width: 100%;
      flex-direction: column;
    }
    .custom_input--radio_input_area {
      column-gap: 24px;
    }
    .ant-descriptions-view {
      .ant-descriptions-row:last-child {
        .ant-descriptions-item-container {
          padding: 15px 5px;
          .payment_item--cost_images {
            gap: 15px 5px;
            justify-content: space-between;
          }
        }
      }
    }
    .custom_input--area_ant_design {
      max-width: 150px;
    }
    .payment_tab_content--item_list {
      margin-top: 20px;
    }
    .implementation_tab_content {
      gap: 10px;
    }
    .info_item {
      .tab_label {
        min-width: unset;
        position: absolute;
      }
      & > div:nth-child(2) {
        width: 100%;
        margin-left: 0;
        .gw-checkbox-label {
          margin-left: 100px;
        }
        textarea {
          width: 100%;
        }
      }
    }
    .traffic_info .custom_unit {
      margin-left: 0;
    }
    .report_item {
      width: 100%;
      flex-direction: column;
      .sub_text {
        padding-left: 10px;
        position: unset;
        font-weight: normal;
      }
      &:nth-child(1) {
        .report_item_left {
          display: none;
        }
        & > div {
          &:nth-child(2) {
            flex-direction: column;
            .gw-btn {
              margin: 10px 0 0 0;
              width: 100%;
            }
          }
        }
      }
      .report_item_left > div {
        flex-direction: column;
        display: flex;
        & > div > div:nth-child(1) {
          .report_item_label {
            font-weight: bold;
          }
        }
        .report_item_label {
          min-width: unset;
          text-align: left;
        }
        .report_item_wrapper {
          flex-direction: column;
          align-items: flex-start;
        }
        .custom_input--radio_input_area {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
      & > div {
        flex-direction: column;
        width: 100%;
        &:nth-child(2) {
          flex-direction: column-reverse;
          display: flex;
          & > div {
            margin-left: 0;
            font-weight: bold;
            flex-direction: column;
          }
          .gw-btn {
            margin: 0 0 10px 0;
            width: 100%;
          }
        }
      }

      .report-text {
        color: #242424;
        margin-top: 10px;
      }
    }
    &--title,
    &--title-left,
    &--title-right,
    &--title-end {
      width: 100%;
      min-height: 150px;
      flex: 0;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      overflow-x: hidden;
    }
    &--title-left {
      min-height: 80px;
      margin-top: 15px;
      border-bottom: 1px solid $grey;
      padding-bottom: 10px;
      padding-left: 15px;
      padding-right: 15px !important;
      span {
        &:nth-child(1) {
          font-size: 12px;
          color: #a0aab8;
        }
        &:nth-child(2) {
          font-weight: bold;
          font-size: 20px;
        }
      }
    }
    &--title-right {
      margin-top: 15px;
      gap: 10px;
      & > div:not(.report_page--title-end) {
        margin-left: 15px;
      }
      border-bottom: 1px solid $grey;
      & > div:last-child > span:last-child {
        display: none;
      }
    }
    &--title-end {
      flex-direction: row;
      padding-left: 15px;
      align-items: center;
    }
  }
}

.implementation_tab_common_info {
  width: 100%;
  padding: 16px 0 0;
  border: 1px solid #A0AAB8;
  border-radius: 3px;

  &_row {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
    margin-bottom: 16px;
  }

  &_label {
    display: inline-block;
    width: 170px;
    color: #646464;
    text-align: right;
  }
}

@media screen and (max-width: 800px) {
  .implementation_tab_common_info {
    padding: 16px;
    .implementation_tab_common_info_row {
      .implementation_tab_common_info_label {
        width: auto;
      }
    }
  }
}