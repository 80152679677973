@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.create_table_modal_base {
  @media screen and (max-width: 800px) {
    min-width: unset !important;
    .common_modal_title {
      text-align: center;
    }
  }
}
.create_table_modal {
  padding: 0 20px;

  &--item {
    display: flex;
    align-items: center;
    input::placeholder {
      font-size: 16px !important;
    }
    margin-bottom: 25px;
    &_label {
      width: 80px;
      min-width: 80px;
    }
    &_text {
      margin: 0 5px;
    }
    @media screen and (max-width: 800px) {
      .create_table_modal--item_text {
        margin: 0 10px 0 -10px;
      }
      &:nth-child(2) {
        flex-wrap: wrap;
        .create_table_modal--item_label {
          width: 100%;
        }
        .custom_input--select_box_area {
          margin-top: 5px;
          flex-flow: row;
          max-width: 42%;
          align-items: center;
          .custom_input--select_box_label {
            width: unset;
            align-items: center;
          }
        }
      }
    }
  }

  &--actions {
    display: flex;
    justify-content: space-between;
    margin: 40px 0 20px;
    @media screen and (max-width: 800px) {
      align-items: center;
      display: flex;
      gap: 23px;
      justify-content: space-between;
      padding-left: 16px;
      padding-right: 16px;
      width: 100%;
    }
  }

  @media screen and (max-width: 800px) {
    padding: 0;
  }
}
