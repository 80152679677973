@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.income_tax {
  &--display {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
    margin-top: 26px;
  }

  &--unity {
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    @media screen and (max-width: 800px) {
      align-items: flex-end;
    }

    span {
      font-size: 14px;
      color: #242424;
    }
  }

  &--td_contents {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--area {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;
    gap: 40px;

    @media screen and (max-width: 800px) {
      flex-flow: column;
      align-items: flex-start;
    }
  }

  &--contents_area {
    width: auto;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    @media screen and (max-width: 800px) {
      width: 100%;
      flex-flow: column;
      align-items: flex-start;
    }
  }

  &--label_and_table_area {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    @media screen and (max-width: 800px) {
      flex-flow: column;
    }

    .income_tax--label_area {
      width: 232px;
      height: 24px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-shrink: 0;
      margin-top: 6px;
      @media screen and (max-width: 800px) {
        width: auto;
      }

      label {
        font-size: 14px;
        color: #646464;

        span {
          color: #d80245;
        }
      }
    }

    .income_tax--table {
      width: 80%;
      border-color: #a0aab8;

      th {
        background-color: #6a6f7c;
        color: #ffffff;
        font-size: 14px;
        height: 36px;
      }
      td {
        height: 76px;
        margin: auto;
      }
    }
  }
  @media screen and (max-width: 800px) {
    &--table_area {
      width: 100%;
    }
    &--table_sp {
      width: 100%;
      border-color: #a0aab8;

      &_header {
        background-color: #6a6f7c;
        color: #ffffff;
        font-size: 14px;
        height: 76px;
        width: 50%;
        margin: auto;
        text-align: center;
      }
      &_contents {
        font-size: 14px;
        height: 76px;
        width: 50%;
        margin: auto;
      }
    }
  }
}
