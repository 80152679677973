@import 'styles/normalize';
@import 'styles/variables';
@import '~antd/dist/antd.css';
@import 'styles/mixin';
@import 'styles/global';

html,
body {
  width: 100%;
  height: 100%;
  font-family: 'Noto Sans JP', sans-serif;
}