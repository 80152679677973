@import '../../../../styles/normalize';
@import '../../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../../styles/mixin';
@import '../../../../styles/global';

.corporate_register--bill_info {
  gap: 24px;
  margin: 24px 9px;
  .custom_input--area .custom_input--unit {
    position: relative;
  }
}
