@import '../../../styles/normalize';
@import '../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../styles/mixin';
@import '../../../styles/global';

.claim_register--claim_area {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  gap: 16px;
}

.invoice_register {

  &--button_area {
    width: 100%;
    height: 92px;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(72, 96, 131, 0.6);
    z-index: 1;
    @media screen and (max-width: 800px) { 
      height: 88px;
      padding: 24px 16px;
    }
  }

  &--submit_buttons {
    width: 520px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    @media screen and (max-width: 800px) { 
      height: 40px;
      gap: 23px;
      width: 100%;
    }
  }

  &--matter_category_area {
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    @media screen and (max-width: 800px) { 
      flex-direction: column;
      height: auto;
    }
  }
}

