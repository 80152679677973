@import '../../../../../../styles/normalize';
@import '../../../../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../../../../styles/mixin';
@import '../../../../../../styles/global';

.quote_register--quotation_info--steps {
  margin: 26px 0 24px 0;
  &--status {
    color: #ffffff;
    height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &--area {
    padding: 24px 0 0 0;
    display: flex;
    justify-content: space-around;
    @media screen and (max-width: 800px) { 
      justify-content: flex-start;
      overflow-x: scroll;
    }
    .info--step {
      height: 44px;
      width: 44px;
      border: 3px solid #0056d3;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font: normal normal bold 18px Noto Sans CJK JP;
      color: #bcbcbc;
      &--title {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &--area {
        display: flex;
        flex-flow: column;
        align-items: center;
        width: 110px;
        @media screen and (max-width: 800px) {
          width: 160px;
        }
      }
      &--divs {
        width: 100%;
        display: flex;
        justify-content: center;
        position: relative;
        padding-bottom: 15px;
      }
      &--divs:not(:last-child):after {
        width: calc((100% - 42px) / 2);
        height: 4px;
        background: #0056d3;
        border-radius: 1px;
        content: '';
        position: absolute;
        top: 22px;
        left: calc(50% + 22px);
      }
      &--divs:not(:first-child)::before {
        width: calc((100% - 42px) / 2);
        height: 4px;
        background: #0056d3;
        border-radius: 1px;
        content: '';
        position: absolute;
        top: 22px;
        left: 0px;
      }
      &--divs.step_active::after,
      &--divs.step_active::before {
        background: #0056d3;
      }
      &--divs.step_wait::after,
      &--divs.step_wait::before {
        background: #A0AAB8;
      }
      &--divs.step_error::after,
      &--divs.step_error::before {
        background: #d80245;
      }
      &--divs.error_finish::after,
      &--divs.error_finish::before {
        background: #003b94;
      }
    }
  }
  &--stamp_buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin: 40px 0;
    @media screen and (max-width: 800px) { 
      gap: 14px;
      margin: 32px 10px;
    }
  }
  &--message {
    padding-top: 6px;
  }
}

@media screen and (max-width: 800px) {
  .info--step--title {
    span {
      font-size: 12px;
    }
  }

  .info--step--area {
    div {
      font-size: 12px;
    }
  }

  .quote_register--quotation_info--steps--status {
    div {
      font-size: 12px;
    }
  }
}