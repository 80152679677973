@import '../../styles/normalize';
@import '../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../styles/mixin';
@import '../../styles/global';

.login {
  &--background {
    position: relative;
    height: calc(100vh + 10px);
    width: 100%;
    background-color: #F3F6F9;
    text-align: center;

    .copyright--contents {
      width: 100%;
      left: 0;
    }
  }
}

@media screen and (max-width: 800px) { 
  .login {
    &--background {
      height: calc(100vh + 30px);
    }
  }
}