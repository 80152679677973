@mixin shadow-box($radius: 6px) {
  border-radius: $radius;
  box-shadow: 0px 0px 8px #383f524d;
}

@mixin scale-font-size(
  $scale,
  $transform-origin: left bottom,
  $display: inline-block
) {
  font-size: $font-size-sssm;
  -webkit-transform: scale($scale);
  transform: scale($scale);
  transform-origin: $transform-origin;
  display: $display;
}

@mixin disable-area {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  cursor: not-allowed;
  user-select: none;
  background: rgba(255, 255, 255, 0.5);
}

@mixin requiredItem() {
  &::after {
    content: '*';
    color: $dark-red;
    font-size: $font-size-sm;
  }
}

@mixin maxRows($rowNum: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $rowNum;
  -webkit-box-orient: vertical;
}

@mixin baseTable() {
  border-collapse: collapse;
  table-layout: fixed;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  width: 100%;
  & > thead {
    background-color: #6a6f7c;
    color: white;
    height: 36px;
  }
  th,
  td {
    border: 1px solid #a0aab8;
  }
}

@mixin flex-column-center() {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin sp() {
  @media screen and (max-width: 800px) {
    @content;
  }
}