@import '../../../../styles/normalize';
@import '../../../../styles/variables';
@import '~antd/dist/antd.css';
@import '../../../../styles/mixin';
@import '../../../../styles/global';

.quote_flow {
  width: calc(100% - 162px);
  margin: 0 auto;

  @include sp() {
    width: 100%;
  }

  &--top_area {
    display: flex;
    flex-flow: column nowrap;
    gap: 30px;
    margin-top: 26px;
    &--help_tip {
      border-radius: 6px;
    }
  }
  &--main {
    display: flex;
    flex-flow: column nowrap;
    // gap: 40px;
    margin-top: 40px;
    &--step {
      width: 44px;
      height: 44px;
      border: 3px solid #d80245;
      border-radius: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        color: #d80245;
        font: normal normal bold 16px/24px Noto Sans CJK JP;
      }
      &_line {
        height: 40px;
        width: 91.17px;
        border-right: 4px solid rgb(188, 188, 188);

        @include sp() {
          position: absolute;
          content: '';
          width: unset;
          height: 26px;
          left: 20px;
          top: -28px;
        }
      }
    }
  }
  &--add_btn {
    width: 107.17px;
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    &--icon {
      box-sizing: border-box;
      width: 36px;
      height: 36px;
      border: 1px solid #2b3245;
      border-radius: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ffffff;
      color: #2b3245;
      margin: 0;
      padding: 0;
    }
  }
}
